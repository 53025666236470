import { useBankNotesAndCoins } from '../euro';
import type { FC } from 'react';
import React, { useCallback } from 'react';
import { Stack } from '@pflegenavi/web-components';
import { BankOrCash } from './ChangeCashForm';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { ChangeCashMode } from './model';
import { BankAccountDepositOrWithdrawFormView } from './BankAccountDepositOrWithdrawFormView';
import { CashListStorageType, FeatureFlag } from '@pflegenavi/shared/api';
import { useFeatureFlagEnabled } from '@pflegenavi/frontend/feature-flags';

export interface BankAccountDepositOrWithdrawFormProps {
  nextStep: () => void;
  mode: ChangeCashMode;
  setBankOrCash: React.Dispatch<React.SetStateAction<BankOrCash>>;
  handleClose: VoidFunction;
  bankAccountChange?: number;
  setBankAccountChange: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  requiredAmount?: number;
  allowSkipCashSelection: boolean;
  setSkippedCashSelection: React.Dispatch<React.SetStateAction<boolean>>;
  cashListId: string;
  storageType: CashListStorageType;
}

export const BankAccountDepositOrWithdrawForm: FC<
  BankAccountDepositOrWithdrawFormProps
> = ({
  mode,
  nextStep,
  handleClose,
  setBankOrCash,
  bankAccountChange,
  setBankAccountChange,
  requiredAmount,
  allowSkipCashSelection,
  setSkippedCashSelection,
  cashListId,
  storageType,
}) => {
  const newAccountingPaymentsFeatureFlagEnabled = useFeatureFlagEnabled(
    FeatureFlag.AccountingPaymentsNew
  );

  const {
    bankAccountAmount: totalBankBalance,
    isLoading: totalBankBalanceLoading,
  } = useBankNotesAndCoins(cashListId);
  const handleSwitchToCashChange = useCallback(() => {
    setBankAccountChange(undefined);
    setBankOrCash(BankOrCash.Cash);
  }, [setBankAccountChange, setBankOrCash]);

  if (totalBankBalance === undefined || totalBankBalanceLoading) {
    return null;
  }

  return (
    <BankAccountDepositOrWithdrawFormView
      setBankAccountChange={setBankAccountChange}
      handleSwitchToCash={
        storageType & CashListStorageType.Cash
          ? handleSwitchToCashChange
          : undefined
      }
      totalBankBalance={totalBankBalance}
      nextStep={nextStep}
      mode={mode}
      handleClose={handleClose}
      allowSkipCashSelection={allowSkipCashSelection}
      setSkippedCashSelection={setSkippedCashSelection}
      bankAccountChange={bankAccountChange}
      requiredAmount={requiredAmount}
      newAccountingPaymentsFeatureFlagEnabled={
        newAccountingPaymentsFeatureFlagEnabled
      }
    />
  );
};

interface AdjustProceedRowProps {
  handleClose: () => void;
  nextStep?: () => void;
  disabled: boolean;
}

export const AdjustProceedRow: FC<AdjustProceedRowProps> = ({
  nextStep,
  disabled,
  handleClose,
}) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" justifyContent="end" gap={1} sx={{ width: '100%' }}>
      <Button
        variant="outlined"
        color="primary"
        disabled={disabled}
        onClick={handleClose}
      >
        {t('cancel')}
      </Button>

      <Button
        data-cy="cash-mgmt-button"
        disabled={disabled || nextStep === undefined}
        variant="contained"
        onClick={() => {
          nextStep?.();
        }}
      >
        {t('cashManagement.confirm')}
      </Button>
    </Stack>
  );
};
