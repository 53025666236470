import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// config
import { HEADER, NAVBAR } from './config';
//
import SideNavBar from './navbar/SideNavbar';
import type { ConfigKey } from './navbar/UseNavConfig';
import { CollapseDrawerProvider, useCollapseDrawer } from './CollapseDrawer';
import DashboardHeader from './header';
import { InnerErrorBoundary } from './InnerErrorBoundry';
import { TestEnvironmentBadge } from './header/TestEnvironmentBadge';

export {
  IncompleteTransactionsCountContext,
  FailedAndFrozenPaymentsCountContext,
} from './navbar/UseNavConfig';

export { MenuBarIconButton } from './navbar/atoms/MenuBarIconButton';

export { ExtensionProvider } from './navbar/ExtensionContext';
export type { NavBarExtension } from './navbar/ExtensionContext';

// ----------------------------------------------------------------------

interface DashboardLayoutProps {
  configKey: ConfigKey;
  home: string;
  // component to display under the pflegenavi logo
  UnderLogoComponent?: React.ReactNode;
  menuItems?: React.ReactNode;
  helpUrl?: string;
  renderSwitchLanguageMenuItem?: ({
    handleClose,
  }: {
    handleClose: () => void;
  }) => React.ReactElement;
}

export const DashboardLayout: FC<DashboardLayoutProps> = ({
  configKey,
  UnderLogoComponent,
  home,
  menuItems,
  helpUrl,
  renderSwitchLanguageMenuItem,
}) => {
  const { isCollapse } = useCollapseDrawer();
  const [open, setOpen] = useState(false);

  const showTestEnvironmentBadge = useMemo(() => {
    return (
      window.location.hostname.endsWith('.staging.pflegenavi.at') ||
      window.location.hostname.endsWith('localhost')
    );
  }, []);

  return (
    <CollapseDrawerProvider>
      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {showTestEnvironmentBadge && <TestEnvironmentBadge />}

        <DashboardHeader
          isCollapse={isCollapse}
          onOpenSidebar={() => setOpen(true)}
          configKey={configKey}
          menuItems={menuItems}
          renderSwitchLanguageMenuItem={renderSwitchLanguageMenuItem}
          helpUrl={helpUrl}
        />

        <SideNavBar
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
          configKey={configKey}
          UnderLogoComponent={UnderLogoComponent}
          renderSwitchLanguageMenuItem={renderSwitchLanguageMenuItem}
        />

        <MainStyle
          collapseClick={false}
          menuBarEnabled={configKey !== 'fm-web'}
        >
          <InnerErrorBoundary
            home={home}
            supportEmail={
              configKey === 'nh-web'
                ? 'support@pflegenavi.at'
                : 'help@pflegenavi.at'
            }
          >
            <Outlet />
            <Box sx={{ height: 100 }}></Box>
          </InnerErrorBoundary>
        </MainStyle>
      </Box>
    </CollapseDrawerProvider>
  );
};

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

interface MainStyleProps {
  collapseClick: boolean;
  menuBarEnabled?: boolean;
}

const MainStyle = styled('main', {
  shouldForwardProp: (prop) =>
    prop !== 'collapseClick' && prop !== 'menuBarEnabled',
})<MainStyleProps>(({ collapseClick, menuBarEnabled, theme }) => ({
  position: 'relative',
  flexGrow: 1,
  paddingTop: `${HEADER.MOBILE_HEIGHT}px`,
  paddingBottom: 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: menuBarEnabled ? 80 : 30,
    paddingBottom: 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));
