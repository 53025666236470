import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { styled, useTheme } from '@mui/material/styles';
import { Card, CardHeader, Table } from '@mui/material';
import { useBaseOptionChart } from './UseBaseOptionChart';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ApexOptions } from 'apexcharts';
import { useFormatting } from '@pflegenavi/frontend/localization';
import { NoDataDefaultTable } from '@pflegenavi/web-components';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important' as 'relative',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

export interface IDataByReceiptType {
  // Returns total, amount for each receipt type
  receiptType: string;
  total: number;
  amount: number;
}

interface ReceiptTypeChartProps {
  data: IDataByReceiptType[];
}

export const ReceiptTypeChart: FC<ReceiptTypeChartProps> = ({ data }) => {
  const sortedAndMerged = useMemo(() => {
    return [...data].sort((a, b) => (a.amount > b.amount ? -1 : 1));
  }, [data]);

  const series = sortedAndMerged.map((data) => data.amount);
  const countSeries = sortedAndMerged.map((data) => data.total);

  const theme = useTheme();

  const base = useBaseOptionChart();
  const { t } = useTranslation();
  const { fCurrency } = useFormatting();

  const chartOptions: ApexOptions = merge(base, {
    colors: [
      theme.palette.primary.main,
      theme.palette.primary.light,
      theme.palette.primary.lighter,
      theme.palette.secondary.darker,
      theme.palette.secondary.dark,
      theme.palette.secondary.main,
      theme.palette.grey['600'],
      theme.palette.success.dark,
      theme.palette.warning.dark,

      // additional colors
      theme.palette.success.main,
      theme.palette.info.main,
      theme.palette.warning.main,
    ],
    labels: sortedAndMerged.map((data) => t(`receiptType.${data.receiptType}`)),
    stroke: { colors: [theme.palette.background.paper] },
    legend: {
      floating: true,
      horizontalAlign: 'center',
    },
    dataLabels: {
      enabled: true,
      dropShadow: { enabled: false },
      formatter: (val: number) => {
        return `${Math.round(val)}%`;
      },
    },
    tooltip: {
      fillSeriesColor: false,
      custom: ({
        series: _series,
        seriesIndex,
        w,
      }: Record<string, never> & { w: { config: ApexOptions } }) => {
        return (
          '<div style="display:flex;gap:4px;flex-direction:row;align-items:center;background-color:lightblue;padding:4px 8px;">' +
          `<div style="width:18px;height:18px;border-radius: 50px;background-color:${
            w.config.colors?.[seriesIndex] ?? ''
          };"></div>` +
          `<div style="display:flex;flex-direction:column"><div><span style="color:${
            theme.palette.primary.main
          }">${w.config.labels?.[seriesIndex] ?? ''}:</span> <span>${fCurrency(
            series[seriesIndex]
          )}</span>` +
          '</div>' +
          `<div><span style="color:${theme.palette.primary.main}">${t(
            'chart.count'
          )}</span> <span>${countSeries[seriesIndex]}</span>` +
          '</div></div>' +
          '</div>'
        );
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  return (
    <Card sx={{ flex: 1 }} data-cy="receipt-type-chart">
      <CardHeader
        title={t('receiptType.label.chart')}
        titleTypographyProps={{
          sx: { fontWeight: 600 },
        }}
      />

      {series.length === 0 ? (
        <Table>
          <NoDataDefaultTable
            display={data.length === 0}
            isNoDataThisMonth
            colSpan={3}
          />
        </Table>
      ) : (
        <ChartWrapperStyle dir="ltr">
          <ReactApexChart
            type="pie"
            series={series}
            options={chartOptions}
            height={280}
          />
        </ChartWrapperStyle>
      )}
    </Card>
  );
};
