import { useTheme } from '@mui/material';
import { useMemo } from 'react';

export const useTextStyles: () => {
  rowSubTextProps: {
    color: string;
    variant: 'caption';
  };
  rowTextProps: {
    color: string;
    variant: 'subtitle2';
  };
  totalTextProps: {
    variant: 'subtitle2';
    fontWeight: number;
  };
} = () => {
  const theme = useTheme();
  return useMemo(() => {
    return {
      rowTextProps: {
        variant: 'subtitle2' as const,
        color: theme.palette.grey['600'],
      },
      rowSubTextProps: {
        variant: 'caption' as const,
        color: theme.palette.grey['600'],
      },
      totalTextProps: {
        variant: 'subtitle2' as const,
        fontWeight: 700,
      },
    };
  }, [theme.palette.grey]);
};
