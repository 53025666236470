import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';
import { Divider, Stack, Typography } from '@mui/material';
import { AccountSelector } from './AccountSelector';
import { MoneyInput } from '@pflegenavi/web-components';
import type { FC } from 'react';
import React from 'react';

import { useTransferFormStore } from './TransferFormStoreProvider';

interface HeaderProps {
  readonly: boolean;
}

export const Header: FC<HeaderProps> = ({ readonly }) => {
  const { t } = useTranslation();

  const [targetAmount, setTargetAmount] = useTransferFormStore(
    useShallow((state) => [state.targetAmount, state.setTargetAmount])
  );

  return (
    <Stack direction="row" gap={0}>
      <Stack direction="column" gap={1} flex={1} paddingY={1}>
        <AccountSelector disabled={readonly} bucket={'from'} />
        <Stack
          direction="row"
          alignItems="center"
          maxWidth={350}
          justifyContent="space-between"
          gap={2}
        >
          <Typography variant="subtitle2">{`${t(
            'common.amount'
          )}:`}</Typography>
          <MoneyInput
            autoFocus={true}
            size="small"
            value={targetAmount}
            onChange={setTargetAmount}
            disabled={readonly}
          />
        </Stack>
      </Stack>
      <Stack direction={'row'} flex={1} maxWidth={60} justifyContent="center">
        <Divider flexItem orientation="vertical" variant="middle"></Divider>
      </Stack>
      <Stack direction="column" flex={1} paddingY={1}>
        <AccountSelector disabled={readonly} bucket={'to'} />
      </Stack>
    </Stack>
  );
};
