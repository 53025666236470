import type { KeycloakInstance } from 'keycloak-js';
import Keycloak from 'keycloak-js';
import type { User } from '@pflegenavi/frontend/authentication';
import { shouldUseNewLogin } from '@pflegenavi/shared-frontend/authentication';

let keycloakUrl = `http://localhost:5051/auth`;
let realm = 'default_tenant';
if (window.location.hostname === 'test.extranet.pflegenavi.at') {
  keycloakUrl = `https://auth-staging.pflegenavi.at/auth`;
} else if (window.location.hostname === 'admin.pflegneavi.at') {
  keycloakUrl = 'https://auth.pflegenavi.at/auth';
  realm = 'master';
} else if (window.location.hostname === 'test.admin.pflegenavi.at') {
  keycloakUrl = 'https://auth-staging.pflegenavi.at/auth';
  realm = 'master';
} else if (window.location.hostname === 'localhost-admin') {
  keycloakUrl = 'http://localhost:5051/auth';
  realm = 'master';
} else if (window.location.hostname.endsWith('.extranet.pflegenavi.at')) {
  keycloakUrl = 'https://auth.pflegenavi.at/auth';
  realm = window.location.hostname.slice(0, -'.extranet.pflegenavi.at'.length);
} else if (window.location.hostname.endsWith('.staging.pflegenavi.at')) {
  keycloakUrl = 'https://auth-staging.pflegenavi.at/auth';
  realm = window.location.hostname.slice(0, -'.staging.pflegenavi.at'.length);
} else if (window.location.hostname.endsWith('.pflegenavi.at')) {
  keycloakUrl = 'https://auth.pflegenavi.at/auth';
  realm = window.location.hostname.slice(0, -'.pflegenavi.at'.length);
} else if (window.location.hostname === 'nginx') {
  keycloakUrl = 'http://nginx:8080/auth';
  realm = 'default_tenant';
} else if (window.location.host === 'localhost:3001') {
  realm = 'other_tenant';
} else if (window.location.host.includes('.localhost')) {
  realm = window.location.host.split('.')[0];
}

// @ts-expect-error 'new' expression, whose target lacks a construct signature, implicitly has an 'any' type.
export const keycloak: KeycloakInstance = new Keycloak({
  url: keycloakUrl,
  realm,
  clientId: 'webapp',
});

// @ts-expect-error Property 'getTokenImmediate' does not exist on type 'KeycloakInstance'.
keycloak.getTokenImmediate = () => keycloak.token;

keycloak.onTokenExpired = () => {
  // eslint-disable-next-line no-console
  console.debug('Token expired');
  keycloak.updateToken(30).then(() => {
    // eslint-disable-next-line no-console
    console.debug('Received new token');
  });
};

export const login = (setUser: (user: User) => void): void => {
  // eslint-disable-next-line complexity
  keycloak.onReady = async (authenticated) => {
    if (authenticated) {
      const userProfile = await keycloak.loadUserProfile();
      if (keycloak.token && userProfile.username) {
        setUser({
          userId: keycloak.tokenParsed?.sub ?? '',
          username: userProfile.username,
          firstName: userProfile.firstName ?? '',
          lastName: userProfile.lastName ?? '',
          email: userProfile.email ?? '',
          // @ts-expect-error Property exists, but is untyped
          attributes: userProfile.attributes,
        });
      }
    }
  };

  if (!shouldUseNewLogin()) {
    keycloak.init({
      onLoad: 'login-required',
    });
  }
};
