import type { FC } from 'react';
import { useCashListConfiguration } from '@pflegenavi/frontend/api-nursing-home';
import { MenuItem, Select } from '@mui/material';

interface CashListDropdownProps {
  label?: string;
  selectedCashListId: string;
  setSelectedCashListId: (id: string) => void;
  disabled?: boolean;
  size?: 'small' | 'medium';
}

export const CashListDropdown: FC<CashListDropdownProps> = (props) => {
  const cashListConfiguration = useCashListConfiguration();
  return (
    <CashListDropdownView
      {...props}
      cashLists={cashListConfiguration.cashLists}
    />
  );
};

interface CashListDropdownViewProps {
  label?: string;
  selectedCashListId: string;
  setSelectedCashListId: (id: string) => void;
  cashLists: Array<{
    id: string;
    name: string;
  }>;
  disabled?: boolean;
  size?: 'small' | 'medium';
}

export const CashListDropdownView: FC<CashListDropdownViewProps> = ({
  label,
  setSelectedCashListId,
  cashLists,
  selectedCashListId,
  disabled,
  size = 'small',
}) => {
  if (cashLists.length < 2) {
    return null;
  }
  return (
    <Select
      name={'cashListDropdown'}
      label={label}
      value={selectedCashListId}
      onChange={(e) => setSelectedCashListId(e.target.value as string)}
      sx={{ width: 150 }}
      disabled={disabled}
      size={size}
    >
      {cashLists.map((cashList) => (
        <MenuItem key={cashList.id} value={cashList.id}>
          {cashList.name}
        </MenuItem>
      ))}
    </Select>
  );
};
