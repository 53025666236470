import type { FC } from 'react';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  CardHeader,
  styled,
  Table,
  TableBody,
  TableCell as TableCellOriginal,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  Iconify,
  OneLineTypography,
  ReceiptStatusLabel,
  TableEmptyRows,
  TableNoData,
} from '@pflegenavi/web-components';
import { generatePath, nhAppMainPages } from '@pflegenavi/frontend/routing';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormatting } from '@pflegenavi/frontend/localization';
import AllSetSvg from '../assets/all_set.svg';
import type { ReceiptStatusValues } from '@pflegenavi/shared/api';

const TableCell = styled(TableCellOriginal)`
  padding: 12px;
`;

// ----------------------------------------------------------------------

interface RecentReceiptsTableProps {
  data: RecentReceipt[];
}

export const RecentReceiptsTable: FC<RecentReceiptsTableProps> = ({ data }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleViewAllReceipts = () => {
    navigate(nhAppMainPages.TRANSACTIONS_PENDING);
  };

  return (
    <Card
      sx={{
        flex: 1,
        minWidth: {
          xs: '100%',
          md: 560,
        },
      }}
      data-cy="latest-receipts"
    >
      <CardHeader
        title={t('dashboard.label.latestReceipts')}
        sx={{ mb: 3 }}
        titleTypographyProps={{
          sx: { fontWeight: 600 },
        }}
      />
      {/* TODO: extract style */}
      <TableContainer
        sx={{ maxWidth: '96%', marginLeft: 'auto', marginRight: 'auto' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('dashboard.table.resident')}</TableCell>
              <TableCell>{t('dashboard.table.receiptType')}</TableCell>
              <TableCell align="right">{t('dashboard.table.amount')}</TableCell>
              <TableCell align="center">{t('common.status')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.length === 0 && (
              <TableNoData colSpan={4} isNotFound>
                <img
                  alt="empty content"
                  src={AllSetSvg}
                  height={170}
                  width={140}
                />
                <Typography
                  variant="inherit"
                  fontWeight="700"
                  textAlign="center"
                >
                  {t('table.no-open-receipts-this-month')}
                </Typography>
              </TableNoData>
            )}
            {data.map((row, index) => {
              return <RecentReceiptsTableRows key={index} row={row} />;
            })}

            {data && data.length > 0 && (
              <TableEmptyRows
                height={48}
                emptyRows={tableMaxRows - data.length}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {data?.length > 0 && (
        <Box sx={{ p: 2, textAlign: 'right', mt: 'auto' }}>
          <Button
            size="small"
            color="inherit"
            endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'} />}
            onClick={handleViewAllReceipts}
          >
            {t('dashboard.label.viewAll')}
          </Button>
        </Box>
      )}
    </Card>
  );
};

interface RecentReceipt {
  id: string;
  resident: { name: string };
  receiptType: string | undefined;
  amount: number;
  status: ReceiptStatusValues;
}

const RecentReceiptsTableRows: FC<{ row: RecentReceipt }> = ({ row }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { fCurrency } = useFormatting();

  const onClick = () => {
    navigate(
      generatePath(nhAppMainPages.TRANSACTIONS_EDIT, {
        transaction_id: row.id,
      })
    );
  };

  return (
    <TableRow
      hover
      sx={{
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.grey[500_24],
        borderBottomWidth: 'thin',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <TableCell align="left" sx={{ maxWidth: 210 }}>
        <OneLineTypography variant="subtitle2">
          {row.resident.name}
        </OneLineTypography>
      </TableCell>

      <TableCell>
        <OneLineTypography variant="subtitle2">
          {row.receiptType ? t(`receiptType.${row.receiptType}`) : '--'}
        </OneLineTypography>
      </TableCell>

      <TableCell align="right">{fCurrency(row.amount)}</TableCell>

      <TableCell align="center">
        <ReceiptStatusLabel status={row.status} />
      </TableCell>
    </TableRow>
  );
};

const tableMaxRows = 5;
