import type { CashState } from './model';
import { ChangeCashMode } from './model';
import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useEffect, useMemo, useRef } from 'react';
import type { Coin } from '@pflegenavi/shared/api';
import { useTranslation } from 'react-i18next';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';
import { useTransfer } from '@pflegenavi/frontend/api-nursing-home';
import { sumCashState, sumCashStateInCents, sumCoinsInCents } from '../euro';
import { Button, Card, Iconify, Stack } from '@pflegenavi/web-components';
import { CardHeader, Divider, Typography } from '@mui/material';
import { TotalRow } from './TotalRow';
import { FactorRow } from './FactorRow';
import { ResetButton } from './atoms/ResetButton';
import { useFormatting } from '@pflegenavi/frontend/localization';
import { TestIds } from '../../TestIds';

interface ConfirmButtonProps {
  confirmText: string;
  confirmColor:
    | 'inherit'
    | 'error'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | undefined;
  confirmIcon: string;
}

interface CashSelectionSummaryProps {
  cashState: CashState;
  setCashState: Dispatch<SetStateAction<CashState>>;
  mode: ChangeCashMode;
  nextStep: VoidFunction;
  handleClose: VoidFunction;
  bankNotes: Coin[] | undefined;
  coins: Coin[] | undefined;
  isStripeTransfer: boolean;
  requiredAmount?: number;
}

// eslint-disable-next-line complexity
export const CashSelectionSummary: FC<CashSelectionSummaryProps> = ({
  cashState,
  setCashState,
  mode,
  nextStep,
  handleClose,
  bankNotes,
  coins,
  isStripeTransfer,
  requiredAmount,
}) => {
  const { t } = useTranslation();
  const { fCurrencyCents } = useFormatting();

  const { selectedNursingHome } = useNursingHomeContext();
  const { data, isLoading } = useTransfer(selectedNursingHome?.id);
  const notEnoughTransferAmountAvailable =
    isStripeTransfer &&
    (isLoading || data === undefined || data < sumCashState(cashState));

  const isModeAdjust = mode === ChangeCashMode.Adjust;

  const initialSum = useRef(0);

  const { coinsObjectWithZeroAmounts, coinsObjectWithoutZeroAmounts } =
    useMemo(() => {
      const coinsObjectWithZeroAmounts: {
        [factor: number]: number;
      } = {};
      const coinsObjectWithoutZeroAmounts: {
        [factor: number]: number;
      } = {};

      if (bankNotes && coins) {
        [...bankNotes, ...coins].forEach(({ factor, amount }) => {
          coinsObjectWithZeroAmounts[factor] = amount;
        });
      }
      if (bankNotes && coins) {
        [...bankNotes, ...coins].forEach(({ factor, amount }) => {
          if (amount > 0) {
            coinsObjectWithoutZeroAmounts[factor] = amount;
          }
        });
      }

      return { coinsObjectWithZeroAmounts, coinsObjectWithoutZeroAmounts };
    }, [bankNotes, coins]);

  useEffect(() => {
    initialSum.current = sumCoinsInCents(
      Object.entries(coinsObjectWithoutZeroAmounts).map(([factor, amount]) => ({
        factor: Number(factor),
        amount,
      }))
    );
  }, [coinsObjectWithoutZeroAmounts]);

  const handleResetState = () => {
    if (isModeAdjust) {
      setCashState(coinsObjectWithoutZeroAmounts);
    } else {
      setCashState({});
    }
  };

  const stateFactors = Object.keys(cashState).map(Number);
  stateFactors.sort((a, b) => (a < b ? 1 : -1));
  const sum = sumCashStateInCents(cashState);

  const factors = stateFactors.map((factor) => ({
    factor,
    amount: cashState[factor],
  }));

  const hasAnyCoin = sum > 0;

  const { confirmText, confirmColor, confirmIcon }: ConfirmButtonProps =
    useMemo(() => {
      if (mode === ChangeCashMode.Withdraw) {
        return {
          confirmIcon: 'eva:trending-down-fill',
          confirmColor: 'error',
          confirmText: t('cashManagement.withdraw'),
        };
      }
      if (mode === ChangeCashMode.Deposit) {
        return {
          confirmIcon: 'eva:trending-up-fill',
          confirmColor: 'primary',
          confirmText: t('cashManagement.deposit'),
        };
      }
      return {
        confirmIcon: '',
        confirmColor: 'primary',
        confirmText: t('cashManagement.confirm'),
      };
    }, [mode, t]);

  const resetDisabled = isModeAdjust ? sum === initialSum.current : sum === 0;

  const requiredAmountReached =
    requiredAmount === undefined || sum === requiredAmount;

  return (
    <Card
      data-cy="cash-selection-summary"
      data-testid={TestIds.CashSelectionSummaryCard}
      sx={{ p: 3, flex: 1, minWidth: 430 }}
    >
      <Stack direction="column" gap={2}>
        <Stack direction="row" justifyContent="space-between">
          <CardHeader
            title={
              isModeAdjust
                ? t('cashManagement.adjust-summary')
                : t('cashManagement.summary')
            }
            sx={{ p: 0 }}
            titleTypographyProps={{
              variant: 'subtitle1',
              sx: { fontWeight: 700 },
            }}
          />

          <ResetButton reset={handleResetState} disabled={resetDisabled}>
            {t(
              `cashManagement.${isModeAdjust ? 'reset-adjust-mode' : 'clear'}`
            )}
          </ResetButton>
        </Stack>

        <Stack direction="column" gap={1}>
          <Stack
            direction="column"
            gap={1}
            sx={{ maxHeight: 250, overflowY: 'scroll', mr: -2 }}
          >
            {factors.map(({ factor, amount }) => (
              <FactorRow
                key={factor}
                factor={factor}
                amount={amount}
                initialAmount={coinsObjectWithZeroAmounts[factor]}
                showDifference={mode === ChangeCashMode.Adjust}
              />
            ))}
          </Stack>

          <Divider />
          <TotalRow sum={sum} initialSum={initialSum.current} mode={mode} />

          {!requiredAmountReached && (
            <Typography
              variant="body2"
              color="error"
              data-amount={requiredAmount}
            >
              {t('cashManagement.cash-change-amount-error', {
                amount: fCurrencyCents(requiredAmount),
              })}
            </Typography>
          )}
        </Stack>

        {!isModeAdjust && (
          <Stack direction="row" justifyContent="end" gap={1}>
            <Button variant="outlined" color="primary" onClick={handleClose}>
              {t('cancel')}
            </Button>

            <Button
              data-cy="cash-mgmt-button"
              disabled={
                !hasAnyCoin ||
                notEnoughTransferAmountAvailable ||
                !requiredAmountReached
              }
              variant="contained"
              color={confirmColor}
              endIcon={<Iconify icon={confirmIcon} />}
              onClick={() => {
                nextStep();
              }}
            >
              {confirmText}
            </Button>
          </Stack>
        )}
      </Stack>
    </Card>
  );
};
