import type { FC } from 'react';
import { Stack, Switch, Typography } from '@mui/material';
import type { FeatureFlag } from '@pflegenavi/shared/api';
import { FeatureFlagStatus } from '@pflegenavi/shared/api';
import {
  useFeatureFlag,
  useSetFeatureFlag,
} from '@pflegenavi/frontend/feature-flags';

interface FeatureFlagRowProps {
  featureFlag: FeatureFlag;
}

export const FeatureFlagRow: FC<FeatureFlagRowProps> = ({ featureFlag }) => {
  const featureFlagEnabled =
    useFeatureFlag(featureFlag) === FeatureFlagStatus.Enabled;
  const setFeatureFlag = useSetFeatureFlag();

  return (
    <Stack direction="row" gap={1}>
      <Typography variant="subtitle2">{featureFlag}</Typography>
      <Switch
        checked={featureFlagEnabled}
        onChange={(_event, checked) => {
          setFeatureFlag(
            featureFlag,
            checked ? FeatureFlagStatus.Enabled : FeatureFlagStatus.Disabled
          );
        }}
      />
    </Stack>
  );
};
