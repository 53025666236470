import type { FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import * as Sentry from '@sentry/react';
import { SomethingWentWrong } from '@pflegenavi/web-components';

const ErrorBoundary: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  return (
    <Sentry.ErrorBoundary
      fallback={({ resetError, error }) => (
        <SomethingWentWrong
          handleGoHomeClick={() => {
            resetError();
            navigate(nhAppMainPages.HOME);
          }}
          supportEmail="support@pflegenavi.at"
          errorMessage={
            error instanceof Error ? error?.message : JSON.stringify(error)
          }
        />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
