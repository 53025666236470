import type { ChangeEvent, FC } from 'react';
import React from 'react';
import { TextField } from '@mui/material';
import { NOTES_MAX_FIELD_LENGTH } from '@pflegenavi/shared/constants';
import { useTranslation } from 'react-i18next';

interface CashNotesFieldProps {
  notes: string;
  setNotes: React.Dispatch<React.SetStateAction<string>>;
  placeholder?: string;
  label?: string;
  /**
   * Mui TextField rows prop
   */
  rows: number;
  error?: boolean;
  setError?: React.Dispatch<React.SetStateAction<boolean>>;
  required?: boolean;
}

export const CashNotesField: FC<CashNotesFieldProps> = ({
  notes,
  setNotes,
  placeholder,
  label,
  rows,
  error,
  setError,
  required,
}) => {
  const { t } = useTranslation();

  const handleNotesChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (notes.length > NOTES_MAX_FIELD_LENGTH) {
      setError?.(true);
      return;
    }
    setError?.(false);
    setNotes(e.target.value);
  };
  return (
    <TextField
      value={notes}
      onChange={handleNotesChange}
      required={required}
      placeholder={placeholder ?? t('cashManagement.confirm-note-placeholder')}
      label={label ?? t('cashManagement.confirm-note-label')}
      multiline
      InputLabelProps={{ shrink: true }}
      rows={rows}
      fullWidth
      error={error}
      helperText={
        error
          ? t('cashManagement.note-field-required')
          : `${notes.length}/${NOTES_MAX_FIELD_LENGTH}`
      }
      inputProps={{
        maxLength: NOTES_MAX_FIELD_LENGTH,
      }}
      sx={{
        mb: -3.5,
        '& .MuiOutlinedInput-root': {
          borderRadius: 3,
          paddingBottom: 2.6,
        },
      }}
    />
  );
};
