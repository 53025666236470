import { styled } from '@mui/material/styles';
import { AppBar, Box, Stack, Toolbar } from '@mui/material';
import {
  cssStyles,
  IconButtonAnimate,
  Iconify,
  useOffSetTop,
  useResponsive,
} from '@pflegenavi/web-components';
import { HEADER, NAVBAR } from '../config';
import { UserAvatar } from '../navbar/UserAvatar';
import type { ConfigKey } from '../navbar/UseNavConfig';
import { HelpIcon } from '../navbar/HelpIcon';

interface Props {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
  configKey: ConfigKey;
  menuItems?: React.ReactNode;
  helpUrl?: string;
  renderSwitchLanguageMenuItem?: ({
    handleClose,
  }: {
    handleClose: () => void;
  }) => React.ReactElement;
}

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
  configKey,
  menuItems,
  helpUrl,
  renderSwitchLanguageMenuItem,
}: Props): JSX.Element | null {
  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive('up', 'lg');

  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
      showMenuBarOnDesktop={configKey !== 'fm-web'}
    >
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {!isDesktop && (
          <IconButtonAnimate
            onClick={onOpenSidebar}
            sx={{
              mr: 1,
              color: 'text.primary.main',
            }}
          >
            <Iconify
              sx={{
                width: 24,
                height: 24,
              }}
              icon="eva:menu-2-fill"
            />
          </IconButtonAnimate>
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" gap={1}>
          {menuItems}
          {helpUrl && <HelpIcon helpUrl={helpUrl} />}
          {!isDesktop && (
            <UserAvatar
              configKey={configKey}
              direction={'top'}
              renderSwitchLanguageMenuItem={renderSwitchLanguageMenuItem}
            />
          )}
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}

interface RootStyleProps {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
  showMenuBarOnDesktop?: boolean;
}

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' &&
    prop !== 'isOffset' &&
    prop !== 'verticalLayout' &&
    prop !== 'showMenuBarOnDesktop',
})<RootStyleProps>(
  ({ isCollapse, isOffset, verticalLayout, showMenuBarOnDesktop, theme }) => ({
    ...cssStyles(theme).bgBlur(),
    boxShadow: 'none',
    height: HEADER.MOBILE_HEIGHT,
    zIndex: theme.zIndex.appBar + 1,
    transition: theme.transitions.create(['width', 'height'], {
      duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up('lg')]: {
      height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
      width: showMenuBarOnDesktop
        ? `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`
        : 0,
      ...(isCollapse && {
        width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
      }),
      ...(isOffset && {
        height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      }),
      ...(verticalLayout && {
        width: '100%',
        height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
        backgroundColor: theme.palette.background.default,
      }),
    },
  })
);

// ----------------------------------------------------------------------
