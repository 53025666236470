import { Typography } from '@mui/material';
import type { FC } from 'react';
import React from 'react';
import { GroupContainer } from './GroupContainer';
import { Title } from './Title';
import { Row } from './Row';
import { RowDivider } from './RowDivider';
import { useTranslation } from 'react-i18next';
import { useTextStyles } from './useTextStyles';
import { useFormatting } from '@pflegenavi/frontend/localization';
import { useCashListConfiguration } from '@pflegenavi/frontend/api-nursing-home';

interface BankProps {
  cashListId: string;
  payinSum: number;
  payoutSum: number;
}

export const BankMangopay: FC<BankProps> = ({
  cashListId,
  payinSum,
  payoutSum,
}) => {
  const { fCurrencyCents } = useFormatting();
  const { rowTextProps, totalTextProps } = useTextStyles();
  const { t } = useTranslation();

  const cashListConfiguration = useCashListConfiguration();

  const cashList = cashListConfiguration.cashLists.find(
    (cashList) => cashList.id === cashListId
  );
  if (!cashList) {
    throw new Error(`Cash list ${cashListId} not found`);
  }

  const { bankAccountAmountInCent, name } = cashList;

  const bankName = name ?? t('accounting.dashboard.bank-title');
  return (
    <GroupContainer>
      <Title>{bankName}</Title>
      <Row>
        <Typography {...rowTextProps}>
          {t('accounting.dashboard.bank-current-balance')}
        </Typography>
        <Typography {...rowTextProps}>
          {fCurrencyCents(bankAccountAmountInCent)}
        </Typography>
      </Row>
      {cashListConfiguration.payoutTargetCashListId === cashListId && (
        <Row>
          <Typography {...rowTextProps}>
            {`- ${t('accounting.dashboard.mangopay-bank-incoming-payouts')}`}
          </Typography>
          <Typography {...rowTextProps} color="green">
            {fCurrencyCents(payoutSum)}
          </Typography>
        </Row>
      )}
      {cashListConfiguration.payoutTargetCashListId === cashListId && (
        <Row>
          <Typography {...rowTextProps}>
            {`- ${t('accounting.dashboard.mangopay-bank-outgoing-payins')}`}
          </Typography>
          <Typography {...rowTextProps} color="red">
            {fCurrencyCents(-payinSum)}
          </Typography>
        </Row>
      )}
      <RowDivider />

      <Row>
        <Typography {...totalTextProps} fontWeight={700}>
          {t('accounting.dashboard.bank-balance', {
            bankName: bankName,
          })}
        </Typography>
        <Typography {...totalTextProps} fontWeight={700}>
          {fCurrencyCents(bankAccountAmountInCent)}
        </Typography>
      </Row>
    </GroupContainer>
  );
};
