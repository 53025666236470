import type * as React from 'react';
import { type SVGProps, useCallback, useEffect, useMemo } from 'react';
import IconEuroCircle from '~icons/majesticons/euro-circle';
import IconAdminPanelSettings from '~icons/material-symbols/admin-panel-settings';
import IconRoundsCreditCard from '~icons/ic/round-credit-card';
import IconAccountPaymentOutline from '~icons/mdi/account-payment-outline';

import type { ResidentListItemDto } from '@pflegenavi/shared/api';
import {
  applySortFilter,
  getDataByStatus,
  statusFilter,
} from '../helpers/residentsTableFilter';
import type { UseTableProps } from '@pflegenavi/web-components';
import { useTable } from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';
import { isBefore, isSameDay } from 'date-fns';
import {
  useFormatDate,
  useFormatting,
  useLocale,
} from '@pflegenavi/frontend/localization';
import {
  formatResidentTableRowModel,
  formatResidentTableRowModelMangopay,
} from '../helpers/formatResidentTableRowModel';
import type { ResidentTableRowModel } from '../interfaces/ResidentTableRowModel';
import { useResidentTableUrlParamFilters } from './useResidentTableUrlParamFilters';
import type { GetAllPaymentInfoResponse } from '@pflegenavi/frontend/api-nursing-home';

export enum CircleTabsColors {
  GREY = 'default',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info',
  SECONDARY = 'secondary',
}

export enum CircleFilterValues {
  ACCOUNT_BALANCE = 'Account balance',
  PAYMENT_PRECONDITIONS = 'Payment preconditions',
  ONGOING_PAYMENTS = 'Ongoing payments',
  PAYMENT_METHODS = 'Payment methods',
}

export enum TabFilterValues {
  ALL = 'All',
  LOW_BALANCE = 'Low balance',
  NEGATIVE_BALANCE = 'Negative balance',
  SETTLEMENT = 'Settlement',
  MISSING_FAMILY_MEMBER = 'Missing family member',
  SEPA_ACTIVE = 'SEPA active',
  SEPA_DEBIT_MISSING = 'SEPA-Debit missing',
  AMOUNT_TOO_HIGH = 'Amount too high',
  EMAIL_NOT_VERIFIED = 'Email not verified',
  CASH_PAYER = 'Cash payer',
  UPCOMING = 'Upcoming',
  PENDING = 'Pending',
  FAILED = 'Failed',
  THRESHOLD = 'Threshold',
  MONTHLY = 'Monthly',
  MANUAL = 'Manual',
  DISABLED = 'Disabled',
}

interface UseResidentsPageProps {
  isMangopay: boolean;
  residents?: ResidentListItemDto[];
  selectedTab?: TabFilterValues;
  nursingHomeMaxPaymentAmount?: number;
}

interface UseResidentsPageMangopayProps {
  residents?: ResidentListItemDto[];
  paymentInfos?: GetAllPaymentInfoResponse;
  selectedTab?: TabFilterValues;
  nursingHomeMaxPaymentAmount?: number;
}

interface UseResidentsPageResult extends UseResidentsTableResult {
  CIRCLE_FILTERS: CircleFilter[];
  TABS: Array<{
    label: string;
    value: string;
    count: number;
    color: CircleTabsColors;
  }>;
  dataFiltered: ResidentTableRowModel[];
  dataFilteredAfterStatus: ResidentTableRowModel[];
  circleFilterValue: CircleFilterValues;
  setCircleFilterValue: (value: CircleFilterValues) => void;
  tabFilterValue: TabFilterValues;
  setTabFilterValue: (value: TabFilterValues) => void;
  additionalColumn?: ResidentColumn;
}

export const useResidentsPageMangopay = ({
  residents,
  paymentInfos,
  selectedTab,
  nursingHomeMaxPaymentAmount,
}: UseResidentsPageMangopayProps): UseResidentsPageResult => {
  const { t } = useTranslation();
  const locale = useLocale();
  const { fCurrency } = useFormatting();
  const fDate = useFormatDate();

  const tableProps = useResidentsTable();
  const { tabFilterValue, setTabFilterValueBase, circleFilterValue } =
    tableProps;
  const { filterTerms, setOrder, setOrderBy } = tableProps;

  const { dataFiltered, dataFilteredAfterStatus } = useMemo(() => {
    const formattedData = (residents ?? []).map((resident) => {
      const paymentInfo = paymentInfos?.data.find(
        (paymentInfo) => paymentInfo.resident_id === resident.id
      );
      return formatResidentTableRowModelMangopay(
        resident,
        paymentInfo,
        nursingHomeMaxPaymentAmount ?? 0,
        {
          fDate,
          fCurrency,
          t,
        }
      );
    });
    return applySortFilter({
      dataFiltered: formattedData,
      comparator: tableProps.comparator,
      filterTerms,
      filterStatus: tabFilterValue,
      circleFilter: circleFilterValue,
      locale,
      isMangopay: true,
    });
  }, [
    residents,
    tableProps.comparator,
    filterTerms,
    tabFilterValue,
    circleFilterValue,
    locale,
    paymentInfos?.data,
    nursingHomeMaxPaymentAmount,
    fDate,
    fCurrency,
    t,
  ]);

  const additionalColumn =
    tabFilterValue === TabFilterValues.LOW_BALANCE
      ? ResidentColumn.LowBalance
      : tabFilterValue === TabFilterValues.NEGATIVE_BALANCE
      ? ResidentColumn.NegativeBalance
      : undefined;

  const setTabFilterValue = useCallback(
    (value: TabFilterValues) => {
      if (value === TabFilterValues.ALL) {
        setOrder('asc');
        setOrderBy(ResidentColumn.Name);
      } else if (value === TabFilterValues.NEGATIVE_BALANCE) {
        setOrder('desc');
        setOrderBy(ResidentColumn.NegativeBalance);
      } else if (value === TabFilterValues.LOW_BALANCE) {
        setOrder('desc');
        setOrderBy(ResidentColumn.LowBalance);
      } else if (value === TabFilterValues.SETTLEMENT) {
        setOrder('desc');
        setOrderBy(ResidentColumn.Name);
      }
      setTabFilterValueBase(value);
    },
    [setOrder, setOrderBy, setTabFilterValueBase]
  );

  useEffect(() => {
    if (selectedTab) {
      setTabFilterValue(selectedTab);
    }
  }, [selectedTab, setTabFilterValue]);

  const { CIRCLE_FILTERS } = useCircleFilters({
    data: dataFiltered,
    circleFilterValue,
    isMangopay: true,
  });

  const TABS =
    CIRCLE_FILTERS.find((item) => item.value === circleFilterValue)?.data ?? [];

  return {
    CIRCLE_FILTERS,
    TABS,
    setTabFilterValue,
    dataFiltered,
    dataFilteredAfterStatus,
    additionalColumn,
    ...tableProps,
  };
};

export const useResidentsPage = ({
  residents,
  selectedTab,
  nursingHomeMaxPaymentAmount,
}: UseResidentsPageProps): UseResidentsPageResult => {
  const { t } = useTranslation();
  const locale = useLocale();
  const { fCurrency } = useFormatting();
  const fDate = useFormatDate();

  const tableProps = useResidentsTable();
  const { tabFilterValue, setTabFilterValueBase, circleFilterValue } =
    tableProps;
  const { filterTerms, setOrder, setOrderBy } = tableProps;

  const { dataFiltered, dataFilteredAfterStatus } = useMemo(() => {
    const formattedData = (residents ?? []).map((resident) =>
      formatResidentTableRowModel(resident, nursingHomeMaxPaymentAmount ?? 0, {
        fDate,
        fCurrency,
        t,
      })
    );
    return applySortFilter({
      dataFiltered: formattedData,
      comparator: tableProps.comparator,
      filterTerms,
      filterStatus: tabFilterValue,
      circleFilter: circleFilterValue,
      locale,
      isMangopay: false,
    });
  }, [
    residents,
    tableProps.comparator,
    filterTerms,
    tabFilterValue,
    circleFilterValue,
    locale,
    nursingHomeMaxPaymentAmount,
    fDate,
    fCurrency,
    t,
  ]);

  const additionalColumn =
    tabFilterValue === TabFilterValues.LOW_BALANCE
      ? ResidentColumn.LowBalance
      : tabFilterValue === TabFilterValues.NEGATIVE_BALANCE
      ? ResidentColumn.NegativeBalance
      : undefined;

  const setTabFilterValue = useCallback(
    (value: TabFilterValues) => {
      if (value === TabFilterValues.ALL) {
        setOrder('asc');
        setOrderBy(ResidentColumn.Name);
      } else if (value === TabFilterValues.NEGATIVE_BALANCE) {
        setOrder('desc');
        setOrderBy(ResidentColumn.NegativeBalance);
      } else if (value === TabFilterValues.LOW_BALANCE) {
        setOrder('desc');
        setOrderBy(ResidentColumn.LowBalance);
      } else if (value === TabFilterValues.SETTLEMENT) {
        setOrder('desc');
        setOrderBy(ResidentColumn.Name);
      }
      setTabFilterValueBase(value);
    },
    [setOrder, setOrderBy, setTabFilterValueBase]
  );

  useEffect(() => {
    if (selectedTab) {
      setTabFilterValue(selectedTab);
    }
  }, [selectedTab, setTabFilterValue]);

  const { CIRCLE_FILTERS } = useCircleFilters({
    data: dataFiltered,
    isMangopay: false,
    circleFilterValue,
  });

  const TABS =
    CIRCLE_FILTERS.find((item) => item.value === circleFilterValue)?.data ?? [];

  return {
    CIRCLE_FILTERS,
    TABS,
    setTabFilterValue,
    dataFiltered,
    dataFilteredAfterStatus,
    additionalColumn,
    ...tableProps,
  };
};

interface CircleFilter {
  value: CircleFilterValues;
  label: string;
  count: number;
  countTitle: string;
  Icon: React.ComponentType<SVGProps<SVGSVGElement>>;
  data: Array<{
    label: string;
    value: string;
    count: number;
    color: CircleTabsColors;
  }>;
}

interface UseCircleFiltersProps {
  data: ResidentTableRowModel[];
  isMangopay: boolean;
  circleFilterValue: CircleFilterValues;
}

interface UseCircleFiltersResult {
  CIRCLE_FILTERS: CircleFilter[];
}

const useCircleFilters = ({
  data,
  isMangopay,
}: UseCircleFiltersProps): UseCircleFiltersResult => {
  const { t } = useTranslation();
  const CIRCLE_FILTERS: CircleFilter[] = useMemo(() => {
    const getLengthByStatus = (
      status: TabFilterValues,
      circleFilter: CircleFilterValues
    ) => getDataByStatus(data, status, circleFilter, isMangopay).length;

    return [
      {
        value: CircleFilterValues.ACCOUNT_BALANCE,
        label: t('residents.account-balance'),
        count: countResidentsInCircleFilter(
          data,
          CircleFilterValues.ACCOUNT_BALANCE,
          isMangopay
        ),
        countTitle: t('residents.title'),
        icon: 'majesticons:euro-circle',
        Icon: IconEuroCircle,
        data: [
          {
            label: t('residents.balance-all'),
            value: TabFilterValues.ALL,
            count: countResidentsInCircleFilter(
              data,
              CircleFilterValues.ACCOUNT_BALANCE,
              isMangopay
            ),
            color: CircleTabsColors.GREY,
          },
          {
            label: t('residents.balance-low'),
            value: TabFilterValues.LOW_BALANCE,
            count: getLengthByStatus(
              TabFilterValues.LOW_BALANCE,
              CircleFilterValues.ACCOUNT_BALANCE
            ),
            color: CircleTabsColors.WARNING,
          },
          {
            label: t('residents.balance-negative'),
            value: TabFilterValues.NEGATIVE_BALANCE,
            count: getLengthByStatus(
              TabFilterValues.NEGATIVE_BALANCE,
              CircleFilterValues.ACCOUNT_BALANCE
            ),
            color: CircleTabsColors.ERROR,
          },
          {
            label: t('residents.settlement'),
            value: TabFilterValues.SETTLEMENT,
            count: getLengthByStatus(
              TabFilterValues.SETTLEMENT,
              CircleFilterValues.ACCOUNT_BALANCE
            ),
            color: CircleTabsColors.INFO,
          },
        ],
      },
      {
        value: CircleFilterValues.PAYMENT_PRECONDITIONS,
        label: t('residents.payment-preconditions.title'),
        count: countResidentsInCircleFilter(
          data,
          CircleFilterValues.PAYMENT_PRECONDITIONS,
          isMangopay
        ),
        countTitle: t('residents.title'),
        icon: 'material-symbols:admin-panel-settings',
        Icon: IconAdminPanelSettings,
        data: [
          {
            label: t('residents.balance-all'),
            value: TabFilterValues.ALL,
            count: countResidentsInCircleFilter(
              data,
              CircleFilterValues.PAYMENT_PRECONDITIONS,
              isMangopay
            ),
            color: CircleTabsColors.GREY,
          },
          {
            label: t('residents.payment-preconditions.payments-activated'),
            value: TabFilterValues.SEPA_ACTIVE,
            count: getLengthByStatus(
              TabFilterValues.SEPA_ACTIVE,
              CircleFilterValues.PAYMENT_PRECONDITIONS
            ),
            color: CircleTabsColors.SUCCESS,
          },
          {
            label: t('residents.payment-preconditions.missing-family-member'),
            value: TabFilterValues.MISSING_FAMILY_MEMBER,
            count: getLengthByStatus(
              TabFilterValues.MISSING_FAMILY_MEMBER,
              CircleFilterValues.PAYMENT_PRECONDITIONS
            ),
            color: CircleTabsColors.WARNING,
          },
          {
            label: t('residents.payment-preconditions.sepa-missing'),
            value: TabFilterValues.SEPA_DEBIT_MISSING,
            count: getLengthByStatus(
              TabFilterValues.SEPA_DEBIT_MISSING,
              CircleFilterValues.PAYMENT_PRECONDITIONS
            ),
            color: CircleTabsColors.INFO,
          },
          ...(isMangopay
            ? []
            : [
                {
                  label: t('residents.payment-preconditions.amount-too-high'),
                  value: TabFilterValues.AMOUNT_TOO_HIGH,
                  count: getLengthByStatus(
                    TabFilterValues.AMOUNT_TOO_HIGH,
                    CircleFilterValues.PAYMENT_PRECONDITIONS
                  ),
                  color: CircleTabsColors.ERROR,
                },
              ]),
          {
            label: t('residents.payment-preconditions.email-not-verified'),
            value: TabFilterValues.EMAIL_NOT_VERIFIED,
            count: getLengthByStatus(
              TabFilterValues.EMAIL_NOT_VERIFIED,
              CircleFilterValues.PAYMENT_PRECONDITIONS
            ),
            color: CircleTabsColors.GREY,
          },
        ],
      },
      {
        value: CircleFilterValues.ONGOING_PAYMENTS,
        label: t('residents.ongoing-payments.title'),
        count: countResidentsInCircleFilter(
          data,
          CircleFilterValues.ONGOING_PAYMENTS,
          isMangopay
        ),
        countTitle: t('residents.title'),
        icon: 'ic:round-credit-card',
        Icon: IconRoundsCreditCard,
        data: [
          {
            label: t('residents.balance-all'),
            value: TabFilterValues.ALL,
            count: countResidentsInCircleFilter(
              data,
              CircleFilterValues.ONGOING_PAYMENTS,
              isMangopay
            ),
            color: CircleTabsColors.GREY,
          },
          {
            label: t('residents.ongoing-payments.upcoming'),
            value: TabFilterValues.UPCOMING,
            count: getLengthByStatus(
              TabFilterValues.UPCOMING,
              CircleFilterValues.ONGOING_PAYMENTS
            ),
            color: CircleTabsColors.INFO,
          },
          {
            label: t('residents.ongoing-payments.pending'),
            value: TabFilterValues.PENDING,
            count: getLengthByStatus(
              TabFilterValues.PENDING,
              CircleFilterValues.ONGOING_PAYMENTS
            ),
            color: CircleTabsColors.SUCCESS,
          },
          {
            label: t('residents.ongoing-payments.failed'),
            value: TabFilterValues.FAILED,
            count: getLengthByStatus(
              TabFilterValues.FAILED,
              CircleFilterValues.ONGOING_PAYMENTS
            ),
            color: CircleTabsColors.ERROR,
          },
        ],
      },
      {
        value: CircleFilterValues.PAYMENT_METHODS,
        label: t('residents.payment-methods.title'),
        count: countResidentsInCircleFilter(
          data,
          CircleFilterValues.PAYMENT_METHODS,
          isMangopay
        ),
        countTitle: t('residents.title'),
        icon: 'mdi:account-payment-outline',
        Icon: IconAccountPaymentOutline,
        data: [
          {
            label: t('residents.balance-all'),
            value: TabFilterValues.ALL,
            count: countResidentsInCircleFilter(
              data,
              CircleFilterValues.PAYMENT_METHODS,
              isMangopay
            ),
            color: CircleTabsColors.GREY,
          },
          {
            label: t('residents.payment-methods.threshold'),
            value: TabFilterValues.THRESHOLD,
            count: getLengthByStatus(
              TabFilterValues.THRESHOLD,
              CircleFilterValues.PAYMENT_METHODS
            ),
            color: CircleTabsColors.SUCCESS,
          },
          {
            label: t('residents.payment-methods.monthly'),
            value: TabFilterValues.MONTHLY,
            count: getLengthByStatus(
              TabFilterValues.MONTHLY,
              CircleFilterValues.PAYMENT_METHODS
            ),
            color: CircleTabsColors.INFO,
          },
          {
            label: t('residents.payment-methods.manual'),
            value: TabFilterValues.MANUAL,
            count: getLengthByStatus(
              TabFilterValues.MANUAL,
              CircleFilterValues.PAYMENT_METHODS
            ),
            color: CircleTabsColors.WARNING,
          },
          {
            label: t('residents.payment-preconditions.cash-payer'),
            value: TabFilterValues.CASH_PAYER,
            count: getLengthByStatus(
              TabFilterValues.CASH_PAYER,
              CircleFilterValues.PAYMENT_METHODS
            ),
            color: CircleTabsColors.SECONDARY,
          },
          {
            label: t('residents.payment-methods.disabled'),
            value: TabFilterValues.DISABLED,
            count: getLengthByStatus(
              TabFilterValues.DISABLED,
              CircleFilterValues.PAYMENT_METHODS
            ),
            color: CircleTabsColors.ERROR,
          },
        ],
      },
    ];
  }, [t, data, isMangopay]);

  return {
    CIRCLE_FILTERS,
  };
};

interface UseResidentsTableResult
  extends UseTableProps<ResidentColumn, ResidentTableRowModel> {
  filterTerms: string;
  handleFilterTerms: (filterTerms: string) => void;
  handleDeleteRow: (id: string) => void;
  tabFilterValue: TabFilterValues;
  setTabFilterValueBase: (value: TabFilterValues) => void;
  circleFilterValue: CircleFilterValues;
  setCircleFilterValue: (value: CircleFilterValues) => void;
}

export enum ResidentColumn {
  Name = 'name',
  Gender = 'gender',
  ResidentAccountingId = 'residentAccountingId',
  FamilyMember = 'familyMember',
  LowBalance = 'lowBalance',
  NegativeBalance = 'negativeBalance',
  Status = 'state',
  Balance = 'balance',
  Empty = '',
}

// eslint-disable-next-line complexity
const safeDateComparator = (a?: Date, b?: Date): number => {
  if (a === undefined && b === undefined) {
    return 0;
  }
  if (a === undefined) {
    return -1;
  }
  if (b === undefined) {
    return 1;
  }
  return isSameDay(b, a) ? 0 : isBefore(a, b) ? -1 : 1;
};

const useResidentsTable = (): UseResidentsTableResult => {
  const urlParamsTableFilters = useResidentTableUrlParamFilters();

  const {
    filterTerms,
    setFilterTerms,
    circleFilterValue,
    tabFilterValue,
    setTabFilterValueBase,
    setCircleFilterValue,
  } = urlParamsTableFilters;

  const { setPage, setSelected, ...tableProps } = useTable<
    ResidentTableRowModel,
    ResidentColumn
  >({
    useUrlParamPagination: true,
    defaultRowsPerPage: 10,
    tableKey: 'residents',
    defaultOrderBy: ResidentColumn.Name,
    sortKeys: {
      [ResidentColumn.FamilyMember]: ['familyMember'],
      [ResidentColumn.Balance]: ['balance'],
      [ResidentColumn.Empty]: [],
      [ResidentColumn.ResidentAccountingId]: ['residentAccountingId'],
      [ResidentColumn.Gender]: ['gender'],
      [ResidentColumn.LowBalance]: ['threshold_due_date'],
      [ResidentColumn.NegativeBalance]: ['negative_due_date'],
      [ResidentColumn.Name]: ['lastName', 'firstName'],
      [ResidentColumn.Status]: ['state'],
    },
    sortComparator: {
      lastName: (a, b) => b.localeCompare(a),
      firstName: (a, b) => b.localeCompare(a),
      negative_due_date: safeDateComparator,
      threshold_due_date: safeDateComparator,
    },
  });

  const handleDeleteRow = (id: string) => {
    // const deleteRow = residents?.filter((row) => row.id !== id);
    setSelected([]);
  };

  const handleFilterTerms = (filterTerms: string) => {
    setFilterTerms(filterTerms);
    setPage(0);
  };

  return {
    filterTerms,
    handleFilterTerms,
    circleFilterValue,
    setCircleFilterValue,
    tabFilterValue,
    setTabFilterValueBase,
    handleDeleteRow,
    setPage,
    setSelected,
    ...tableProps,
  };
};

function countResidentsInCircleFilter(
  residents: ResidentTableRowModel[],
  circleFilter: CircleFilterValues,
  isMangopay: boolean
): number {
  let count = 0;

  for (const resident of residents) {
    if (statusFilter(resident, TabFilterValues.ALL, circleFilter, isMangopay)) {
      count++;
    }
  }

  return count;
}
