import type { FC } from 'react';
import { useState, useRef, useEffect, memo, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import type { CashTransactionGroupRowModel } from './model';
import { Iconify } from '@pflegenavi/web-components';
import { CashTransactionGroupType } from '@pflegenavi/shared/api';

interface DateColumnProps {
  row: Pick<
    CashTransactionGroupRowModel,
    'lastUpdateDateString' | 'lastUpdateTime'
  >;
}

export const DateColumn: FC<DateColumnProps> = ({ row }) => {
  return (
    <Stack gap={1}>
      <Typography variant="body2" fontWeight={700} lineHeight={1}>
        {row.lastUpdateDateString}
      </Typography>
      <Typography variant="body2" color="text.secondary" lineHeight={1}>
        {row.lastUpdateTime}
      </Typography>
    </Stack>
  );
};

interface CashAmountColumnProps {
  row: Pick<
    CashTransactionGroupRowModel,
    'transferCount' | 'transferAmount' | 'transferColor'
  >;
}

export const CashAmountColumn: FC<CashAmountColumnProps> = ({ row }) => {
  return (
    <Stack gap={1}>
      <Typography
        variant="body2"
        color={row.transferColor}
        fontWeight={700}
        lineHeight={1}
        className={'cashAmountColumn'}
      >
        {row.transferAmount}
      </Typography>
      <Typography variant="body2" color="text.secondary" lineHeight={1}>
        {row.transferCount}
      </Typography>
    </Stack>
  );
};

interface LinkAmountColumnProps {
  row: Pick<CashTransactionGroupRowModel, 'linkCount' | 'amountLinkedString'>;
}

export const LinkAmountColumn: FC<LinkAmountColumnProps> = ({ row }) => {
  return (
    <Stack gap={1}>
      <Typography variant="body2" fontWeight={700} lineHeight={1}>
        {row.amountLinkedString}
      </Typography>
      <Typography variant="body2" color="text.secondary" lineHeight={1}>
        {row.linkCount}
      </Typography>
    </Stack>
  );
};

export const IconCheckmark: FC = () => {
  return (
    <Iconify
      width={20}
      height={20}
      icon="eva:checkmark-circle-2-fill"
      color="success.main"
    />
  );
};

export const IconConfirmed: FC = () => {
  return (
    <Iconify
      width={20}
      height={20}
      icon="eva:done-all-outline"
      color="success.main"
    />
  );
};

export const IconNeutralCheckmark: FC = () => {
  return (
    <Iconify
      width={20}
      height={20}
      icon="eva:checkmark-circle-2-outline"
      color="success.main"
    />
  );
};

export const IconWarning: FC = () => {
  return (
    <Iconify
      width={20}
      height={20}
      icon="eva:alert-circle-fill"
      color="warning.main"
    />
  );
};

export interface CheckColumnProps {
  row: Pick<
    CashTransactionGroupRowModel,
    'mismatchConfirmation' | 'amountLinked' | 'cashChanged' | 'type'
  >;
}

export const CheckColumn: React.FC<CheckColumnProps> = memo(({ row }) => {
  const [animate, setAnimate] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true); // set to true on initial mount
  }, []);

  // eslint-disable-next-line complexity
  const icon = useMemo(() => {
    if (row.mismatchConfirmation) {
      return <IconConfirmed />;
    }

    switch (row.type) {
      case CashTransactionGroupType.Adjustment:
      case CashTransactionGroupType.Deposit: // No way to link a deposit atm
        return <IconNeutralCheckmark />;
    }

    const amountLinkedOr0 = row.amountLinked ?? 0;
    const cashChangedOr0 = row.cashChanged ?? 0;
    if (amountLinkedOr0 === cashChangedOr0) {
      return <IconCheckmark />;
    }

    return <IconWarning />;
  }, [row.mismatchConfirmation, row.amountLinked, row.cashChanged, row.type]);

  const prevIcon = useRef(icon);

  useEffect(() => {
    setIsMounted(true); // set to true on initial mount
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isMounted && prevIcon.current !== icon) {
      setAnimate(true);
      const timer = setTimeout(() => {
        setAnimate(false);
      }, 200);

      return () => {
        clearTimeout(timer);
      };
    }
    prevIcon.current = icon; // update the previous check value
  }, [isMounted, icon]);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        transition: 'all 0.2s ease',
        transform: animate ? 'scale(1.4)' : 'scale(1)',
      }}
    >
      {icon}
    </Stack>
  );
});

interface NotesColumnProps {
  row: Pick<CashTransactionGroupRowModel, 'notes'>;
}

export const InfoColumn: FC<NotesColumnProps> = ({ row }) => {
  return <Typography variant="body2">{row.notes}</Typography>;
};
