import { Divider, Stack } from '@pflegenavi/web-components';
import { MenuItem } from '@mui/material';
import { fmAppMainPages } from '@pflegenavi/frontend/routing';
import type { VFC } from 'react';
import { useEffect, useState } from 'react';
import {
  usePostTest400,
  useUserProfileApi,
} from '@pflegenavi/frontend/api-nursing-home';
import { useFeatureFlag } from '@pflegenavi/frontend/feature-flags';
import { FeatureFlag } from '@pflegenavi/shared/api';
import { useNavigate } from 'react-router-dom';

const useDev = (): VoidFunction => {
  const [triggerError, setTriggerError] = useState(false);
  const handleTriggerError = () => {
    setTriggerError(true);
  };
  useEffect(() => {
    if (triggerError) {
      setTriggerError(false);
      throw new Error('Dev test: Boom inside!');
    }
  }, [triggerError]);
  return handleTriggerError;
};

export const DevTriggerErrorMenu: VFC = () => {
  const devToolEnabled = useFeatureFlag(FeatureFlag.DevTools);
  const api = useUserProfileApi(true);
  const handleTriggerError = useDev();
  const navigate = useNavigate();

  const { mutate: post400 } = usePostTest400();

  const get404 = async () => {
    await api?.test404Get();
  };

  const get500 = async () => {
    await api?.test500Get();
  };

  return devToolEnabled ? (
    <Stack
      sx={{
        borderWidth: 2,
        borderColor: 'yellow',
        borderStyle: 'dashed',
      }}
    >
      <Divider sx={{ borderStyle: 'dashed' }} />
      <MenuItem
        onClick={() => {
          throw new Error('Dev test: Boom!');
        }}
      >
        💥 Boom!
      </MenuItem>
      <MenuItem onClick={handleTriggerError}>💥 Component error!</MenuItem>
      <MenuItem
        onClick={() => {
          navigate(fmAppMainPages.DEV_NOWHERE);
        }}
      >
        🪐 Far away..
      </MenuItem>
      <MenuItem onClick={get404}>💨 Fetch air</MenuItem>
      <MenuItem onClick={() => post400()}>Post 400</MenuItem>
      <MenuItem onClick={get500}>💾💥 Server failed 500</MenuItem>
    </Stack>
  ) : null;
};
