import { CircleFilterValues, TabFilterValues } from './useResidentsPage';
import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';
import { useDebouncedSetQueryParams } from '@pflegenavi/web-components';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useResidentTableUrlParamFilters = () => {
  const [searchParams] = useSearchParams();
  const { debouncedSetQueryParams } = useDebouncedSetQueryParams();

  const filterTerms = searchParams.get('search') || '';
  const setFilterTerms = useCallback(
    (value: string) => {
      debouncedSetQueryParams({ search: value });
    },
    [debouncedSetQueryParams]
  );

  const circleFilterValue =
    (searchParams.get('circleFilter') as CircleFilterValues) ||
    CircleFilterValues.ACCOUNT_BALANCE;
  const setCircleFilterValue = useCallback(
    (value: CircleFilterValues) => {
      debouncedSetQueryParams({ circleFilter: value });
    },
    [debouncedSetQueryParams]
  );

  const tabFilterValue =
    (searchParams.get('tabFilter') as TabFilterValues) || TabFilterValues.ALL;
  const setTabFilterValueBase = useCallback(
    (value: TabFilterValues) => {
      debouncedSetQueryParams({ tabFilter: value });
    },
    [debouncedSetQueryParams]
  );

  return {
    filterTerms,
    setFilterTerms,
    circleFilterValue,
    setCircleFilterValue,
    tabFilterValue,
    setTabFilterValueBase,
  };
};
