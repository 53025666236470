import euro1 from '../assets/euro_1.png';
import euro2 from '../assets/euro_2.png';
import euro5 from '../assets/euro_5.png';
import euro10 from '../assets/euro_10.png';
import euro20 from '../assets/euro_20.png';
import euro50 from '../assets/euro_50.png';
import euro100 from '../assets/euro_100.png';
import euro200 from '../assets/euro_200.png';
import euro500 from '../assets/euro_500.png';
import euro1000 from '../assets/euro_1000.png';
import euro2000 from '../assets/euro_2000.png';
import euro5000 from '../assets/euro_5000.png';
import euro10000 from '../assets/euro_10000.png';
import euro20000 from '../assets/euro_20000.png';
import euro50000 from '../assets/euro_50000.png';
import { useCashListConfiguration } from '@pflegenavi/frontend/api-nursing-home';
import { useMemo } from 'react';
import type { Coin } from '@pflegenavi/shared/api';
import type { CashState } from './form/model';

export { default as equals } from '../assets/equals.svg';
export { default as times } from '../assets/times.svg';

export const euro: { [key: number]: string } = {
  1: euro1,
  2: euro2,
  5: euro5,
  10: euro10,
  20: euro20,
  50: euro50,
  100: euro100,
  200: euro200,
  500: euro500,
  1000: euro1000,
  2000: euro2000,
  5000: euro5000,
  10000: euro10000,
  20000: euro20000,
  50000: euro50000,
};

const factors = [
  50000, 20000, 10000, 5000, 2000, 1000, 500, 200, 100, 50, 20, 10, 5, 2, 1,
];

export function coinsToCashState(coins: Coin[]): CashState {
  return coins.reduce((state, coin) => {
    if (coin.amount === 0) {
      return state;
    }
    return {
      ...state,
      [coin.factor]: coin.amount,
    };
  }, {});
}

export function compareCashStates(a: CashState, b: CashState): boolean {
  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);

  if (aKeys.length !== bKeys.length) {
    return false;
  }

  for (const key of aKeys) {
    if (a[Number(key)] !== b[Number(key)]) {
      return false;
    }
  }

  return true;
}

export function computeCashStateDelta(
  cashState: CashState,
  coins: Coin[]
): CashState {
  return coins.reduce((state, coin) => {
    return {
      ...state,
      [coin.factor]: (state[coin.factor] ?? 0) - coin.amount,
    };
  }, cashState);
}

export function initialAmountToCashState(
  amount: number | undefined
): CashState {
  if (!amount) {
    return {};
  }

  const cashState: Record<number, number> = {};
  for (
    let current_factor_index = 0;
    current_factor_index < factors.length;
    current_factor_index++
  ) {
    const factor = factors[current_factor_index];
    while (amount >= factor) {
      amount -= factor;
      if (!cashState[factor]) {
        cashState[factor] = 1;
      } else {
        cashState[factor] += 1;
      }
    }
  }
  return cashState;
}

/**
 * Converts the amount into coin lists, but considers available coins
 * @param amount
 * @param coins
 */
// eslint-disable-next-line complexity
export function initialAmountToCashStateWithLimits(
  amount: number | undefined,
  coins: Coin[]
): CashState {
  if (!amount) {
    return {};
  }

  const cashState: Record<number, number> = {};
  const coinsCopy = coins.map((coin) => ({ ...coin })); // Make a copy of the coins array

  for (
    let current_factor_index = 0;
    current_factor_index < factors.length;
    current_factor_index++
  ) {
    const factor = factors[current_factor_index];

    while (amount >= factor && hasAvailableCoins(coinsCopy, factor)) {
      amount -= factor;
      decrementAvailableCoins(coinsCopy, factor);

      if (!cashState[factor]) {
        cashState[factor] = 1;
      } else {
        cashState[factor] += 1;
      }
    }
  }

  return cashState;
}

function hasAvailableCoins(coins: Coin[], factor: number): boolean {
  const coin = coins.find((c) => c.factor === factor);
  return coin !== undefined && coin.amount > 0;
}

function decrementAvailableCoins(coins: Coin[], factor: number): void {
  const coin = coins.find((c) => c.factor === factor);
  if (coin) {
    coin.amount -= 1;
  }
}

export const mapFactorToImage = (factor: number): string => {
  return euro[factor] ?? euro[500];
};

export const isBanknote = (factor: number): boolean => {
  return factor > 499;
};

export const isCoin = (factor: number): boolean => {
  return !isBanknote(factor);
};

export const useBankNotesAndCoins = (
  cashListId: string | undefined
): {
  bankNotes: Coin[];
  coins: Coin[];
  bankAccountAmount: number;
  all: Coin[];
  totalAmount: number;
  isLoading: boolean;
} => {
  const cashListConfiguration = useCashListConfiguration();
  const cashList = cashListConfiguration.cashLists.find(
    (cashList) => cashList.id === cashListId
  )!;

  const { bankNotes, coins } = useMemo(() => {
    const coins = cashList?.coins ?? [];
    return {
      bankNotes: coins.filter((coin) => isBanknote(coin.factor)),
      coins: coins.filter((coin) => isCoin(coin.factor)).reverse(),
    };
  }, [cashList?.coins]);

  return {
    bankAccountAmount: cashList?.bankAccountAmountInCent ?? 0,
    bankNotes,
    coins,
    isLoading: false,
    all: cashList?.coins ?? [],
    totalAmount: cashList?.totalInCent ?? 0,
  };
};

export const sumCoins = (coins: Coin[]): number => {
  return sumCoinsInCents(coins) / 100;
};

export const sumCoinsInCents = (coins: Coin[]): number => {
  return coins.reduce((total, coin) => total + coin.factor * coin.amount, 0);
};

export const sumCashState = (cashState: CashState): number => {
  return sumCoins(
    Object.entries(cashState).map(([factor, amount]) => ({
      factor: Number(factor),
      amount,
    }))
  );
};

export const sumCashStateInCents = (cashState: CashState): number => {
  return sumCoinsInCents(
    Object.entries(cashState).map(([factor, amount]) => ({
      factor: Number(factor),
      amount,
    }))
  );
};

export const sumCashStateInCentsByType = (
  cashState: CashState
): {
  banknotes: number;
  coins: number;
  total: number;
} => {
  const factors = Object.keys(cashState).map(Number);

  return factors.reduce(
    (aggregate, factor) => {
      const added = cashState[factor] * factor;
      return {
        ...aggregate,
        total: aggregate.total + added,
        ...(isBanknote(factor)
          ? { banknotes: aggregate.banknotes + added }
          : { coins: aggregate.coins + added }),
      };
    },
    { banknotes: 0, coins: 0, total: 0 }
  );
};

export const cashStateToCoins = (cashState: CashState): Coin[] => {
  const coins = Object.entries(cashState).map(([factor, amount]) => ({
    factor: Number(factor),
    amount,
  }));

  coins.sort((a, b) => b.factor - a.factor);
  return coins;
};
