import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Iconify,
  MenuItem,
  TableMoreMenu,
  Tooltip,
} from '@pflegenavi/web-components';
import { useNavigate } from 'react-router-dom';
import { generatePath, nhAppMainPages } from '@pflegenavi/frontend/routing';
import { useFeatureFlagEnabled } from '@pflegenavi/frontend/feature-flags';
import { FeatureFlag } from '@pflegenavi/shared/api';

interface DropDownMenuProps {
  handleRemoveLink: () => void;
  groupId?: string;
  disabled?: boolean;
  disabledTooltipTitle?: string;
}

export const DropDownMenu: FC<DropDownMenuProps> = ({
  handleRemoveLink,
  groupId,
  disabled,
  disabledTooltipTitle,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleCloseMenu = (event?: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(null);
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  };
  const groupDetailPath = useFeatureFlagEnabled(
    FeatureFlag.AccountingPaymentsNew
  )
    ? nhAppMainPages.NURSING_HOME_ACCOUNTING_GROUP_DETAIL
    : nhAppMainPages.CASH_MANAGEMENT_GROUP_DETAIL;

  const handleNavigateToGroupDetails = useCallback(() => {
    if (groupId) {
      navigate(
        generatePath(groupDetailPath, {
          groupId,
        })
      );
    }
  }, [groupId, navigate, groupDetailPath]);

  return (
    <TableMoreMenu
      open={openMenu}
      onOpen={handleOpenMenu}
      onClose={handleCloseMenu}
      iconButtonSx={{ mr: '-12px' }}
      actions={
        <>
          <Tooltip title={disabledTooltipTitle} placement="top">
            <div>
              <MenuItem
                sx={{ color: 'error.main' }}
                onClick={(e) => {
                  handleRemoveLink();
                  handleCloseMenu();
                  e.preventDefault();
                  e.stopPropagation();
                }}
                disabled={disabled}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                {t('cashManagement.link.remove')}
              </MenuItem>
            </div>
          </Tooltip>
          {groupId && (
            <MenuItem
              onClick={(e) => {
                handleNavigateToGroupDetails();
                handleCloseMenu();
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Iconify icon={'eva:eye-fill'} />
              {t('cashManagement.view-transaction-group')}
            </MenuItem>
          )}
        </>
      }
    />
  );
};
