import type { Coin } from '@pflegenavi/shared/api';

interface GetCoinsFittingOpts {
  ignoreAvailableCoins: boolean;
  returnCount?: number;
  blacklist?: Coin[];
}

/** Returns a list of coins (factor, amount) fitting into the given amount */
export const getCoinsFittingIntoAmount = (
  amountInCent: number,
  coins: Coin[],
  { ignoreAvailableCoins, returnCount = 4, blacklist }: GetCoinsFittingOpts
): Coin[] => {
  const coinsFittingIntoAmount: Coin[] = [];

  coins = coins.filter(
    (coin) =>
      !blacklist?.some(
        (blacklistedCoin) => blacklistedCoin.factor === coin.factor
      )
  );

  coins.sort((a, b) => b.factor - a.factor);

  for (const coin of coins) {
    const coinsNeeded = Math.floor(amountInCent / coin.factor);
    const coinsUsed = ignoreAvailableCoins
      ? coinsNeeded
      : Math.min(coinsNeeded, coin.amount);
    if (coinsUsed > 0) {
      coinsFittingIntoAmount.push({
        factor: coin.factor,
        amount: coinsUsed,
      });
    }
  }

  return coinsFittingIntoAmount.slice(0, returnCount);
};
