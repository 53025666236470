import {
  Card,
  GraphIcon,
  LoadingContainer,
  SvgIconStyle,
} from '@pflegenavi/web-components';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { sumCoins, useBankNotesAndCoins } from './euro';
import { CardHeader, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormatting } from '@pflegenavi/frontend/localization';
import { useCashListConfiguration } from '@pflegenavi/frontend/api-nursing-home';

export const CashCards: FC = () => {
  // TODO: Remove legacy code
  const cashListId = useCashListConfiguration().selectedCashListId;
  const { totalAmount, bankAccountAmount, all, isLoading } =
    useBankNotesAndCoins(cashListId);

  const { t } = useTranslation();

  const sum = useMemo(() => {
    return sumCoins(all);
  }, [all]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <CashCard
          title={t('cashManagement.total')}
          color="primary.main"
          amount={totalAmount / 100}
          isLoading={isLoading}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <CashCard
          title={t('cashManagement.total-cash')}
          color="info.main"
          amount={sum}
          isLoading={isLoading}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <CashCard
          title={t('cashManagement.total-account')}
          color="error.main"
          amount={bankAccountAmount / 100}
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  );
};

interface CashCardProps {
  amount: number;
  isLoading: boolean;
  color: string;
  title: string;
}

const CashCard: FC<CashCardProps> = ({ amount, isLoading, color, title }) => {
  const { fCurrency } = useFormatting();

  if (isLoading) {
    return <LoadingContainer height={200} />;
  }

  return (
    <Card sx={{ p: 3, height: '100%', minWidth: 252 }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={5}
      >
        <Stack direction="column">
          <CardHeader
            title={title}
            sx={{ mb: 0, p: 0 }}
            titleTypographyProps={{
              variant: 'subtitle2',
              sx: { fontWeight: 700 },
            }}
          />
          <Typography variant="h4">{fCurrency(amount)}</Typography>
        </Stack>

        <SvgIconStyle sx={{ width: 52, height: 35, color }} src={GraphIcon} />
      </Stack>
    </Card>
  );
};
