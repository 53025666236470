import type { FC } from 'react';
import React, { useCallback } from 'react';
import {
  Modal,
  ModalInner,
  useErrorSnackbar,
} from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import type { BaseData } from '@pflegenavi/shared/api';
import { LegalEntityForm } from './LegalEntityForm';
import { useCreateLegalEntityPhoenix } from '@pflegenavi/frontend/api-nursing-home';

export interface AddLegalEntityModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const AddLegalEntityModal: FC<AddLegalEntityModalProps> = ({
  open,
  setOpen,
}) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const { enqueueErrorSnackbar } = useErrorSnackbar();

  const { mutateAsync: createLegalEntity } = useCreateLegalEntityPhoenix();

  const handleClose = (_: any, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const handleSubmitLegalEntity = useCallback(
    async (data: {
      id: string; // id ignored in this case
      base_data: BaseData;
    }) => {
      try {
        await createLegalEntity(data.base_data);
        enqueueSnackbar(t('legal-entities.form.success.created'), {
          variant: 'success',
        });
        setOpen(false);
      } catch (e) {
        enqueueErrorSnackbar(
          e,
          t('legal-entities.form.error.failed-to-create')
        );
      }
    },
    [createLegalEntity, enqueueErrorSnackbar, enqueueSnackbar, setOpen, t]
  );

  return (
    <Modal open={open} handleClose={handleClose}>
      <ModalInner>
        <LegalEntityForm
          title={t('legal-entities.add-legal-entity')}
          setOpen={setOpen}
          handleSubmitLegalEntity={handleSubmitLegalEntity}
        />
      </ModalInner>
    </Modal>
  );
};
