import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { CashImage } from '../CashImage';
import type { Coin } from '@pflegenavi/shared/api';

interface QuickSelectionProposedCashProps {
  targetReached: boolean;
  fittingCoins: Coin[];
  handleCoinSelect: (event: React.MouseEvent, coin: Coin) => void;
}

export const QuickSelectionProposedCash: FC<
  QuickSelectionProposedCashProps
> = ({ fittingCoins, targetReached, handleCoinSelect }) => {
  const { t } = useTranslation();
  return (
    <Stack gap={1}>
      <Typography variant="h6" sx={{ lineHeight: 1 }}>
        {t('quick-selection.selection-title')}
      </Typography>
      <Stack direction="row" gap={1}>
        {targetReached && (
          <Typography variant="body2" sx={{ ...animation }} color="grey.600">
            {t('quick-selection.target-reached')}
          </Typography>
        )}
        {!targetReached && fittingCoins.length === 0 && (
          <Typography
            variant="body2"
            sx={{
              ...animation,
            }}
            color="grey.600"
          >
            {t('quick-selection.no-coins-left')}
          </Typography>
        )}
        {fittingCoins.map((coin) => (
          <CashImage
            key={coin.factor}
            factor={coin.factor}
            sx={{
              cursor: 'pointer',
              ...animation,
            }}
            tooltipPlacement="top"
            onClick={(event) => handleCoinSelect(event, coin)}
          />
        ))}
      </Stack>
    </Stack>
  );
};
export const animation = {
  animation: 'fadein 400ms ease-in-out forwards',
  '@keyframes fadein': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
};
