// only the parts of BankAccountDepositOrWithdrawForm that are rendering the form
import React, { type FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormatting } from '@pflegenavi/frontend/localization';
import { ChangeCashMode } from './model';
import { MoneyInput, Stack, Typography } from '@pflegenavi/web-components';
import { MAX_BANK_ACCOUNT_CHANGE } from '@pflegenavi/shared/constants';
import { Button, Divider, FormHelperText } from '@mui/material';
import euroSign from '../../assets/cash_list.svg';
import { SkipCashSelectionButton } from './atoms/SkipCashSelectionButton';
import type { BankAccountDepositOrWithdrawFormProps } from './BankAccountForm';

type BankAccountDepositOrWithdrawFormViewProps = {
  bankAccountChange?: number;
  setBankAccountChange: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  handleSwitchToCash?: VoidFunction;
  totalBankBalance: number;
  newAccountingPaymentsFeatureFlagEnabled?: boolean;
} & Pick<
  BankAccountDepositOrWithdrawFormProps,
  | 'allowSkipCashSelection'
  | 'setSkippedCashSelection'
  | 'requiredAmount'
  | 'mode'
  | 'nextStep'
  | 'handleClose'
>;
// Only the parts of BankAccountDepositOrWithdrawForm that are rendering the form
export const BankAccountDepositOrWithdrawFormView: FC<
  BankAccountDepositOrWithdrawFormViewProps
  // eslint-disable-next-line complexity
> = ({
  bankAccountChange,
  mode,
  setBankAccountChange,
  requiredAmount,
  handleSwitchToCash,
  totalBankBalance,
  allowSkipCashSelection,
  setSkippedCashSelection,
  nextStep,
  handleClose,
  newAccountingPaymentsFeatureFlagEnabled,
}) => {
  const { t } = useTranslation();
  const { fCurrencyCents } = useFormatting();

  const isDeposit = mode === ChangeCashMode.Deposit;

  const changeFactor = useMemo(() => {
    if (isDeposit) {
      return 1;
    }
    return -1;
  }, [isDeposit]);

  const newBalance = totalBankBalance + changeFactor * (bankAccountChange ?? 0);

  const requiredAmountSet =
    requiredAmount === undefined || requiredAmount === bankAccountChange;

  const confirmButtonDisabled = useMemo(() => {
    const noChange = bankAccountChange === 0 || bankAccountChange === undefined;
    const newBalanceNegative =
      mode === ChangeCashMode.Deposit ? false : newBalance < 0;
    return noChange || newBalanceNegative || !requiredAmountSet;
  }, [bankAccountChange, mode, newBalance, requiredAmountSet]);

  const moneyInputHelperText = useMemo(() => {
    const newBalanceNegative =
      mode === ChangeCashMode.Deposit ? false : newBalance < 0;
    if (newBalanceNegative) {
      return t('cashManagement.bank-account-new-balance-negative-error');
    }
    if (!requiredAmountSet) {
      return t('cashManagement.bank-account-change-amount-error', {
        amount: fCurrencyCents(requiredAmount),
      });
    }
    return undefined;
  }, [fCurrencyCents, mode, newBalance, requiredAmount, requiredAmountSet, t]);

  return (
    <Stack gap={2}>
      <Typography fontWeight={700}>
        {t('cashManagement.total-account')}
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ height: 90 }}
      >
        <MoneyInput
          label={t(
            `cashManagement.${isDeposit ? 'deposit' : 'withdrawal'}-amount`
          )}
          value={bankAccountChange}
          maxValue={MAX_BANK_ACCOUNT_CHANGE}
          onChange={(newValue) => {
            setBankAccountChange(newValue);
          }}
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 7 }}
          helperText={
            moneyInputHelperText && (
              <FormHelperText sx={{ m: 0 }} error>
                {moneyInputHelperText}
              </FormHelperText>
            )
          }
        />
        <Stack sx={{ gap: 0.5 }}>
          <Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={5}
            >
              <Typography variant="subtitle2" fontWeight={700}>
                {t('cashManagement.current-bank-account-balance')}
              </Typography>
              <Typography variant="subtitle2" fontWeight={700}>
                {fCurrencyCents(totalBankBalance)}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={5}
            >
              <Typography variant="subtitle2">
                {t(
                  `cashManagement.${
                    isDeposit ? 'deposit' : 'withdrawal'
                  }-amount`
                )}
              </Typography>
              <Typography
                variant="subtitle2"
                color={isDeposit ? 'success.main' : 'error.main'}
              >
                {bankAccountChange === 0 || bankAccountChange === undefined
                  ? '--'
                  : fCurrencyCents(changeFactor * bankAccountChange)}
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={5}
          >
            <Typography variant="subtitle2" fontWeight={700}>
              {t('cashManagement.new-bank-account-balance')}
            </Typography>
            <Typography variant="subtitle2" fontWeight={700}>
              {fCurrencyCents(newBalance)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack>
        <Stack
          direction="row"
          justifyContent={
            newAccountingPaymentsFeatureFlagEnabled
              ? 'flex-end'
              : 'space-between'
          }
          alignItems="center"
        >
          {!newAccountingPaymentsFeatureFlagEnabled && (
            <Button
              sx={{
                visibility: handleSwitchToCash ? undefined : 'hidden',
              }}
              onClick={handleSwitchToCash}
              startIcon={
                <img
                  style={{
                    width: 20,
                    marginTop: -2,
                  }}
                  alt=""
                  src={euroSign}
                />
              }
            >
              {t(
                `cashManagement.${
                  isDeposit ? 'deposit-to' : 'withdraw-from'
                }-cash`
              )}
            </Button>
          )}

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1.5}
          >
            <Button variant="outlined" onClick={handleClose}>
              {t('cancel')}
            </Button>
            <Button
              data-cy="cash-mgmt-button"
              variant="contained"
              disabled={confirmButtonDisabled}
              onClick={nextStep}
            >
              {t('actions.confirm')}
            </Button>
          </Stack>
        </Stack>
        {allowSkipCashSelection && (
          <Stack direction="row" justifyContent="flex-start">
            <SkipCashSelectionButton
              onClick={() => {
                setSkippedCashSelection(true);
                nextStep();
              }}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
