import type { FC } from 'react';
import React, { useMemo } from 'react';
import EvaPlusFill from '~icons/eva/plus-fill';
import EvaSyncOutline from '~icons/eva/sync-outline';
import EvaPersonFill from '~icons/eva/person-fill';
import MdiReceiptText from '~icons/mdi/receipt-text';
import MdiReceiptTextPlus from '~icons/mdi/receipt-text-plus';

import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Tooltip,
} from '@mui/material';
import { MenuPopover } from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';
import { useMenuPopover } from './useMenuPopover';
import { MenuBarIconButton } from '@pflegenavi/frontend/layout';
import { useNavigate } from 'react-router-dom';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import { ChangeCashModal } from '@pflegenavi/frontend/pages/cash-management';
import { useTrackStructuredEvent } from '@pflegenavi/frontend/tracking';
import { useFeatureFlagEnabled } from '@pflegenavi/frontend/feature-flags';
import { FeatureFlag } from '@pflegenavi/shared/api';

export const QuickCreation: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const CoinListEnabled = useFeatureFlagEnabled(FeatureFlag.CoinList);

  const { open, handleClick, handleClose, anchorEl } =
    useMenuPopover('QuickCreation');

  const trackEvent = useTrackStructuredEvent('QuickCreation');

  const {
    navigateAddReceiptBatch,
    navigateAddReceipt,
    navigateChangeCash,
    navigateAddResident,
  } = useMemo(() => {
    const makeUseNavigate = (
      path: string,
      name: 'ReceiptBatch' | 'Resident' | 'Receipt'
    ) => {
      return () => {
        void trackEvent({
          action: 'click',
          label: name,
        });
        navigate(path);
        handleClose();
      };
    };

    const navigateAddResident = makeUseNavigate(
      nhAppMainPages.RESIDENTS_ADD,
      'Resident'
    );
    const navigateAddReceipt = makeUseNavigate(
      nhAppMainPages.TRANSACTIONS_ADD_EMPTY_RESIDENT,
      'Receipt'
    );
    const navigateAddReceiptBatch = makeUseNavigate(
      nhAppMainPages.RECEIPTS_BATCH_ADD,
      'ReceiptBatch'
    );
    const navigateChangeCash = () => {
      void trackEvent({
        action: 'click',
        label: 'ChangeCash',
      });
      setChangeCashOpen(true);
      handleClose();
    };

    return {
      navigateAddResident,
      navigateAddReceipt,
      navigateAddReceiptBatch,
      navigateChangeCash,
    };
  }, [trackEvent, navigate, handleClose]);

  const [changeCashOpen, setChangeCashOpen] = React.useState(false);

  return (
    <>
      <Tooltip title={t('menu-bar.quick-creation.title')}>
        <MenuBarIconButton
          name={'quickLinks'}
          onClick={handleClick}
          open={Boolean(open)}
        >
          <EvaPlusFill width={24} height={24} />
        </MenuBarIconButton>
      </Tooltip>
      <ChangeCashModal open={changeCashOpen} setOpen={setChangeCashOpen} />
      <MenuPopover
        open={Boolean(open)}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          p: 0,
          width: 'fit',
          mt: 0.5,
          ml: 0.5,
        }}
      >
        <List
          disablePadding
          subheader={
            <ListSubheader
              disableSticky
              sx={{
                py: 1,
                px: 2.5,
                typography: 'overline',
                mt: 1,
              }}
            >
              {t('quick-actions.title')}
            </ListSubheader>
          }
        >
          <ListItemButton onClick={navigateAddReceipt}>
            <ListItemIcon>
              <MdiReceiptText />
            </ListItemIcon>
            <ListItemText>{t('quick-actions.receipt')}</ListItemText>
          </ListItemButton>
          <ListItemButton onClick={navigateAddReceiptBatch}>
            <ListItemIcon>
              <MdiReceiptTextPlus />
            </ListItemIcon>
            <ListItemText>{t('quick-actions.receipt-batch')}</ListItemText>
          </ListItemButton>
          {CoinListEnabled && (
            <ListItemButton
              className={'quickLinksChangeCash'}
              onClick={navigateChangeCash}
            >
              <ListItemIcon>
                <EvaSyncOutline />
              </ListItemIcon>
              <ListItemText>{t('quick-actions.change-cash')}</ListItemText>
            </ListItemButton>
          )}

          <ListItemButton onClick={navigateAddResident}>
            <ListItemIcon>
              <EvaPersonFill />
            </ListItemIcon>
            <ListItemText>{t('quick-actions.resident')}</ListItemText>
          </ListItemButton>
        </List>
      </MenuPopover>
    </>
  );
};
