import { ChangeCashMode } from './model';
import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Iconify, Stack, Typography } from '@pflegenavi/web-components';
import { LetterChip } from './LetterChip';

interface ChangeCashFormStep1Props {
  nextStep: () => void;
  setMode: (mode: ChangeCashMode) => void;
}

export const ChangeCashFormStep1: FC<ChangeCashFormStep1Props> = ({
  nextStep,
  setMode,
}) => {
  const { t } = useTranslation();

  return (
    <Stack direction="column" gap={1} sx={{ paddingY: 1 }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <LetterChip>A</LetterChip>
        <Typography variant="h6" fontWeight={700}>
          {t('cashManagement.deposit')} / {t('cashManagement.withdraw')}
        </Typography>
      </Stack>

      <Typography variant="body1">
        {t('cashManagement.deposit-or-withdraw')}
      </Typography>

      <Stack direction="row" gap={1} justifyContent="end">
        <Button
          name={'changeCashWithdraw'}
          sx={{ width: 140 }}
          variant="contained"
          color="error"
          endIcon={<Iconify icon="eva:trending-down-fill" />}
          onClick={() => {
            setMode(ChangeCashMode.Withdraw);
            nextStep();
          }}
        >
          {t('cashManagement.withdraw')}
        </Button>

        <Button
          name={'changeCashDeposit'}
          sx={{ width: 140 }}
          variant="contained"
          endIcon={<Iconify icon="eva:trending-up-fill" />}
          onClick={() => {
            setMode(ChangeCashMode.Deposit);
            nextStep();
          }}
        >
          {t('cashManagement.deposit')}
        </Button>
      </Stack>
    </Stack>
  );
};
