import type { FC } from 'react';
import { useCallback } from 'react';
import React, { useMemo, useState } from 'react';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';
import type {
  CashList,
  FinanceResult,
} from '@pflegenavi/frontend/api-nursing-home';
import {
  useCashListConfiguration,
  useGetFinancePhoenix,
} from '@pflegenavi/frontend/api-nursing-home';
import { Box, Container, Stack, Typography, useTheme } from '@mui/material';
import { useFormatting } from '@pflegenavi/frontend/localization';
import { Button, Iconify, LoadingContainer } from '@pflegenavi/web-components';
import {
  CashListStorageType,
  FeatureFlag,
  FeatureFlagStatus,
} from '@pflegenavi/shared/api';
import { useTranslation } from 'react-i18next';
import { NursingHomeAccountingBreadcrumbs } from './NursingHomeAccountingBreadcrumbs';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import { GroupContainer } from './overview/GroupContainer';
import { RowDivider } from './overview/RowDivider';
import { Row } from './overview/Row';
import { Title } from './overview/Title';
import { Bank } from './overview/Bank';
import { Cash } from './overview/Cash';
import { useFeatureFlag } from '@pflegenavi/frontend/feature-flags';
import { NewAccountingOverviewTotals } from './components/NewAccountingOverviewTotals';
import { ChangeCashModal } from '@pflegenavi/frontend/pages/cash-management';
import { NewAccountingOverviewTotalsMangopay } from './components/NewAccountingOverviewTotalsMangopay';
import { BankMangopay } from './overview/BankMangopay';

export const MangopayOverviewPage: FC = () => {
  const { t } = useTranslation();
  const [openChangeCashModal, setOpenChangeCashModal] = useState(false);

  const { selectedNursingHome } = useNursingHomeContext();

  // Data fetches
  const { data, isLoading } = useGetFinancePhoenix({
    nursingHomeId: selectedNursingHome?.id,
  });

  const coinListEnabled =
    useFeatureFlag(FeatureFlag.CoinList) === FeatureFlagStatus.Enabled;

  const cashListConfiguration = useCashListConfiguration();

  const cashAccounts = useMemo(() => {
    return cashListConfiguration?.cashLists?.filter(
      (cashList) => cashList.storageType & CashListStorageType.Cash
    );
  }, [cashListConfiguration?.cashLists]);
  const bankAccounts = useMemo(() => {
    return cashListConfiguration?.cashLists?.filter(
      (cashList) => cashList.storageType === CashListStorageType.BankAccount
    );
  }, [cashListConfiguration?.cashLists]);

  // eslint-disable-next-line require-await
  const showAccountingOverviewPdf = useCallback(async () => {
    try {
      // if (!selectedNursingHome || !data) {
      //   throw new Error('No nursing home selected');
      // }
      //
      // const currentDate = new Date();
      // const pdfPromise = handlePrintPdfAccountingOverview({
      //   nursingHomeName: selectedNursingHome.name,
      //   date: currentDate,
      //   logo: pdfLogo ? pdfLogo.url : pflegenaviBase64Logo,
      //   coinListEnabled: coinListEnabled,
      //   serviceProviderPaymentsEnabled:
      //   serviceProviderPaymentsFeatureFlagEnabled,
      //
      //   stripeAvailableAmountInCents:
      //   data.statistics.StripeAvailable.amountInCents,
      //   stripePendingAmountInCents: data.statistics.StripePending.amountInCents,
      //   stripeAvailableSoonAmountInCents:
      //   data.statistics.StripeAvailableSoon.amountInCents,
      //   stripePayoutAmountInCents: data.statistics.Payouts.amountInCents,
      //   stripePendingReversalsInCents: totalPendingReversals,
      //   stripeAmountInCents: data.statistics.Stripe.amountInCents,
      //
      //   bankAccounts: bankAccounts,
      //   payoutTargetCashListId: cashListConfiguration.payoutTargetCashListId,
      //   bankOutgoingServiceProviderPaymentsAmountInCents:
      //   serviceProviderPaymentSum,
      //   serviceProviderPaymentTargetCashListId:
      //   cashListConfiguration.serviceProviderPaymentTargetCashListId,
      //
      //   cashAccounts: cashAccounts,
      // });
      //
      // const minTime = new Promise((resolve) => setTimeout(resolve, 500));
      // const [pdf] = await Promise.all([pdfPromise, minTime]);
      //
      // pdf.save(
      //   makeSafeFilename(
      //     t('accounting.report.pdf-name', {
      //       nursingHomeName: selectedNursingHome.name,
      //       date: format(currentDate, 'yyyy-MM-dd'),
      //     })
      //   )
      // );
    } catch (error) {
      // enqueueErrorSnackbar(error, t('errors.something-went-wrong'), {
      //   variant: 'error',
      // });
    }
  }, []);

  return (
    <Container maxWidth={'lg'}>
      <NursingHomeAccountingBreadcrumbs
        pageTitle={t('accounting.nav.overview')}
        route={nhAppMainPages.NURSING_HOME_ACCOUNTING_OVERVIEW}
        action={
          <Stack
            direction="row"
            gap={1}
            alignContent="center"
            alignSelf="center"
          >
            <Button
              variant="outlined"
              startIcon={<Iconify icon="eva:download-fill" />}
              onClick={showAccountingOverviewPdf}
            >
              {t('accounting.dashboard.download-button.overview-pdf')}
            </Button>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:sync-outline" />}
              onClick={() => setOpenChangeCashModal(true)}
            >
              {t('cashManagement.change-cash')}
            </Button>
            <ChangeCashModal
              open={openChangeCashModal}
              setOpen={setOpenChangeCashModal}
            />
          </Stack>
        }
      />

      <OverviewPageContent
        data={data}
        isLoading={isLoading}
        cashAccounts={cashAccounts}
        bankAccounts={bankAccounts}
        cashLists={cashListConfiguration.cashLists}
        coinListEnabled={coinListEnabled}
      />
    </Container>
  );
};

export interface OverviewPageNewContentProps {
  data: FinanceResult | undefined;
  isLoading: boolean;
  cashAccounts: CashList[];
  bankAccounts: CashList[];
  coinListEnabled: boolean;
  cashLists: CashList[];
}

// eslint-disable-next-line complexity
const OverviewPageContent: FC<OverviewPageNewContentProps> = ({
  data,
  isLoading,
  bankAccounts,
  cashAccounts,
  coinListEnabled,
  cashLists,
}: OverviewPageNewContentProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { fCurrencyCents } = useFormatting();

  const { rowTextProps, totalTextProps } = useMemo(() => {
    return {
      rowTextProps: {
        variant: 'subtitle2' as const,
        color: theme.palette.grey['600'],
      },
      rowSubTextProps: {
        variant: 'caption' as const,
        color: theme.palette.grey['600'],
      },
      totalTextProps: {
        variant: 'subtitle2' as const,
        fontWeight: 700,
      },
    };
  }, [theme.palette.grey]);

  if (!data || isLoading) {
    return <LoadingContainer />;
  }

  // TODO: Replace NewAccountingOverviewTotals  with a new implementation
  return (
    <Stack gap={3} maxWidth={925}>
      <Box sx={{ width: '100%' }}>
        <NewAccountingOverviewTotalsMangopay
          wallet={data?.data?.mangopay?.wallet / 100}
          receipts={data?.data?.receipts?.draft / 100}
          payoutSum={data?.data?.mangopay?.payouts / 100}
          payinSum={data?.data?.mangopay?.payins / 100}
          cashLists={cashLists}
        />
      </Box>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        gap={3}
      >
        <Stack
          gap={3}
          direction={coinListEnabled ? 'column' : 'row'}
          flexWrap="wrap"
        >
          <GroupContainer>
            <Title>{t('accounting.dashboard.nursing-home-wallet-title')}</Title>
            <Row>
              <Typography {...totalTextProps}>
                {t('accounting.dashboard.total-nursing-home-wallet')}
              </Typography>
              <Typography {...totalTextProps}>
                {fCurrencyCents(data?.data?.mangopay?.wallet ?? 0)}
              </Typography>
            </Row>
          </GroupContainer>

          <GroupContainer>
            <Title>{t('accounting.dashboard.resident-balance-title')}</Title>
            <Row>
              <Typography {...rowTextProps}>
                {t('accounting.dashboard.resident-balance')}
              </Typography>
              <Typography {...rowTextProps}>
                {fCurrencyCents(data?.data?.total_resident_balance ?? 0)}
              </Typography>
            </Row>
            <RowDivider />
            <Row>
              <Typography {...totalTextProps}>
                {t('accounting.dashboard.total-resident-balance')}
              </Typography>
              <Typography {...totalTextProps}>
                {fCurrencyCents(data?.data?.total_resident_balance ?? 0)}
              </Typography>
            </Row>
          </GroupContainer>

          {coinListEnabled &&
            bankAccounts.map((bankAccount) => (
              <BankMangopay
                cashListId={bankAccount.id}
                payoutSum={data.data?.mangopay?.payouts}
                payinSum={data.data?.mangopay?.payins}
              />
            ))}
          <GroupContainer>
            <Title>{t('accounting.dashboard.receipts-title')}</Title>
            <Row>
              <Typography {...rowTextProps}>
                {t('accounting.dashboard.receipts-amount')}
              </Typography>
              <Typography {...rowTextProps}>
                {fCurrencyCents(data?.data?.receipts?.draft)}
              </Typography>
            </Row>
            <RowDivider />
            <Row>
              <Typography {...totalTextProps} fontWeight={700}>
                {t('accounting.dashboard.receipts-total-balance')}
              </Typography>
              <Typography {...totalTextProps} fontWeight={700}>
                {fCurrencyCents(data?.data?.receipts?.draft)}
              </Typography>
            </Row>
          </GroupContainer>
        </Stack>

        {coinListEnabled && (
          <Stack gap={3}>
            {cashAccounts.map((cashAccount) => (
              <Cash cashListId={cashAccount.id} />
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
