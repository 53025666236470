import React, { useCallback } from 'react';
import type { StructuredEvents } from '@pflegenavi/frontend/tracking';
import { useTrackStructuredEvent } from '@pflegenavi/frontend/tracking';

export const useMenuPopover: (label: StructuredEvents['AppMenu']['label']) => {
  anchorEl: HTMLElement | null;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleClose: () => void;
  open: boolean;
} = (label) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const trackEvent = useTrackStructuredEvent('AppMenu');

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      void trackEvent({
        action: 'click',
        label: label,
      });
      setAnchorEl(event.currentTarget);
    },
    [trackEvent, label]
  );
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return {
    anchorEl,
    open,
    handleClick,
    handleClose,
  };
};
