import {
  useIncompleteReceiptCount,
  useUnsubmittedReceiptBatchCount,
} from '@pflegenavi/frontend/api-nursing-home';
import { IncompleteTransactionsCountContext } from '@pflegenavi/frontend/layout';
import * as React from 'react';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';

export const IncompleteTransactionsProvider = ({
  children,
}: {
  children: React.ReactElement;
}): JSX.Element => {
  const { selectedNursingHome } = useNursingHomeContext();

  const { data: receiptCount, isLoading } = useIncompleteReceiptCount(
    selectedNursingHome?.id
  );

  const { data: receiptBatchCount, isLoading: isLoadingReceiptBatchCount } =
    useUnsubmittedReceiptBatchCount(selectedNursingHome?.id);

  const count =
    isLoading && isLoadingReceiptBatchCount
      ? 0
      : (receiptCount ?? 0) + (receiptBatchCount ?? 0);

  return (
    <IncompleteTransactionsCountContext.Provider value={count}>
      {children}
    </IncompleteTransactionsCountContext.Provider>
  );
};
