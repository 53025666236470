import type { FC } from 'react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, Button, Card, Modal, Stack, styled } from '@mui/material';
import { FeatureFlag, FeatureFlagStatus } from '@pflegenavi/shared/api';
import { FeatureFlagRow } from './FeatureFlagRow';
import { FeatureFlagDevContext } from '@pflegenavi/frontend/feature-flags';
import { useFeatureFlagDev } from '@pflegenavi/frontend/feature-flags';
import { CloseModalIcon } from '@pflegenavi/web-components';

export const ModalInner = styled(Box)({
  position: 'absolute',
  width: 'fit-content',
  height: 'fit-content',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

interface FeatureFlagModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const featureFlags = [
  FeatureFlag.CoinList,
  // Don't allow changing this to avoid locking ourselves out
  // FeatureFlag.DevTools,
  FeatureFlag.PaymentStatusBadge,
  FeatureFlag.AddFamilyMember,

  FeatureFlag.AccountingPayments,
  FeatureFlag.AccountingPaymentsNew,
  FeatureFlag.ServiceProviderPayments,
  FeatureFlag.ResidentsPaymentOverview,
  FeatureFlag.dev_ServiceProviderApp,
  FeatureFlag.ImportFamilyMembers,
  FeatureFlag.EmployeeManagement,
  FeatureFlag.dev_NursingHomeReceiptForServiceProvider,
  FeatureFlag.dev_LegalEntities,
];

const CaritasFeatureFlags = [FeatureFlag.CoinList];
const SamaritarbundFeatureFlags = [FeatureFlag.CoinList];
const DrescherFeatureFlags: FeatureFlag[] = [];

const SchoenbrunnFeatureFlags = [...CaritasFeatureFlags];

const presets = {
  All: featureFlags,
  None: [],
  Caritas: CaritasFeatureFlags,
  'Caritas Schoenbrunn': SchoenbrunnFeatureFlags,
  Drescher: DrescherFeatureFlags,
  Samariterbund: SamaritarbundFeatureFlags,
};

const useClearFeatureFlags = () => {
  const context = useContext(FeatureFlagDevContext);

  return useCallback(() => {
    context.set(new Map());
  }, [context]);
};

const usePresets = () => {
  const context = useContext(FeatureFlagDevContext);

  const onClickPreset = useCallback(
    (preset: keyof typeof presets) => {
      const newMap = new Map();
      presets[preset].forEach((featureFlag) => {
        newMap.set(featureFlag, FeatureFlagStatus.Enabled);
      });
      featureFlags.forEach((featureFlag) => {
        if (!newMap.has(featureFlag)) {
          newMap.set(featureFlag, FeatureFlagStatus.Disabled);
        }
      });
      context.set(newMap);
    },
    [context]
  );

  const PresetButtons = useMemo(() => {
    return (
      <Stack gap={1}>
        {Object.keys(presets).map((preset) => (
          <Button
            name={`presetButton${preset}`}
            key={preset}
            variant="outlined"
            onClick={() => onClickPreset(preset as keyof typeof presets)}
          >
            {preset}
          </Button>
        ))}
      </Stack>
    );
  }, [onClickPreset]);

  return PresetButtons;
};

const FeatureFlagModal: FC<FeatureFlagModalProps> = ({ open, setOpen }) => {
  const clear = useClearFeatureFlags();
  const PresetButtons = usePresets();
  const handleCloseButton = () => setOpen(false);
  return (
    <Modal open={open} onClose={handleCloseButton}>
      <ModalInner>
        <Card
          sx={{
            width: 600,
            p: 2,
            maxHeight: '100vh',
            overflowY: 'auto',
          }}
        >
          <Stack
            sx={{
              paddingBottom: 1,
            }}
            direction="column"
            alignItems="flex-end"
            gap={2}
          >
            <CloseModalIcon onClick={handleCloseButton} />
          </Stack>
          <Stack direction="row" justifyContent={'space-between'}>
            <Stack gap={2}>
              <Stack>
                {featureFlags.map((featureFlag) => (
                  <FeatureFlagRow key={featureFlag} featureFlag={featureFlag} />
                ))}
              </Stack>
              <Stack direction="row">
                <Button variant="outlined" onClick={clear}>
                  Clear
                </Button>
              </Stack>
            </Stack>
            {PresetButtons}
          </Stack>
        </Card>
      </ModalInner>
    </Modal>
  );
};

export const useFeatureFlagModal = (): {
  FeatureFlagModalComponent: JSX.Element | null;
} => {
  const [open, setOpen] = useState(false);

  const devToolsEnabled =
    useFeatureFlagDev(FeatureFlag.DevTools) === FeatureFlagStatus.Enabled;

  const FeatureFlagModalComponent = useMemo(() => {
    if (!devToolsEnabled) {
      return null;
    }
    return <FeatureFlagModal open={open} setOpen={setOpen} />;
  }, [open, devToolsEnabled]);

  useEffect(() => {
    const onKeydown = (event: KeyboardEvent) => {
      if (event.shiftKey && event.key === '?') {
        setOpen(true);
      }
    };
    if (devToolsEnabled) {
      document.body.addEventListener('keydown', onKeydown);
    }
    return () => document.body.removeEventListener('keydown', onKeydown);
  }, [devToolsEnabled]);

  return {
    FeatureFlagModalComponent,
  };
};
