import type { FC } from 'react';
import React, { useEffect, useRef } from 'react';
import { useFormatting } from '@pflegenavi/frontend/localization';
import { Grid } from '@mui/material';
import { Typography } from '@pflegenavi/web-components';

interface FactorRowProps {
  factor: number;
  amount: number;
  initialAmount: number;
  showDifference: boolean;
}

// eslint-disable-next-line complexity
export const FactorRow: FC<FactorRowProps> = ({
  factor,
  amount,
  initialAmount,
  showDifference,
}) => {
  const { fCurrency } = useFormatting();
  const sum = (factor * amount) / 100;

  const difference = amount ? amount - initialAmount : initialAmount * -1;

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const div = divRef.current;
    if (div) {
      div.scrollIntoView({ behavior: 'smooth' });
    }
  }, [amount]);

  return (
    <Grid
      container
      spacing={3}
      data-cy={`factor-row`}
      sx={{ pr: 2 }}
      ref={divRef}
    >
      <Grid item xs={5} display="flex" gap={1} alignItems="center">
        <Typography variant="subtitle2" fontWeight={500} noWrap>
          {fCurrency(factor / 100, { minimumFractionDigits: 0 })} x {amount}
        </Typography>
        {difference !== 0 && showDifference && (
          <Typography
            fontSize={13}
            color={difference > 0 ? 'success.main' : 'error'}
            fontWeight={500}
          >
            ({difference > 0 ? '+' + difference : difference})
          </Typography>
        )}
      </Grid>

      <Grid item container xs={7} spacing={1} alignItems="center">
        <Grid item xs={showDifference ? 6 : 12} justifyContent="flex-end">
          <Typography variant="subtitle2" fontWeight={500} textAlign="right">
            {sum === 0 ? '--' : fCurrency(sum)}
          </Typography>
        </Grid>
        {showDifference && (
          <Grid item xs={6} justifyContent="flex-end">
            <Typography
              fontSize={13}
              color={
                difference !== 0
                  ? difference > 0
                    ? 'success.main'
                    : 'error'
                  : 'primary'
              }
              fontWeight={500}
              textAlign="right"
            >
              {difference !== 0
                ? difference > 0
                  ? '+' +
                    fCurrency((factor * difference) / 100, {
                      minimumFractionDigits: 0,
                    })
                  : fCurrency((factor * difference) / 100, {
                      minimumFractionDigits: 0,
                    })
                : '--'}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
