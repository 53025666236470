import type { StackProps } from '@mui/material';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import type { FC, SVGProps } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormatting } from '@pflegenavi/frontend/localization';

// ----------------------------------------------------------------------

interface Props extends StackProps {
  icon: string;
  title: string;
  total: number;
  percent: number;
  price: number;
  color?: string;
  Icon: (props: SVGProps<SVGSVGElement>) => React.ReactElement;
}

export const ReceiptAnalytic: FC<Props> = ({
  title,
  total,
  icon,
  color,
  percent,
  price,
  Icon,
  ...props
}) => {
  const { t } = useTranslation();
  const { fCurrency } = useFormatting();
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{
        width: 1,
        minWidth: 200,
        padding: {
          xs: 2,
          lg: 0,
        },
      }}
      {...props}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ position: 'relative' }}
      >
        <Stack sx={{ fontSize: '24px', position: 'absolute' }}>
          <Icon />
        </Stack>

        <CircularProgress
          variant="determinate"
          value={percent}
          size={56}
          thickness={4}
          sx={{ color, opacity: 0.48 }}
        />

        <CircularProgress
          variant="determinate"
          value={100}
          size={56}
          thickness={4}
          sx={{
            color: 'grey.50016',
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0.48,
          }}
        />
      </Stack>

      <Stack spacing={0.5} sx={{ ml: 2 }}>
        <Typography variant="h6">{title}</Typography>

        <Typography variant="subtitle2">
          {total}{' '}
          <Box
            component="span"
            sx={{ color: 'text.secondary', typography: 'body2' }}
          >
            {t('common.receipts', { count: total })}
          </Box>
        </Typography>

        <Typography variant="subtitle2" sx={{ color }}>
          {fCurrency(price)}
        </Typography>
      </Stack>
    </Stack>
  );
};
