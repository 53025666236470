import { Route } from 'react-router-dom';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import { lazy } from 'react';
import { DefaultSuspense } from '@pflegenavi/web-components';

const RecurringItemsPageV2 = lazy(() => import('./RecurringItemsPageV2'));
const RecurringItemDetailPageV2 = lazy(
  () => import('./RecurringItemDetailPageV2')
);
const RecurringItemDetailResidentsPage = lazy(
  () => import('./RecurringItemDetailResidentsPage')
);
const RecurringItemDetailReceiptBatchesPage = lazy(
  () => import('./RecurringItemDetailReceiptBatchesPage')
);

export const recurringItemRoutes = (): JSX.Element => {
  return (
    <>
      <Route
        path={nhAppMainPages.RECURRING_ITEMS_V2}
        element={
          <DefaultSuspense>
            <RecurringItemsPageV2 />
          </DefaultSuspense>
        }
      />
      <Route
        path={nhAppMainPages.RECURRING_ITEMS_V2_DETAIL}
        element={
          <DefaultSuspense>
            <RecurringItemDetailPageV2 />
          </DefaultSuspense>
        }
      >
        <Route
          index={true}
          element={
            <DefaultSuspense>
              <RecurringItemDetailResidentsPage />
            </DefaultSuspense>
          }
        />
        <Route
          path={nhAppMainPages.RECURRING_ITEMS_V2_DETAIL_RESIDENTS}
          element={
            <DefaultSuspense>
              <RecurringItemDetailResidentsPage />
            </DefaultSuspense>
          }
        />
        <Route
          path={nhAppMainPages.RECURRING_ITEMS_V2_DETAIL_RECEIPT_BATCHES}
          element={
            <DefaultSuspense>
              <RecurringItemDetailReceiptBatchesPage />
            </DefaultSuspense>
          }
        />
      </Route>
    </>
  );
};
