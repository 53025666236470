import JsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {
  useFormatDate,
  useFormatting,
} from '@pflegenavi/frontend/localization';
import { YEAR_MONTH_DAY_SHORT_FORMAT } from '@pflegenavi/shared/constants';
import type { TFunction } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { calculateImagePlacement } from '@pflegenavi/shared/pdf-generation';
import { drawMultilineText } from '@pflegenavi/shared/pdf-generation';

// colors used
const DIVIDER_GREY = '#F2F2F2';
const PINK = '#FBE3DE';
const TITLE_GREY = '#919EAB';

// baseline margins applied to all elements rendered to be properly aligned and do not overlap with the header/footer, except the header and footer which only have the left and right baseline margins
const BASELINE_TOP_MARGIN = 40;
const BASELINE_BOTTOM_MARGIN = 30;
const BASELINE_LEFT_MARGIN = 10;
const BASELINE_RIGHT_MARGIN = 10;

interface HandlePrintProps {
  withdrawalAmount: number;
  nursingHome: string;
  employee: string;
  date: Date;
  logo?: string;
  notes?: string;
}

export interface HandlePrintWithdrawalWithoutResidentResult {
  blob: Blob;
  url: string;
  save: (filename: string) => void;
  date: Date;
}

export interface Context {
  fCurrency: ReturnType<typeof useFormatting>['fCurrency'];
  fDate: ReturnType<typeof useFormatDate>;
  t: TFunction<'translation', undefined>;
}

interface DrawHeaderOpts {
  logo?: string;
}

export const drawHeader = async (
  context: Context,
  doc: JsPDF,
  opts: DrawHeaderOpts
): Promise<void> => {
  const { t } = context;

  const HEADER_HEIGHT = 35;
  const logo = opts.logo;

  doc.setFillColor(PINK);
  const fontSize = 14;
  const lineHeight = 1.2;

  // create a rectangle at the top of the page with a height of 10% of the page
  doc.rect(0, 0, doc.internal.pageSize.width, HEADER_HEIGHT, 'F');

  const textHeight = fontSize * lineHeight;
  const titleY = (HEADER_HEIGHT - textHeight) / 2 + textHeight / 2 + 2;

  doc.setFontSize(fontSize);
  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, 'bold');
  doc.setTextColor(0, 0, 0); // RGB for black
  doc.text(
    t('resident-pdf.withdrawal-confirmation.withdrawal-receipt'),
    BASELINE_LEFT_MARGIN,
    titleY
  );

  if (!logo) {
    return;
  }

  const img: HTMLImageElement = await new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.onerror = (err) => {
      reject(err);
    };
    img.crossOrigin = 'Anonymous';
    img.src = logo;
  });

  const { x, y, width, height } = calculateImagePlacement(
    img,
    60,
    35,
    5,
    5,
    10
  );

  doc.addImage(
    img,
    'PNG',
    doc.internal.pageSize.width - 60 + x,
    y,
    width,
    height
  );
};

export const drawFooter = (
  context: Context,
  doc: JsPDF,
  pageNumber: number,
  pageCount: number
): void => {
  const { t } = context;
  const pageHeight = doc.internal.pageSize.height;

  autoTable(doc, {
    body: [
      [
        {
          content: 'help@pflegenavi.at',
          styles: {
            halign: 'left',
          },
        },
        {
          content: `${t('resident-pdf.document.page')}: ${String(
            pageNumber
          )} ${t('resident-pdf.document.of')} ${String(pageCount)}`,
          styles: {
            halign: 'right',
          },
        },
      ],
    ],
    theme: 'plain',
    startY: pageHeight - 15,
    margin: {
      bottom: 0,
      top: 0,
      left: BASELINE_LEFT_MARGIN,
      right: BASELINE_RIGHT_MARGIN,
    },
  });
};

export const drawFooters = (context: Context, doc: JsPDF): void => {
  const pageCount = doc.internal.pages.length - 1;

  for (let i = 1; i <= pageCount; i++) {
    // Go to page i
    doc.setPage(i);
    //Print Page 1 of 4 for example
    drawFooter(context, doc, i, pageCount);
  }
};

export const drawDate = (
  context: Context,
  doc: JsPDF,
  text: string,
  date: Date,
  options?: {
    x?: number;
    y?: number;
  }
): void => {
  const { fDate } = context;

  autoTable(doc, {
    body: [[{ content: fDate(new Date(date), YEAR_MONTH_DAY_SHORT_FORMAT) }]],
    theme: 'plain',
    didDrawPage: function (data) {
      const fontSize = 12;
      // Set Title Style
      doc.setFontSize(fontSize);
      doc.setTextColor(TITLE_GREY);

      // @ts-expect-error it doesn't let me not specify the font family
      doc.setFont(undefined, 'bold');

      // Get Page Height
      const titlePosY = data.settings.startY - 1;

      // Calculate the page width
      const pageWidth = doc.internal.pageSize.getWidth();

      // Calculate the x-coordinate for the text to be right-aligned
      const titlePosX = pageWidth - BASELINE_RIGHT_MARGIN - 1;

      // Add Title
      doc.text(text.toUpperCase(), options?.x ?? titlePosX, titlePosY, {
        align: 'right',
      });
    },
    styles: {
      fontSize: 11,
    },
    columnStyles: {
      0: {
        halign: 'right',
      },
    },
    startY: options?.y ?? BASELINE_TOP_MARGIN + 9,
    margin: {
      bottom: BASELINE_BOTTOM_MARGIN + 13,
      left: doc.internal.pageSize.getWidth() - BASELINE_RIGHT_MARGIN - 30,
      right: BASELINE_RIGHT_MARGIN,
    },
  });
};

export const drawNursingHome = (
  context: Context,
  doc: JsPDF,
  nursingHome: string,
  options: {
    y: number;
  }
): void => {
  const { t } = context;
  autoTable(doc, {
    body: [[{ content: nursingHome }]],
    theme: 'plain',
    didDrawPage: function (data) {
      // Set Title Style
      doc.setFontSize(12);
      doc.setTextColor(TITLE_GREY);
      // @ts-expect-error it doesn't let me not specify the font family
      doc.setFont(undefined, 'bold');

      // Get Page Height
      const titlePos = data.settings.startY - 1;

      // Add Title
      doc.text(
        t('resident-pdf.withdrawal-confirmation.nursing-home').toUpperCase(),
        BASELINE_LEFT_MARGIN + 1.5,
        titlePos
      );
    },
    styles: {
      fontSize: 11,
    },
    startY: options.y,
    margin: {
      bottom: BASELINE_BOTTOM_MARGIN + 13,
      left: BASELINE_LEFT_MARGIN,
      right: BASELINE_RIGHT_MARGIN,
    },
  });
};

export const drawEmployee = (
  context: Context,
  doc: JsPDF,
  employee: string,
  options: {
    y: number;
  }
): void => {
  const { t } = context;
  autoTable(doc, {
    body: [[{ content: employee }]],
    theme: 'plain',
    didDrawPage: function (data) {
      // Set Title Style
      doc.setFontSize(12);
      doc.setTextColor(TITLE_GREY);
      // @ts-expect-error it doesn't let me not specify the font family
      doc.setFont(undefined, 'bold');

      // Get Page Height
      const titlePos = data.settings.startY - 1;

      // Add Title
      doc.text(
        t('resident-pdf.withdrawal-confirmation.employee').toUpperCase(),
        BASELINE_LEFT_MARGIN + 1.5,
        titlePos
      );
    },
    styles: {
      fontSize: 11,
    },
    startY: options.y,
    margin: {
      bottom: BASELINE_BOTTOM_MARGIN + 13,
      left: BASELINE_LEFT_MARGIN,
      right: BASELINE_RIGHT_MARGIN,
    },
  });
};

export const drawBottomText = (
  context: Context,
  doc: JsPDF,
  text: string,
  options: { y: number }
): void => {
  // Set Title Style
  doc.setFontSize(11);
  doc.setTextColor('black');
  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, 'normal');

  // Add Title
  doc.text(text, BASELINE_LEFT_MARGIN, options.y);
};

export const drawWithdrawalAmount = (
  context: Context,
  doc: JsPDF,
  amount: number
): void => {
  const { t, fCurrency } = context;
  const titleFontSize = 12;
  // Set Title Style
  doc.setFontSize(titleFontSize);
  doc.setTextColor(TITLE_GREY);
  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, 'bold');
  const pageSize = doc.internal.pageSize;
  const titleWidth =
    (doc.getStringUnitWidth(
      t('resident-pdf.withdrawal-confirmation.withdrawal-amount').toUpperCase()
    ) *
      titleFontSize) /
    doc.internal.scaleFactor;
  const titleOffset = (pageSize.width - titleWidth) / 2;
  // Add Title
  doc.text(
    t('resident-pdf.withdrawal-confirmation.withdrawal-amount').toUpperCase(),
    titleOffset,
    BASELINE_TOP_MARGIN + 32.5
  );

  const amountFontSize = 17;
  // Set Title Style
  doc.setFontSize(amountFontSize);
  doc.setTextColor('black');
  const amountWidth =
    (doc.getStringUnitWidth(fCurrency(amount)) * amountFontSize) /
    doc.internal.scaleFactor;
  const amountOffset = (pageSize.width - amountWidth) / 2;
  // add amount
  doc.text(
    fCurrency(Math.abs(amount)),
    amountOffset,
    BASELINE_TOP_MARGIN + 40.5
  );
};

export const drawDivider = (doc: JsPDF, y: number): void => {
  doc.setLineWidth(0.5);
  doc.setDrawColor(DIVIDER_GREY);
  doc.line(
    BASELINE_LEFT_MARGIN,
    y,
    doc.internal.pageSize.width - BASELINE_RIGHT_MARGIN,
    y
  );
};

export const drawSignatureField = (
  context: Context,
  doc: JsPDF,
  y: number
): void => {
  const { t } = context;

  doc.setLineWidth(0.3);
  doc.setDrawColor(0, 0, 0);
  doc.setLineDashPattern([0.5, 0.5], 0);
  doc.line(
    doc.internal.pageSize.width - BASELINE_LEFT_MARGIN - 60,
    y,
    doc.internal.pageSize.width - BASELINE_LEFT_MARGIN,
    y
  );

  doc.setFontSize(10);

  const titleWidth =
    (doc.getStringUnitWidth(
      t('resident-pdf.withdrawal-confirmation.signature')
    ) *
      10) /
    doc.internal.scaleFactor;
  const titleOffset =
    doc.internal.pageSize.width - BASELINE_LEFT_MARGIN - 30 - titleWidth / 2;

  doc.setTextColor(0, 0, 0);
  doc.setFontSize(10);
  doc.text(
    t('resident-pdf.withdrawal-confirmation.signature'),
    titleOffset,
    y + 5
  );
};

export const drawNameField = (
  context: Context,
  doc: JsPDF,
  y: number
): void => {
  const { t } = context;

  doc.setLineWidth(0.3);
  doc.setDrawColor(0, 0, 0);
  doc.setLineDashPattern([0.5, 0.5], 0);
  doc.line(BASELINE_LEFT_MARGIN, y, BASELINE_LEFT_MARGIN + 60, y);

  doc.setFontSize(10);

  const titleWidth =
    (doc.getStringUnitWidth(
      t('resident-pdf.withdrawal-confirmation.signature')
    ) *
      10) /
    doc.internal.scaleFactor;

  const titleOffset = BASELINE_LEFT_MARGIN + 30 - titleWidth / 2;

  doc.setTextColor(0, 0, 0);
  doc.setFontSize(10);
  doc.text(t('resident-pdf.withdrawal-confirmation.name'), titleOffset, y + 5);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePrintPdfWithdrawalWithoutResidentConfirmation = () => {
  const { t } = useTranslation();
  const { fCurrency } = useFormatting();
  const fDate = useFormatDate();

  const handlePrintPdfWithdrawalWithoutResidentConfirmation = async ({
    withdrawalAmount,
    employee,
    nursingHome,
    date,
    logo,
    notes,
  }: HandlePrintProps): Promise<HandlePrintWithdrawalWithoutResidentResult> => {
    const doc = new JsPDF();

    const context: Context = {
      t,
      fCurrency: (amount, opts) =>
        fCurrency(amount, { ...opts, useNonBreakingCharacters: false }),
      fDate,
    };
    drawNursingHome(context, doc, nursingHome, {
      y: BASELINE_TOP_MARGIN + 9,
    });

    drawDate(context, doc, t('common.date'), date);
    drawEmployee(context, doc, employee, {
      y: BASELINE_TOP_MARGIN + 33,
    });
    drawWithdrawalAmount(context, doc, withdrawalAmount);

    drawDivider(doc, BASELINE_TOP_MARGIN + 48);

    const noteOffset = 59;
    const label = t('resident-pdf.withdrawal-confirmation.note');
    const trimmedNotes = notes ? notes.trim() : notes;
    const notesTextHeight = trimmedNotes
      ? drawMultilineText(doc, `${label}: `, trimmedNotes, {
          y: BASELINE_TOP_MARGIN + noteOffset,
          allLinesIndented: false,
          boldLabel: true,
          baseMarginLeft: BASELINE_LEFT_MARGIN,
          baseMarginRight: BASELINE_RIGHT_MARGIN,
          lineHeight: 7,
          fontSize: 11,
        })
      : 0;

    const arbitraryAdditionalOffset = trimmedNotes ? 7 : 0;
    const bottomTextOffset = 59 + notesTextHeight + arbitraryAdditionalOffset;
    drawBottomText(
      context,
      doc,
      t(
        'resident-pdf.withdrawal-confirmation.amount-withdrawn-from-cash-register'
      ),
      {
        y: BASELINE_TOP_MARGIN + bottomTextOffset,
      }
    );

    drawNameField(context, doc, BASELINE_TOP_MARGIN + bottomTextOffset + 40);

    drawSignatureField(
      context,
      doc,
      BASELINE_TOP_MARGIN + bottomTextOffset + 40
    );

    await drawHeader(context, doc, {
      logo,
    });

    drawFooters(context, doc);

    // Get the PDF data as a Blob
    const pdfBlob = doc.output('blob');

    // Create a URL from the Blob
    const url = URL.createObjectURL(pdfBlob);

    return {
      blob: pdfBlob,
      save: doc.save,
      url,
      date,
    };
  };

  return {
    handlePrintPdfWithdrawalWithoutResidentConfirmation,
  };
};
