import type { Coin } from '@pflegenavi/shared/api';
import { FeatureFlag } from '@pflegenavi/shared/api';
import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Iconify,
  Modal,
  ModalInner,
  Stack,
} from '@pflegenavi/web-components';
import { Divider, Grid, IconButton, Typography } from '@mui/material';
import { useFormatting } from '@pflegenavi/frontend/localization';
import { useTheme } from '@mui/material/styles';
import { useFeatureFlagEnabled } from '@pflegenavi/frontend/feature-flags';

interface ModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  coins?: Coin[];
  bankAccountAmount: number;
  amountColor: string;
  amountString: string;
  balanceAfterString?: string;
  balanceBeforeString?: string;
  selectedCashListName: string | undefined;
}

export const CoinsModal: FC<ModalProps> = ({
  open,
  coins,
  bankAccountAmount,
  setOpen,
  amountString,
  amountColor,
  balanceAfterString,
  balanceBeforeString,
  selectedCashListName,
}) => {
  const { t } = useTranslation();
  const factors = coins?.filter((coin) => coin.amount !== 0);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal open={open} handleClose={handleClose}>
      <ModalInner>
        <Card
          sx={{
            width: 400,
            p: 2,
            maxHeight: '100vh',
            overflowY: 'auto',
          }}
        >
          <Stack direction="column" gap={1}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              justifyContent={'space-between'}
              gap={1}
            >
              <Typography variant="h6" fontWeight={700}>
                {t('cashManagement.table-modal.title')}
              </Typography>

              <IconButton
                onClick={handleClose}
                sx={{
                  padding: '16px',
                }}
              >
                <Iconify
                  icon={'eva:close-fill'}
                  sx={{
                    position: 'absolute',
                  }}
                  width={20}
                />
              </IconButton>
            </Stack>

            <Stack direction="column" gap={1}>
              {factors &&
                factors
                  .sort(({ factor: a }, { factor: b }) => b - a)
                  .map(({ factor, amount }) => (
                    <FactorRow key={factor} factor={factor} amount={amount} />
                  ))}
              <BankAmountRow amount={bankAccountAmount} />
              <Divider />
              <TotalsRow
                amountColor={amountColor}
                amountString={amountString}
                balanceAfterString={balanceAfterString}
                balanceBeforeString={balanceBeforeString}
                selectedCashListName={selectedCashListName ?? ''}
              />
            </Stack>
          </Stack>
        </Card>
      </ModalInner>
    </Modal>
  );
};

interface FactorRowProps {
  factor: number;
  amount: number;
  color?: string;
}

export const FactorRow: FC<FactorRowProps> = ({ factor, amount, color }) => {
  const { fCurrency } = useFormatting();
  const sum = (factor * amount) / 100;

  return (
    <Grid container spacing={3}>
      <Grid
        item
        xs={6}
        display="flex"
        direction="row"
        gap={1}
        alignItems="center"
      >
        <Typography
          component="span"
          variant="subtitle2"
          fontWeight={500}
          noWrap
          sx={{ width: 50 }}
          color={color}
        >
          {fCurrency(factor / 100, { minimumFractionDigits: 0 })}&nbsp;
        </Typography>
        <Typography
          component="span"
          variant="subtitle2"
          fontWeight={500}
          noWrap
          sx={{ width: 10 }}
          color={color}
        >
          x
        </Typography>
        <Typography
          component="span"
          variant="subtitle2"
          fontWeight={500}
          noWrap
          color={color}
        >
          {amount < 0 ? `(${amount})` : amount}
        </Typography>
      </Grid>

      <Grid item container xs={6} spacing={1} alignItems="center">
        <Grid item xs={12} justifyContent="flex-end">
          <Typography
            variant="subtitle2"
            fontWeight={500}
            textAlign="right"
            color={color}
          >
            {sum === 0 ? '--' : fCurrency(sum)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

interface BankAmountRowProps {
  amount: number;
}

const BankAmountRow: FC<BankAmountRowProps> = ({ amount }) => {
  const { fCurrency } = useFormatting();
  const { t } = useTranslation();

  if (amount === 0) {
    return null;
  }
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="subtitle2" fontWeight={600}>
        {t('cashManagement.table-modal.bank-account-amount')}
      </Typography>
      <Typography variant="subtitle2" fontWeight={500} textAlign="right">
        {fCurrency(amount / 100, { minimumFractionDigits: 2 })}
      </Typography>
    </Stack>
  );
};

interface TotalsRowProps {
  amountColor: string;
  amountString: string;
  balanceAfterString?: string;
  balanceBeforeString?: string;
  selectedCashListName: string;
}

const TotalsRow: FC<TotalsRowProps> = ({
  amountColor,
  amountString,
  balanceAfterString,
  balanceBeforeString,
  selectedCashListName,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const newAccountingPaymentsFeatureFlagEnabled = useFeatureFlagEnabled(
    FeatureFlag.AccountingPaymentsNew
  );

  return (
    <Stack direction="column" gap={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle2" fontWeight={700}>
          {t('cashManagement.table-modal.total-change')}
        </Typography>

        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Typography variant="subtitle2" color={amountColor} fontWeight={700}>
            {amountString}
          </Typography>
        </Stack>
      </Stack>

      {balanceBeforeString && balanceAfterString && (
        <Stack direction="column" gap={0.5}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle2" color={theme.palette.grey[600]}>
              {newAccountingPaymentsFeatureFlagEnabled
                ? t('cashManagement.table-modal.total-cash-list-old', {
                    cashListName: selectedCashListName,
                  })
                : t('cashManagement.table-modal.total-cash-old')}
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Typography variant="subtitle2" color={theme.palette.grey[600]}>
                {balanceBeforeString}
              </Typography>
            </Stack>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle2">
              {newAccountingPaymentsFeatureFlagEnabled
                ? t('cashManagement.table-modal.total-cash-list-new', {
                    cashListName: selectedCashListName,
                  })
                : t('cashManagement.table-modal.total-cash-new')}
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Typography variant="subtitle2">{balanceAfterString}</Typography>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
