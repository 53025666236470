import { Route } from 'react-router-dom';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import { lazy, useMemo } from 'react';
import { DefaultSuspense } from '@pflegenavi/web-components';
import { ReceiptBatchOverviewPage } from './ReceiptBatchOverviewPage';

const AddReceiptBatchPage = lazy(
  () => import('./receiptBatch/AddReceiptBatchPage')
);
const EditReceiptBatchPage = lazy(
  () => import('./receiptBatch/EditReceiptBatchPage')
);

export const useReceiptBatchRoutes = (): JSX.Element | null => {
  return useMemo(() => {
    return (
      <Route path={nhAppMainPages.RECEIPTS_BATCH}>
        <Route
          index
          element={
            <DefaultSuspense>
              <ReceiptBatchOverviewPage />
            </DefaultSuspense>
          }
        />
        <Route
          path={nhAppMainPages.RECEIPTS_BATCH_ADD}
          element={
            <DefaultSuspense>
              <AddReceiptBatchPage />
            </DefaultSuspense>
          }
        />
        <Route
          path={nhAppMainPages.RECEIPTS_BATCH_EDIT}
          element={
            <DefaultSuspense>
              <EditReceiptBatchPage />
            </DefaultSuspense>
          }
        />
      </Route>
    );
  }, []);
};
