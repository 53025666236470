import type { NavBarExtension } from '@pflegenavi/frontend/layout';
import { useFeatureFlag } from '@pflegenavi/frontend/feature-flags';
import { FeatureFlag, FeatureFlagStatus } from '@pflegenavi/shared/api';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';

import cashList from './assets/cash_list.svg';
import { SvgIconStyle } from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';

export const useConfig: () => NavBarExtension = () => {
  const enabled = useFeatureFlag(FeatureFlag.CoinList);

  const { t } = useTranslation();

  if (enabled === FeatureFlagStatus.Disabled) {
    return undefined;
  }
  return {
    item: {
      icon: <SvgIconStyle src={cashList} sx={{ width: 1, height: 1 }} />,
      title: t('cashManagement.title'),
      path: nhAppMainPages.CASH_MANAGEMENT,
      children: [
        {
          title: t('common.overview'),
          path: nhAppMainPages.CASH_MANAGEMENT,
        },
        {
          title: t('cashManagement.nav.cash-register'),
          path: nhAppMainPages.CASH_MANAGEMENT_CASH,
        },
        {
          title: t('nav.cash-management.cash-transactions'),
          path: nhAppMainPages.CASH_MANAGEMENT_TRANSACTIONS,
        },
      ],
    },
  };
};
