import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import { DefaultSuspense } from '@pflegenavi/web-components';

const ReportingPage = lazy(() => import('./ReportingPage'));
const ReportingTransactionPage = lazy(
  () => import('./ReportingTransactionPage')
);
const ReportingPrintPage = lazy(() => import('./ReportingPrintPage'));

export const reportingRoutes = (): JSX.Element => {
  return (
    <Route path={nhAppMainPages.REPORTING}>
      <Route
        index={true}
        element={
          <DefaultSuspense>
            <ReportingPage />
          </DefaultSuspense>
        }
      />
      <Route
        path={nhAppMainPages.REPORTING_TRANSACTIONS}
        element={
          <DefaultSuspense>
            <ReportingTransactionPage />
          </DefaultSuspense>
        }
      />
      <Route
        path={nhAppMainPages.REPORTING_PRINT}
        element={
          <DefaultSuspense>
            <ReportingPrintPage />
          </DefaultSuspense>
        }
      />
    </Route>
  );
};
