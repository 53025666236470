import type { FC } from 'react';
import * as React from 'react';
import { useState } from 'react';
import { Box, Container } from '@mui/material';
import { Button, HeaderBreadcrumbs, Iconify } from '@pflegenavi/web-components';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import { useTranslation } from 'react-i18next';
import { useGetLegalEntities } from '@pflegenavi/frontend/api-nursing-home';
import { LegalEntitiesTable } from './components/legal-entities/LegalEntitiesTable';
import { AddLegalEntityModal } from './components/legal-entities/AddLegalEntityModal';

const LegalEntitiesPage: FC = () => {
  const { t } = useTranslation();

  const [addModalOpen, setAddModalOpen] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { data, isLoading } = useGetLegalEntities();

  return (
    <Box>
      <Container maxWidth={'lg'}>
        <HeaderBreadcrumbs
          heading={t('nursingHome.main')}
          links={[
            {
              name: t('nursingHome.main'),
              href: nhAppMainPages.NURSING_HOME,
            },
            { name: t('legal-entities.title') },
          ]}
          action={
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => setAddModalOpen(true)}
            >
              {t('legal-entities.add-legal-entity')}
            </Button>
          }
        />
        <AddLegalEntityModal
          key={addModalOpen ? 'open' : 'closed'}
          open={addModalOpen}
          setOpen={setAddModalOpen}
        />
        <LegalEntitiesTable
          isLoading={isLoading}
          legalEntities={data?.data}
          page={page}
          pageSize={rowsPerPage}
          onPageChange={setPage}
          onPageSizeChange={setRowsPerPage}
          totalRowCount={data?.meta.total_count ?? 0}
        />
      </Container>
    </Box>
  );
};

export default LegalEntitiesPage;
