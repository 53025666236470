import type { FC, ReactNode } from 'react';
import { createContext, useContext } from 'react';
import type { NavListProps } from '@pflegenavi/web-components';

export type NavBarExtension =
  | {
      item: NavListProps;
    }
  | undefined;

const ExtensionContext = createContext<NavBarExtension[]>([]);

export const ExtensionProvider: FC<{
  value: NavBarExtension[];
  children: ReactNode;
}> = (props) => {
  return (
    <ExtensionContext.Provider value={props.value}>
      {props.children}
    </ExtensionContext.Provider>
  );
};

export const useExtensions = (): NavBarExtension[] => {
  return useContext(ExtensionContext);
};
