import type { FC, ReactNode } from 'react';
import React, { memo, useCallback } from 'react';
import { IconButton, TextField } from '@mui/material';
import { Iconify } from '@pflegenavi/web-components';
import type {
  FormikErrors,
  FormikHelpers,
  FormikProps,
  FormikTouched,
} from 'formik';
import { getIn } from 'formik';
import { useTheme } from '@mui/material/styles';
import type {
  CreateNewFamilyMemberType,
  ImportFamilyMemberFormValues,
} from './ImportFamilyMembersTable';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { FMImportErrorHelperText } from './FMImportErrorHelperText';

interface ImportFamilyMembersTableRowViewProps {
  index: number;
  firstName: string;
  lastName: string;
  email: string;
  setFieldValue: FormikHelpers<CreateNewFamilyMemberType>['setFieldValue'];
  setFieldTouched: FormikHelpers<CreateNewFamilyMemberType>['setFieldTouched'];
  removeRow: (index: number) => void;
  SelectResidents: ReactNode;
  errors: FormikErrors<CreateNewFamilyMemberType> | undefined;
  touched: FormikTouched<CreateNewFamilyMemberType> | undefined;
  lastInputRef?: React.RefObject<HTMLInputElement>;
  formik: FormikProps<ImportFamilyMemberFormValues>;
}

export const ImportFamilyMembersTableRowView: FC<ImportFamilyMembersTableRowViewProps> =
  memo(
    // eslint-disable-next-line complexity
    ({
      index,
      firstName,
      lastName,
      email,
      setFieldValue,
      setFieldTouched,
      removeRow,
      SelectResidents,
      errors,
      touched,
      lastInputRef,
      formik,
    }) => {
      const theme = useTheme();

      const handleDeleteRow = useCallback(() => {
        removeRow(index);
      }, [index, removeRow]);

      const setFirstName = useCallback(
        ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue(`familyMembers.${index}.firstName`, value);
        },
        [index, setFieldValue]
      );

      const setLastName = useCallback(
        ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue(`familyMembers.${index}.lastName`, value);
        },
        [index, setFieldValue]
      );

      const setEmail = useCallback(
        ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue(`familyMembers.${index}.email`, value);
        },
        [index, setFieldValue]
      );

      return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell
            component="th"
            scope="row"
            sx={{
              maxWidth: '190px',
            }}
          >
            {SelectResidents}
          </TableCell>
          <TableCell
            align="left"
            sx={{
              maxWidth: '170px',
              pl: '16px',
            }}
          >
            <MemoizedInput
              name={`familyMembers.${index}.firstName`}
              value={firstName}
              onChange={setFirstName}
              error={
                touched?.firstName ? Boolean(errors?.firstName) : undefined
              }
              helperText={touched?.firstName ? errors?.firstName : undefined}
              setFieldTouched={setFieldTouched}
              formik={formik}
              trim={true}
              capitalize={true}
            />
          </TableCell>
          <TableCell
            align="left"
            sx={{
              maxWidth: '170px',
            }}
          >
            <MemoizedInput
              name={`familyMembers.${index}.lastName`}
              value={lastName}
              onChange={setLastName}
              error={touched?.lastName ? Boolean(errors?.lastName) : undefined}
              helperText={touched?.lastName ? errors?.lastName : undefined}
              setFieldTouched={setFieldTouched}
              formik={formik}
              trim={true}
              capitalize={true}
            />
          </TableCell>
          <TableCell
            align="left"
            sx={{
              maxWidth: '170px',
            }}
          >
            <MemoizedInput
              type="email"
              name={`familyMembers.${index}.email`}
              value={email}
              onChange={setEmail}
              error={touched?.email ? Boolean(errors?.email) : undefined}
              helperText={touched?.email ? errors?.email : undefined}
              inputRef={lastInputRef}
              setFieldTouched={setFieldTouched}
              formik={formik}
              trim={true}
            />
          </TableCell>

          <TableCell
            align="right"
            sx={{
              maxWidth: 20,
            }}
          >
            <IconButton onClick={handleDeleteRow} tabIndex={-1}>
              <Iconify
                icon="eva:trash-2-outline"
                color={theme.palette.error.main}
                fontSize={20}
              />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    }
  );

interface MemoizedInputProps {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  helperText?: string;
  type?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  setFieldTouched: FormikHelpers<CreateNewFamilyMemberType>['setFieldTouched'];
  formik: FormikProps<ImportFamilyMemberFormValues>;
  trim?: boolean;
  capitalize?: boolean;
}

const MemoizedInput: FC<MemoizedInputProps> = memo(
  ({
    name,
    value,
    onChange,
    error,
    helperText,
    type = 'text',
    inputRef,
    setFieldTouched,
    formik,
    trim,
    capitalize,
  }) => {
    const normalizeOnBlur = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        const originalValue = getIn(formik.values, e.target.name);
        let value = originalValue;
        if (value === undefined) {
          return;
        }

        if (trim) {
          value = value?.trim();
        }

        if (capitalize) {
          value = value?.charAt(0).toUpperCase() + value?.slice(1);
        }

        if (value !== originalValue) {
          formik.setFieldValue(e.target.name, value);
        }

        setFieldTouched(e.target.name);
      },
      [formik, trim, capitalize, setFieldTouched]
    );
    return (
      <>
        <TextField
          variant="standard"
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          inputRef={inputRef}
          onBlur={normalizeOnBlur}
          inputProps={{
            style: {
              marginTop: 15,
            },
          }}
        />
        <FMImportErrorHelperText helperText={helperText} />
      </>
    );
  }
);
