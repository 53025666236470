import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import {
  type ConfigType,
  DeleteDialog,
  Iconify,
  makeConfig,
  MenuItem,
  PaginatedTable2,
  TableMoreMenu,
  Typography,
  useErrorSnackbar,
} from '@pflegenavi/web-components';
import type { LegalEntity } from '@pflegenavi/shared/api';
import { useTranslation } from 'react-i18next';
import {
  isDeleteLegalEntityConflictException,
  useDeleteLegalEntityPhoenix,
} from '@pflegenavi/frontend/api-nursing-home';
import { useSnackbar } from 'notistack';
import { EditLegalEntityModal } from './EditLegalEntityModal';

interface LegalEntitiesTableProps {
  isLoading: boolean;
  legalEntities: LegalEntity[] | undefined;

  totalRowCount: number;
  page: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
}

type ColumnNames =
  | 'company'
  | 'address'
  | 'zip'
  | 'city'
  | 'phone'
  | 'optionsMenu';
type Filters = never;

export const LegalEntitiesTable: FC<LegalEntitiesTableProps> = ({
  isLoading,
  legalEntities,
  page,
  pageSize,
  totalRowCount,
  onPageSizeChange,
  onPageChange,
}) => {
  const { t } = useTranslation();

  const [idToBeDeleted, setIdToBeDeleted] = useState<string | undefined>(
    undefined
  );

  const setDeleteOpen = useCallback((open: boolean) => {
    if (!open) {
      setIdToBeDeleted(undefined);
    }
  }, []);

  const handleClickDelete = useCallback((id: string) => {
    setIdToBeDeleted(id);
  }, []);

  const { enqueueSnackbar } = useSnackbar();
  const { enqueueErrorSnackbar } = useErrorSnackbar();

  const { mutateAsync: deleteLegalEntity } = useDeleteLegalEntityPhoenix();

  const handleDelete = useCallback(async () => {
    if (!idToBeDeleted) {
      throw new Error('No Legal entity selected for deletion');
    }
    try {
      await deleteLegalEntity({ legalEntityId: idToBeDeleted });
      enqueueSnackbar(t('legal-entities.form.success.deleted'), {
        variant: 'success',
      });
      setIdToBeDeleted(undefined);
    } catch (e) {
      if (isDeleteLegalEntityConflictException(e)) {
        enqueueErrorSnackbar(
          e,
          t('legal-entities.form.error.failed-to-delete-conflict')
        );
      } else {
        enqueueErrorSnackbar(
          e,
          t('legal-entities.form.error.failed-to-delete')
        );
      }
    }
  }, [
    deleteLegalEntity,
    enqueueErrorSnackbar,
    enqueueSnackbar,
    idToBeDeleted,
    t,
  ]);

  const [selectedLegalEntity, setSelectedLegalEntity] = useState<
    LegalEntity | undefined
  >(undefined);

  const setOpen = useCallback((open: boolean) => {
    if (!open) {
      setSelectedLegalEntity(undefined);
    }
  }, []);

  const config: ConfigType<LegalEntity, ColumnNames, Filters> = useMemo(
    () =>
      makeConfig<LegalEntity, ColumnNames, Filters>({
        showAll: undefined,
        onClickRow: undefined,
        tabs: undefined,
        columns: [
          {
            key: 'company',
            label: t('legal-entities.table.company'),
            align: 'left',
            component: ({ row }) => (
              <Typography variant="subtitle2" noWrap>
                {row.base_data.company}
              </Typography>
            ),
          },
          {
            key: 'address',
            label: t('legal-entities.table.address'),
            align: 'left',
            component: ({ row }) => (
              <Typography variant="subtitle2" noWrap>
                {row.base_data.address}
              </Typography>
            ),
          },
          {
            key: 'zip',
            label: t('legal-entities.table.zip'),
            align: 'left',
            component: ({ row }) => (
              <Typography variant="subtitle2">
                {row.base_data.zip_code}
              </Typography>
            ),
          },
          {
            key: 'city',
            label: t('legal-entities.table.city'),
            align: 'left',
            component: ({ row }) => (
              <Typography variant="subtitle2">{row.base_data.city}</Typography>
            ),
          },
          {
            key: 'phone',
            label: t('legal-entities.table.phone'),
            align: 'left',
            component: ({ row }) => (
              <Typography variant="subtitle2">{row.base_data.phone}</Typography>
            ),
          },
          {
            key: 'optionsMenu',
            label: '',
            align: 'right',
            component: ({ row }) => (
              <OptionsMenu
                row={row}
                handleClickDelete={handleClickDelete}
                handleEdit={() => setSelectedLegalEntity(row)}
              />
            ),
          },
        ],
        filters: undefined,
        emptyRowHeight: 77,
        pagination: {
          page,
          pageSize,
          pageSizeOptions: [5, 10, 25, 50],
          total: totalRowCount,
          onPageChange,
          onPageSizeChange,
        },
      }),
    [
      handleClickDelete,
      onPageChange,
      onPageSizeChange,
      page,
      pageSize,
      t,
      totalRowCount,
    ]
  );

  const rowsOnPage = useMemo(() => {
    const rows = legalEntities ?? [];
    return rows.slice(page * pageSize, page * pageSize + pageSize);
  }, [legalEntities, page, pageSize]);

  return (
    <>
      <PaginatedTable2
        name="legal-entities"
        isLoading={isLoading}
        rows={rowsOnPage}
        config={config}
      />

      {selectedLegalEntity && (
        <EditLegalEntityModal
          key={selectedLegalEntity ? 'open' : 'closed'}
          open={!!selectedLegalEntity}
          setOpen={setOpen}
          legalEntity={selectedLegalEntity}
        />
      )}

      <DeleteDialog
        open={!!idToBeDeleted}
        // @ts-expect-error not based on useState, but need to use DeleteDialog
        setDeleteDialogOpen={setDeleteOpen}
        title={t('legal-entities.delete-modal.title')}
        confirmationMessage={t(
          'legal-entities.delete-modal.confirmation-message'
        )}
        handleDelete={handleDelete}
      />
    </>
  );
};

interface OptionsMenuProps {
  row: LegalEntity;
  handleEdit: () => void;
  handleClickDelete: (id: string) => void;
}

const OptionsMenu: FC<OptionsMenuProps> = ({
  row,
  handleEdit,
  handleClickDelete,
}) => {
  const { t } = useTranslation();

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleCloseMenu = (event?: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(null);
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <TableMoreMenu
      open={openMenu}
      onOpen={handleOpenMenu}
      onClose={handleCloseMenu}
      actions={
        <>
          <MenuItem
            onClick={(e) => {
              handleClickDelete(row.id);
              e.preventDefault();
              e.stopPropagation();
            }}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon={'eva:trash-2-outline'} />
            {t('actions.delete')}
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              handleEdit();
              handleCloseMenu();
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Iconify icon={'eva:edit-fill'} />
            {t('actions.edit')}
          </MenuItem>
        </>
      }
    />
  );
};
