import type { ReactNode, FC, PropsWithChildren } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SomethingWentWrong } from '@pflegenavi/web-components';
import * as Sentry from '@sentry/react';

interface Props extends PropsWithChildren {
  children?: ReactNode;
  home: string;
  supportEmail: string;
}
export const InnerErrorBoundary: FC<Props> = ({
  children,
  home,
  supportEmail,
}) => {
  const navigate = useNavigate();

  return (
    <Sentry.ErrorBoundary
      fallback={({ resetError, error }) => (
        <SomethingWentWrong
          handleGoHomeClick={() => {
            resetError();
            navigate(home);
          }}
          supportEmail={supportEmail}
          errorMessage={
            error instanceof Error ? error?.message : JSON.stringify(error)
          }
        />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
