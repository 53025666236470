import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import {
  getTransactionInfo,
  getTypeString,
  TypeLabel,
} from '@pflegenavi/transaction-components';
import {
  Initiator,
  Link,
  makeConfig,
  PaginatedTable2,
} from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';
import {
  useFormatDate,
  useFormatting,
} from '@pflegenavi/frontend/localization';
import type { GetCashTransactionGroupTransactions } from '@pflegenavi/shared/api';
import { Stack, Tooltip, Typography } from '@mui/material';
import { YEAR_MONTH_DAY_SHORT_FORMAT } from '@pflegenavi/shared/constants';
import { generatePath, useNavigate } from 'react-router-dom';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import { useTrackStructuredEvent } from '@pflegenavi/frontend/tracking';

type TransactionTableColumnNames =
  | 'residentName'
  | 'type'
  | 'initiator'
  | 'amount'
  | 'submission-date'
  | 'info';

type TransactionTableFilters = never;

export const TransactionsTable: FC<{
  transactions: GetCashTransactionGroupTransactions[];
}> = ({ transactions }) => {
  const { t } = useTranslation();
  const { fCurrencyCents } = useFormatting();
  const fDate = useFormatDate();

  const navigate = useNavigate();

  const onNavigateUser = useCallback(
    (residentId: string) => {
      navigate(
        generatePath(nhAppMainPages.RESIDENT_DETAIL_ACCOUNT, {
          resident_id: residentId,
        })
      );
    },
    [navigate]
  );

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const onPageData = useMemo(() => {
    return transactions.slice(page * pageSize, (page + 1) * pageSize);
  }, [transactions, page, pageSize]);

  const config = useMemo(
    () =>
      makeConfig<
        GetCashTransactionGroupTransactions,
        TransactionTableColumnNames,
        TransactionTableFilters
      >({
        showAll: undefined,
        columns: [
          {
            key: 'residentName',
            label: t('residents.main'),
            align: 'left',
            component: ({ row }) => {
              const resident = row.resident;
              return resident !== undefined ? (
                <Link
                  to={'/'}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onNavigateUser(resident.id);
                  }}
                >
                  <Typography variant="subtitle2" noWrap>
                    {resident.firstName} {resident?.lastName}
                  </Typography>
                </Link>
              ) : (
                <>--</>
              );
            },
          },
          {
            key: 'type',
            label: t('common.type'),
            align: 'left',
            component: ({ row }) => (
              <TypeLabel
                label={getTypeString(row.sourceType, { t })}
                type={row.type}
                sourceType={row.sourceType}
              />
            ),
          },
          {
            key: 'initiator',
            label: t('resident.transactions.table.initiator'),
            align: 'left',
            component: ({ row }) => {
              return (
                <Initiator
                  initiatedBy={row.initiatedBy}
                  name={row.initiator?.fullName ?? '--'}
                  translationKeys={{
                    employee: 'web-components.initiator.employee',
                    disabled: 'web-components.initiator.disabled',
                    familyMember: 'web-components.initiator.family-member',
                    system: 'web-components.initiator.system',
                  }}
                  iconSize={20}
                />
              );
            },
          },
          {
            key: 'amount',
            label: t('resident.form.amount'),
            align: 'right',
            component: ({ row }) => <>{fCurrencyCents(row.amount)}</>,
          },
          {
            key: 'submission-date',
            label: t('common.submission-date'),
            align: 'center',
            component: ({ row }) => (
              <>{fDate(row.date, YEAR_MONTH_DAY_SHORT_FORMAT)}</>
            ),
          },
          {
            key: 'info',
            label: t('common.info'),
            align: 'left',
            component: ({ row }) => {
              const info = getTransactionInfo(
                row.sourceType,
                row.additionalData?.recurringItemName,
                row.receiptType,
                row.notes,
                { t }
              );

              return (
                <Tooltip
                  arrow
                  placement="top-start"
                  title={info.length > 15 ? info : undefined}
                >
                  <Typography sx={{ fontSize: '1rem' }}>
                    {' '}
                    {info.length > 15 ? info.substring(0, 15) + '...' : info}
                  </Typography>
                </Tooltip>
              );
            },
          },
        ],
        pagination: {
          page,
          pageSize,
          pageSizeOptions: [5, 10],
          total: transactions.length,
          onPageChange: setPage,
          onPageSizeChange: setPageSize,
        },
      }),
    [
      t,
      transactions.length,
      page,
      pageSize,
      fCurrencyCents,
      fDate,
      onNavigateUser,
    ]
  );

  if (!transactions || transactions.length === 0) {
    return null;
  }

  return (
    <Stack gap={2}>
      <Stack gap={0.5}>
        <Typography variant="h6" fontWeight="bold">
          {t('cashManagement.group-detail.overview.linked-transactions-title')}
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">
          {t('cashManagement.link.table-row.link-count.transaction', {
            count: transactions.length,
          })}
        </Typography>
      </Stack>

      <PaginatedTable2
        name="CashTransactionGroupDetailTransactionTable"
        useTrackStructuredEvent={useTrackStructuredEvent}
        config={config}
        rows={onPageData ?? []}
        isLoading={false}
      />
    </Stack>
  );
};
