import type { FC } from 'react';
import React from 'react';
import { Button, Card, Stack, Typography } from '@mui/material';
import type { CashState, ChangeCashMode } from '../form/model';
import type { Coin } from '@pflegenavi/shared/api';
import { Iconify } from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';
import { TestIds } from '../../TestIds';
import { TargetAmount } from './TargetAmount';
import { QuickSelectionProposedCash } from './QuickSelectionProposedCash';
import { useQuickSelection } from './UseQuickSelection';
import { SelectionHint } from './SelectionHint';
import { SelectedCoins } from './SelectedCoins';

interface ToggleShowAllCoinsProps {
  showAllCoins: boolean;
  setShowAllCoins: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ToggleShowAllCoins: FC<ToggleShowAllCoinsProps> = ({
  showAllCoins,
  setShowAllCoins,
}) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" justifyContent="flex-start">
      <Button
        variant="text"
        onClick={() => setShowAllCoins((oldShowAllCoins) => !oldShowAllCoins)}
        startIcon={
          <Iconify
            icon={showAllCoins ? 'eva:eye-off-fill' : 'eva:eye-fill'}
            mt="-2px"
          />
        }
        sx={{
          lineHeight: 1,
          height: 36,
        }}
      >
        {showAllCoins
          ? t('quick-selection.hide-all-coins')
          : t('quick-selection.show-all-coins')}
      </Button>
    </Stack>
  );
};

export interface QuickSelectionCardProps {
  mode: ChangeCashMode;
  cashState: CashState;
  setCashState: React.Dispatch<React.SetStateAction<CashState>>;
  bankNotes: Coin[] | undefined;
  coins: Coin[] | undefined;
  targetAmount: number;
  setTargetAmount:
    | React.Dispatch<React.SetStateAction<number | undefined>>
    | undefined;
}

export const QuickSelectionCard: FC<QuickSelectionCardProps> = (props) => {
  const { t } = useTranslation();
  const { targetAmount, coins, bankNotes, setTargetAmount, mode } = props;

  const {
    onlyShowCash,
    setShowAllCoins,
    selectedCoins,
    showAllCoins,
    fittingCoins,
    handleCoinSelect,
    overrideFactor,
    incrementFactor,
    decrementFactor,
    targetReached,
  } = useQuickSelection(props);

  return (
    <Card
      sx={{
        p: 3,
        flex: 2,
        minHeight: 480,
      }}
      data-testid={TestIds.QuickSelectionCard}
    >
      <Stack gap={3}>
        <Typography variant="h5" sx={{ lineHeight: 1 }}>
          {t('quick-selection.title')}
        </Typography>
        {!onlyShowCash && (
          <>
            <TargetAmount
              targetAmount={targetAmount}
              setTargetAmount={setTargetAmount}
            />
            {!showAllCoins && (
              <QuickSelectionProposedCash
                targetReached={targetReached}
                fittingCoins={fittingCoins}
                handleCoinSelect={handleCoinSelect}
              />
            )}
          </>
        )}

        <Stack gap={1}>
          <Typography variant="h6" lineHeight={1}>
            {t('quick-selection.selection')}
          </Typography>
          {selectedCoins.length === 0 && <SelectionHint />}
          {selectedCoins.length > 0 && (
            <SelectedCoins
              coins={coins}
              selectedCoins={selectedCoins}
              mode={mode}
              bankNotes={bankNotes}
              decrementFactor={decrementFactor}
              incrementFactor={incrementFactor}
              overrideFactor={overrideFactor}
            />
          )}
          <ToggleShowAllCoins
            showAllCoins={showAllCoins}
            setShowAllCoins={setShowAllCoins}
          />
        </Stack>
      </Stack>
    </Card>
  );
};
