import type { FC } from 'react';
import { useCallback, useRef, useState } from 'react';
import { Button, Divider, NameAvatar, Stack } from '@pflegenavi/web-components';
import { useAuthentication } from '@pflegenavi/frontend/authentication';
import { MenuItem, Popover, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { fmAppMainPages } from '@pflegenavi/frontend/routing';
import { DevTriggerErrorMenu } from './DevTriggerErrorMenu';
import { abbreviateName } from '@pflegenavi/shared/utils';

import type { ConfigKey } from './UseNavConfig';

const Anchor = styled('span')`
  position: absolute;
  left: -1px;
  top: -24px;
  height: 100%;
`;

const BottomAnchor = styled('span')`
  position: absolute;
  top: 15px;
  height: 100%;
`;

const Name = styled(Typography)`
  position: relative;
  font-size: 16px;
`;

interface UserAvatarProps {
  renderSwitchLanguageMenuItem?: ({
    handleClose,
  }: {
    handleClose: () => void;
  }) => React.ReactElement;
  configKey: ConfigKey;
  direction?: 'top' | 'bottom';
}

// eslint-disable-next-line complexity
export const UserAvatar: FC<UserAvatarProps> = ({
  renderSwitchLanguageMenuItem,
  configKey,
  direction = 'bottom',
}) => {
  const { t } = useTranslation();
  const { user, logout } = useAuthentication();
  const navigate = useNavigate();

  const [open, setOpen] = useState<HTMLElement | null>(null);
  const anchorRef = useRef<HTMLDivElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    await logout();
  };

  const handleClickProfile = () => {
    navigate(fmAppMainPages.PROFILE);
    handleClose();
  };

  const openPrivacyPolicy = useCallback(() => {
    const url = 'https://pflegenavi.at/privacy-policy-apps';
    window.open(url, '_blank');
  }, []);

  const openTermsAndConditions = useCallback(() => {
    const url = 'https://pflegenavi.at/terms-and-conditions';
    window.open(url, '_blank');
  }, []);

  const displayedUserName =
    user !== undefined
      ? (user.firstName &&
          user.lastName &&
          abbreviateName(`${user.firstName} ${user.lastName}`)) ||
        user.email ||
        user.username ||
        t('common.unknown-user')
      : t('common.unknown-user');

  return (
    <Stack direction="column">
      <Button fullWidth onClick={handleOpen} data-cy="user-avatar">
        <Stack
          direction="row"
          sx={{
            gap: 1,
            width: 1,
            alignItems: 'center',
          }}
        >
          <NameAvatar name={displayedUserName} />
          <Name
            variant="body2"
            sx={{
              mt: 0.2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {direction === 'bottom' && <Anchor ref={anchorRef} />}
            {displayedUserName}
            {direction === 'top' && (
              <span style={{ position: 'relative' }}>
                <BottomAnchor ref={anchorRef} />
              </span>
            )}
          </Name>
        </Stack>
      </Button>

      <Popover
        open={Boolean(open)}
        anchorEl={anchorRef?.current}
        onClose={handleClose}
        sx={{
          p: 0,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
        transformOrigin={
          direction === 'bottom'
            ? {
                vertical: 'bottom',
                horizontal: 'left',
              }
            : {
                vertical: 'top',
                horizontal: 'right',
              }
        }
        anchorOrigin={
          direction === 'top'
            ? {
                vertical: 'bottom',
                horizontal: 'right',
              }
            : undefined
        }
        PaperProps={{
          sx: {
            p: 1,
            width: 210,
            overflow: 'inherit',
          },
        }}
      >
        {!(configKey === 'nh-web') && (
          <MenuItem onClick={handleClickProfile}>
            {t('usermenu.profile')}
          </MenuItem>
        )}
        {renderSwitchLanguageMenuItem &&
          renderSwitchLanguageMenuItem({ handleClose })}
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem onClick={openTermsAndConditions}>
          {t('terms-and-conditions')}
        </MenuItem>
        <MenuItem onClick={openPrivacyPolicy}>{t('privacy-policy')}</MenuItem>
        <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
        {configKey !== 'sp-web' && <DevTriggerErrorMenu />}
      </Popover>
    </Stack>
  );
};
