import type {
  useFormatDate,
  useFormatTime,
  useFormatting,
} from '@pflegenavi/frontend/localization';
import type { CashTransactionTableRowModel } from '../interfaces/CashTransactionTableRowModel';
import type { GetCashManagementTransactionDto } from '@pflegenavi/shared/api';
import { CashManagementTransactionType } from '@pflegenavi/shared/api';
import {
  TIME_FORMAT,
  YEAR_MONTH_DAY_SHORT_FORMAT,
} from '@pflegenavi/shared/constants';
import type { Theme } from '@mui/material/styles';

interface formatCashTransactionTableRowModelOpts {
  fDate: ReturnType<typeof useFormatDate>;
  fCurrencyCents: ReturnType<typeof useFormatting>['fCurrency'];
  fTime: ReturnType<typeof useFormatTime>;
  theme: Theme;
  t: (key: string) => string;
  selectedCashListName: string;
}

// eslint-disable-next-line complexity
export const formatCashTransactionTableRowModel = (
  row: GetCashManagementTransactionDto,
  opts: formatCashTransactionTableRowModelOpts
): CashTransactionTableRowModel => {
  const { type, employee, amountInCents, date, notes, relatedResidents } = row;

  const typeLabelString = opts.t(`cashManagement.table-row.${row.type}`);

  const typeLabelColor =
    (type === CashManagementTransactionType.Deposit && 'success') ||
    (type === CashManagementTransactionType.StripeTransfer && 'warning') ||
    (type === CashManagementTransactionType.Withdrawal && 'info') ||
    (type === CashManagementTransactionType.Adjustment && 'default') ||
    (type === CashManagementTransactionType.ResidentDeposit && 'success') ||
    (type === CashManagementTransactionType.ResidentWithdrawal && 'info') ||
    'info';

  let residentNameString = '--';
  let residentFirstName = '--';
  let residentLastName = '--';

  if (relatedResidents) {
    if (relatedResidents.length > 0) {
      residentNameString = `${relatedResidents[0].firstName} ${relatedResidents[0].lastName}`;
      residentFirstName = relatedResidents[0].firstName;
      residentLastName = relatedResidents[0].lastName;
    }

    if (relatedResidents?.length > 1) {
      residentNameString += ` + ${relatedResidents.length - 1}`;
    }
  }

  const dateString = opts.fDate(date, YEAR_MONTH_DAY_SHORT_FORMAT);

  const hasEmployee =
    employee &&
    (employee.firstName?.length > 0 || employee.lastName?.length > 0);
  const employeeString = hasEmployee
    ? `${employee?.firstName} ${employee?.lastName}`
    : '--';

  const amountString = opts.fCurrencyCents(amountInCents);

  return {
    id: row.id,
    typeLabelString,
    type: type,
    typeLabelColor,
    residentNameString,
    residentFirstName: residentFirstName,
    residentLastName: residentLastName,
    dateString,
    date,
    employeeString,
    employeeFirstName:
      employee.firstName?.length > 0 ? employee?.firstName : '--',
    employeeLastName: employee.lastName?.length > 0 ? employee?.lastName : '--',
    amountString,
    amountInCents,
    info: notes.length > 0 ? notes : '--',
    timeString: opts.fTime(row.date, TIME_FORMAT),
    balanceAfterString: opts.fCurrencyCents(row.balanceAfterInCents),
    balanceBeforeString: opts.fCurrencyCents(row.balanceBeforeInCents),
    amountColor:
      amountInCents < 0
        ? 'error.main'
        : amountInCents > 0
        ? 'success.main'
        : opts.theme.palette.grey['400'],
    coinChange: row.coinChange,
    bankAccountAmount: row.bankAccountAmount,
    groupId: row.groupId,
    selectedCashListName: opts.selectedCashListName,
  };
};
