import type { FC } from 'react';
import React, { useMemo, useState } from 'react';
import {
  Box,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { experimental_sx as sx } from '@mui/system';
import {
  Iconify,
  MenuPopover,
  NameAvatar,
  TruncatedTooltipText,
} from '@pflegenavi/web-components';

import { useTranslation } from 'react-i18next';

import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';
import type { NursingHomeProfileDto } from '@pflegenavi/shared/api';
import { useNavigate } from 'react-router-dom';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import { useClientStoredResourcesContext } from '@pflegenavi/frontend/client-stored-resources-context';
import { FM_IMPORT_STATE_LOCAL_STORAGE_KEY } from '@pflegenavi/shared/constants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SelectNursingHomeProps {}

const ITEM_HEIGHT = 65;

export const SelectNursingHome: FC<SelectNursingHomeProps> = () => {
  const navigate = useNavigate();

  const clientStoredResources = useClientStoredResourcesContext();

  const { t } = useTranslation();
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const { selectedNursingHome, nursingHomeList: nursingHomes } =
    useNursingHomeContext();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleSelectedNursingHome = (nursingHome: NursingHomeProfileDto) => {
    navigate(
      nhAppMainPages.NURSING_HOME_SELECT.replace(
        ':nursingHomeId',
        nursingHome.id
      )
    );
    clientStoredResources.remove(FM_IMPORT_STATE_LOCAL_STORAGE_KEY);
    handleClose();
  };

  const SCROLLBAR_HEIGHT = useMemo(() => {
    const defaultHeight = 3 * ITEM_HEIGHT;
    if (nursingHomes) {
      const nbrOfItems = nursingHomes.length;
      return nbrOfItems > 3 ? defaultHeight : nbrOfItems * ITEM_HEIGHT;
    }
    return defaultHeight;
  }, [nursingHomes]);

  if (!nursingHomes || !selectedNursingHome || nursingHomes?.length === 1) {
    return null;
  }

  return (
    <>
      <SelectionButton
        direction="row"
        onClick={handleOpen}
        spacing={2}
        alignItems="center"
      >
        <Iconify
          icon={'mdi:home'}
          width={20}
          height={20}
          sx={{ minWidth: 20 }}
        />
        <TruncatedTooltipText text={`${selectedNursingHome?.name}`} noTooltip />
      </SelectionButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        arrow="top-left"
        sx={{
          mt: 0.5,
          ml: 6,
          width: 350,
          boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px',
          pb: 2,
          paddingX: 1.2,
          '& .MuiMenuItem-root': {
            px: 1.5,
            height: ITEM_HEIGHT,
            borderRadius: 1.5,
          },
        }}
      >
        <Typography variant="h6" sx={{ p: 1.5 }}>
          {t('nav.nursing-home-selection')}
        </Typography>
        <Box style={{ overflowY: 'auto' }} sx={{ height: SCROLLBAR_HEIGHT }}>
          {nursingHomes.map((nursingHome) => (
            <MenuItem
              key={nursingHome.id}
              onClick={() => handleSelectedNursingHome(nursingHome)}
              sx={{
                bgcolor:
                  selectedNursingHome?.id === nursingHome.id ? 'grey.200' : '',
              }}
            >
              <ListItemAvatar sx={{ position: 'relative' }}>
                <NameAvatar name={nursingHome.name} />
              </ListItemAvatar>

              <ListItemText
                primaryTypographyProps={{
                  typography: 'body1',
                  color: 'primary.main',
                }}
                secondaryTypographyProps={{ typography: 'caption' }}
                primary={nursingHome.name}
                secondary={
                  selectedNursingHome?.id === nursingHome.id
                    ? t('common.current-selection')
                    : null
                }
              />
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
};

const SelectionButton = styled(Stack)(
  sx({
    mb: 5,
    cursor: 'pointer',
    bgcolor: 'secondary.light',
    pt: 1.6,
    pb: 1.4,
    pl: 2,
    pr: 2,
    borderRadius: 1.5,
  })
);
