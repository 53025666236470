import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import { DefaultSuspense } from '@pflegenavi/web-components';
import { FeatureFlagStatus } from '@pflegenavi/shared/api';
import { ImportFamilyMembersPage } from './familyMembers/ImportFamilyMembersPage';

const ResidentsPage = lazy(() => import('./ResidentsPage'));
const AddResidentPage = lazy(() => import('./AddResidentPage'));
const InactiveResidentsPage = lazy(() => import('./InactiveResidentsPage'));
const ResidentDetailPage = lazy(() => import('./ResidentDetailPage'));
const ResidentDetailAccount = lazy(
  () => import('./residentDetail/ResidentDetailAccount')
);
const ResidentDetailOverview = lazy(
  () => import('./residentDetail/ResidentDetailOverview')
);
const ResidentDetailFamilyMembers = lazy(
  () => import('./residentDetail/ResidentDetailFamilyMembers')
);

const ResidentDetailServiceProviders = lazy(
  () => import('./residentDetail/ResidentDetailServiceProviders')
);

export const residentRoutes = (
  addFamilyMemberFeatureFlag: FeatureFlagStatus,
  importFamilyMemberFeatureFlag: FeatureFlagStatus,
  serviceProvidersAppFeatureFlag: FeatureFlagStatus
): JSX.Element => {
  return (
    <>
      <Route
        path={nhAppMainPages.RESIDENTS}
        element={
          <DefaultSuspense>
            <ResidentsPage />
          </DefaultSuspense>
        }
      />
      <Route
        path={nhAppMainPages.RESIDENTS_ADD}
        element={
          <DefaultSuspense>
            <AddResidentPage />
          </DefaultSuspense>
        }
      />
      {importFamilyMemberFeatureFlag === FeatureFlagStatus.Enabled && (
        <Route
          path={nhAppMainPages.RESIDENTS_IMPORT_FAMILY_MEMBERS}
          element={
            <DefaultSuspense>
              <ImportFamilyMembersPage />
            </DefaultSuspense>
          }
        />
      )}
      <Route
        path={nhAppMainPages.RESIDENTS_INACTIVE}
        element={
          <DefaultSuspense>
            <InactiveResidentsPage />
          </DefaultSuspense>
        }
      />
      <Route
        path={nhAppMainPages.RESIDENT_DETAIL_ACCOUNT}
        element={
          <DefaultSuspense>
            <ResidentDetailPage />
          </DefaultSuspense>
        }
      >
        <Route
          index={true}
          element={
            <DefaultSuspense>
              <ResidentDetailAccount />
            </DefaultSuspense>
          }
        />
        <Route
          path={nhAppMainPages.RESIDENT_DETAIL}
          element={
            <DefaultSuspense>
              <ResidentDetailOverview />
            </DefaultSuspense>
          }
        />
        {addFamilyMemberFeatureFlag === FeatureFlagStatus.Enabled && (
          <Route
            path={nhAppMainPages.RESIDENT_DETAIL_FAMILY_MEMBERS}
            element={
              <DefaultSuspense>
                <ResidentDetailFamilyMembers />
              </DefaultSuspense>
            }
          />
        )}
        {serviceProvidersAppFeatureFlag === FeatureFlagStatus.Enabled && (
          <Route
            path={nhAppMainPages.RESIDENT_DETAIL_SERVICE_PROVIDERS}
            element={
              <DefaultSuspense>
                <ResidentDetailServiceProviders />
              </DefaultSuspense>
            }
          />
        )}
      </Route>
    </>
  );
};
