import React, { type FC, useCallback, useMemo } from 'react';
import type { ServiceProviderPayment } from '@pflegenavi/shared/api';
import { FeatureFlag, PaymentStatus } from '@pflegenavi/shared/api';
import type { ConfigType } from '@pflegenavi/web-components';
import {
  Label,
  Link,
  makeConfig,
  OneLineTypography,
  PaginatedTable2,
  Stack,
  TransactionTypeLogo,
  Typography,
} from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';
import {
  useFormatDate,
  useFormatting,
} from '@pflegenavi/frontend/localization';
import { YEAR_SHORTMONTH_DAY_FORMAT } from '@pflegenavi/shared/constants';
import { generatePath, useNavigate } from 'react-router-dom';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import { useFeatureFlagEnabled } from '@pflegenavi/frontend/feature-flags';

export type Tab = 'all' | PaymentStatus;

interface ServiceProviderPaymentsTableProps {
  isLoading: boolean;
  serviceProviderPayments: ServiceProviderPayment[] | undefined;

  tab: Tab;
  setTab: (tab: Tab) => void;

  totalRowCount: number;
  page: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;

  onChangeDateRange: (range?: { from?: Date; to?: Date }) => void;
  createdDateRange?: {
    from?: Date;
    to?: Date;
  };
}

interface ServiceProviderPaymentsTableRow {
  id: string;
  employee: string;
  date: string;
  amount: string;
  status: PaymentStatus;
  statusColor: 'default' | 'info' | 'success' | 'warning' | 'error';
  last4Iban: string;
  serviceProviderId: string;
  serviceProvider: string;
  serviceProviderReceiptTypeName: string;
  receiptBatchId: string | null;
}

type ColumnNames =
  | 'serviceProvider'
  | 'employee'
  | 'date'
  | 'amount'
  | 'status'
  | 'last4Iban';
type Filters = 'created';

export const ServiceProviderPaymentsTable: FC<
  ServiceProviderPaymentsTableProps
> = ({
  serviceProviderPayments,
  isLoading,
  tab,
  setTab,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  totalRowCount,
  createdDateRange,
  onChangeDateRange,
}) => {
  const { t } = useTranslation();
  const fDate = useFormatDate();
  const { fCurrencyCents } = useFormatting();

  const serviceProviderEnabled = useFeatureFlagEnabled(
    FeatureFlag.dev_ServiceProviderApp
  );
  const navigate = useNavigate();

  const openServiceProvider = useCallback(
    (id: string) => {
      navigate(
        generatePath(nhAppMainPages.SERVICE_PROVIDER_DETAILS, {
          serviceProviderId: id,
        })
      );
    },
    [navigate]
  );

  const openReceiptBatch = useCallback(
    (id: string) => {
      navigate(
        generatePath(nhAppMainPages.RECEIPTS_BATCH_EDIT, {
          batchId: id,
        })
      );
    },
    [navigate]
  );

  const rows = useMemo<ServiceProviderPaymentsTableRow[]>(
    () =>
      // eslint-disable-next-line complexity
      serviceProviderPayments?.map((payment) => {
        const statusColor =
          payment.status === PaymentStatus.Pending
            ? 'info'
            : payment.status === PaymentStatus.Completed
            ? 'success'
            : payment.status === PaymentStatus.Failed
            ? 'error'
            : payment.status === PaymentStatus.Disputed
            ? 'error'
            : 'default';

        return {
          id: payment.id,
          date: fDate(payment.created, YEAR_SHORTMONTH_DAY_FORMAT),
          employee: payment.employee
            ? `${payment.employee.firstName ?? ''} ${
                payment.employee.lastName ?? ''
              }`
            : '--',
          serviceProviderId: payment.serviceProvider.id,
          serviceProvider: payment.serviceProvider
            ? payment.serviceProvider.name
            : '--',
          serviceProviderReceiptTypeName: payment.serviceProvider
            ? payment.serviceProvider.receiptType.name
            : 'Andere',
          amount: fCurrencyCents(payment.amount),
          status: payment.status,
          statusColor,
          last4Iban: payment.last4Iban ?? '--',
          receiptBatchId: payment.receiptBatchId,
        };
      }) ?? [],
    [fCurrencyCents, fDate, serviceProviderPayments]
  );

  const config: ConfigType<
    ServiceProviderPaymentsTableRow,
    ColumnNames,
    Filters
  > = useMemo(
    () =>
      makeConfig<ServiceProviderPaymentsTableRow, ColumnNames, Filters>({
        showAll: undefined,
        onClickRow: (row) => {
          if (row.receiptBatchId) {
            openReceiptBatch(row.receiptBatchId);
          }
        },
        tabs: {
          selectedItem: tab,
          updateFilterModel: setTab,
          items: [
            {
              value: 'all',
              label: t('accounting.service-provider-payments.table.all'),
              color: 'default',
              count: undefined,
            },
            {
              value: PaymentStatus.Pending,
              label: t('accounting.service-provider-payments.table.pending'),
              color: 'info',
              count: undefined,
            },
            {
              value: PaymentStatus.Completed,
              label: t('accounting.service-provider-payments.table.completed'),
              color: 'success',
              count: undefined,
            },
            {
              value: PaymentStatus.Failed,
              label: t('accounting.service-provider-payments.table.failed'),
              color: 'warning',
              count: undefined,
            },
            {
              value: PaymentStatus.Disputed,
              label: t('accounting.service-provider-payments.table.disputed'),
              color: 'error',
              count: undefined,
            },
          ],
        },
        columns: [
          {
            key: 'serviceProvider',
            label: t(
              'accounting.service-provider-payments.table.service-provider'
            ),
            align: 'left',
            component: ({ row }) => (
              <Stack direction="row" alignItems="center" gap={1}>
                <TransactionTypeLogo
                  logoType={row.serviceProviderReceiptTypeName}
                  size={45}
                />
                {serviceProviderEnabled ? (
                  <Link
                    to={'/'}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      openServiceProvider(row.serviceProviderId);
                    }}
                  >
                    <OneLineTypography
                      variant="subtitle2"
                      textOverflow="ellipsis"
                      maxWidth={300}
                    >
                      {row.serviceProvider}
                    </OneLineTypography>
                  </Link>
                ) : (
                  <OneLineTypography
                    variant="subtitle2"
                    textOverflow="ellipsis"
                    maxWidth={300}
                  >
                    {row.serviceProvider}
                  </OneLineTypography>
                )}
              </Stack>
            ),
          },
          {
            key: 'employee',
            label: t('accounting.service-provider-payments.table.employee'),
            align: 'left',
            component: ({ row }) => (
              <Typography variant="subtitle2">{row.employee}</Typography>
            ),
          },
          {
            key: 'date',
            label: t('accounting.service-provider-payments.table.date'),
            align: 'left',
            component: ({ row }) => (
              <Typography variant="subtitle2">{row.date}</Typography>
            ),
          },
          {
            key: 'last4Iban',
            label: t('accounting.service-provider-payments.table.last4iban'),
            align: 'left',
            component: ({ row }) => (
              <Typography variant="subtitle2">
                ************ {row.last4Iban}
              </Typography>
            ),
          },
          {
            key: 'amount',
            label: t('accounting.service-provider-payments.table.amount'),
            align: 'right',
            component: ({ row }) => (
              <Typography variant="subtitle2">{row.amount}</Typography>
            ),
          },
          {
            key: 'status',
            label: t('accounting.service-provider-payments.table.state'),
            align: 'left',
            component: ({ row }) => (
              <Label color={row.statusColor} variant="ghost">
                {t(`accounting.service-provider-payments.states.${row.status}`)}
              </Label>
            ),
          },
        ],
        filters: {
          activeFilters: 0,
          items: [
            {
              key: 'created',
              type: 'dateRange',
              label: t('accounting.service-provider-payments.filters.date'),
              range: createdDateRange,
              onChange: onChangeDateRange,
              isSet: () => !!createdDateRange?.from || !!createdDateRange?.to,
              unset: () => onChangeDateRange(undefined),
            },
          ],
          showActiveFiltersBar: true,
        },
        emptyRowHeight: 77,
        pagination: {
          page,
          pageSize,
          pageSizeOptions: [5, 10, 25, 50],
          total: totalRowCount,
          onPageChange,
          onPageSizeChange,
        },
      }),
    [
      tab,
      setTab,
      t,
      createdDateRange,
      onChangeDateRange,
      page,
      pageSize,
      totalRowCount,
      onPageChange,
      onPageSizeChange,
      serviceProviderEnabled,
      openServiceProvider,
      openReceiptBatch,
    ]
  );

  return (
    <PaginatedTable2
      name="ServiceProviderPaymentsTable"
      isLoading={isLoading}
      config={config}
      rows={rows}
    />
  );
};
