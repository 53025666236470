import type { BrowserTracker } from '@snowplow/browser-tracker';
import { newTracker, trackStructEvent } from '@snowplow/browser-tracker';
import type {
  InitializeOptions,
  ITracking,
} from '@pflegenavi/frontend/tracking';

// eslint-disable-next-line complexity
const determineEndpoint = () => {
  let endpoint = undefined;
  if (
    window.location.hostname === 'test.extranet.pflegenavi.at' ||
    window.location.hostname.endsWith('.staging.pflegenavi.at') ||
    window.location.hostname.endsWith('-staging.pflegenavi.at')
  ) {
    endpoint = 'https://snowplow-staging-tmp.pflegenavi.at';
  } else if (window.location.hostname.endsWith('.pflegenavi.at')) {
    endpoint = 'https://snowplow.pflegenavi.at';
  } else if (window.location.hostname === 'localhost') {
    endpoint = 'http://localhost:9090';
  }

  return endpoint;
};

export class SnowplowTracking implements ITracking {
  private tracker: BrowserTracker | undefined;

  initialize(options: InitializeOptions): void {
    const endpoint = determineEndpoint();

    if (endpoint) {
      this.tracker = newTracker('snowplow', endpoint, {
        appId: options.appId,
        plugins: [],
        discoverRootDomain: true,
        cookieSameSite: 'Lax',
        contexts: {
          browser: true,
          webPage: true,
        },
        anonymousTracking: {
          withServerAnonymisation: true,
        },
      });

      this.tracker.enableActivityTracking({
        heartbeatDelay: 59,
        minimumVisitLength: 9,
      });

      this.tracker.trackPageView();
    }
  }

  trackStructuredEvent<Event>(event: Event): Promise<void> {
    if (!this.tracker) {
      return Promise.resolve();
    }
    // @ts-expect-error event type mismatch
    trackStructEvent(event, [this.tracker.id]);
    return Promise.resolve();
  }
}
