import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useShallow } from 'zustand/react/shallow';
import { Stack, Typography } from '@mui/material';

import { useTransferFormStore } from './TransferFormStoreProvider';
import { CashListDropdown } from '../../CashListDropdown';

interface AccountSelectorProps {
  disabled: boolean;
  bucket: 'from' | 'to';
}

export const AccountSelector: FC<AccountSelectorProps> = ({
  bucket,
  disabled,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [bucketState] = useTransferFormStore(
    useShallow((state) => [state[bucket]])
  );

  const { color, title } = useMemo(() => {
    return {
      color:
        bucket === 'from'
          ? theme.palette.error.main
          : theme.palette.success.main,
      title: `${
        bucket === 'from' ? t('common.withdrawal') : t('common.deposit')
      }:`,
    };
  }, [bucket, theme.palette.error.main, theme.palette.success.main, t]);

  return (
    <Stack>
      <Typography fontWeight={700} variant="h6" color={color}>
        {title}
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        maxWidth={350}
      >
        <Typography variant="subtitle2">
          {`${t('cashManagement.transfer.selected-cash-list')}:`}
        </Typography>
        <CashListDropdown
          label={t('cashManagement.select-cash-list')}
          disabled={disabled}
          size="small"
          selectedCashListId={bucketState.cashListId}
          setSelectedCashListId={bucketState.setCashListId}
        />
      </Stack>
    </Stack>
  );
};
