import * as React from 'react';
import type { FC } from 'react';
import { FormHelperText } from '@mui/material';

interface FMImportErrorHelperTextProps {
  helperText: string | undefined;
}

export const FMImportErrorHelperText: FC<FMImportErrorHelperTextProps> = ({
  helperText,
}) => {
  return (
    <FormHelperText
      sx={{
        height: 10,
        color: 'error.main',
      }}
    >
      {helperText ?? ''}
    </FormHelperText>
  );
};
