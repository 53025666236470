import type { NavBarExtension } from '@pflegenavi/frontend/layout';
import { useFeatureFlag } from '@pflegenavi/frontend/feature-flags';
import { FeatureFlag, FeatureFlagStatus } from '@pflegenavi/shared/api';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';

import ServiceProviderIcon from './assets/service_provider_icon.svg';
import { SvgIconStyle } from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';

export const useConfig: () => NavBarExtension = () => {
  const serviceProviderAppEnabled =
    useFeatureFlag(FeatureFlag.dev_ServiceProviderApp) ===
    FeatureFlagStatus.Enabled;

  const { t } = useTranslation();

  if (!serviceProviderAppEnabled) {
    return undefined;
  }
  return {
    item: {
      icon: (
        <SvgIconStyle
          src={ServiceProviderIcon as unknown as string}
          sx={{
            width: 1,
            height: 1,
          }}
        />
      ),
      title: t('service-provider-page.service-provider'),
      path: nhAppMainPages.SERVICE_PROVIDER,
      children: [
        {
          title: t('common.overview'),
          path: nhAppMainPages.SERVICE_PROVIDER,
        },
        {
          title: t('service-provider-page.add-new-service-provider'),
          path: nhAppMainPages.SERVICE_PROVIDER_ADD,
        },
      ],
    },
  };
};
