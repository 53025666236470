import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@pflegenavi/web-components';
import transferCashIcon from '../../assets/transfer-cash.svg';
import { ChangeCashMode } from './model';
import { LetterChip } from './LetterChip';

interface PayoutsFormStep1Props {
  nextStep: () => void;
  setMode: (mode: ChangeCashMode) => void;
  setIsStripeTransfer: (isStripeTransfer: boolean) => void;
}

export const PayoutsFormStep1: FC<PayoutsFormStep1Props> = ({
  nextStep,
  setMode,
  setIsStripeTransfer,
}) => {
  const { t } = useTranslation();

  return (
    <Stack direction="column" gap={1} sx={{ paddingY: 1 }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <LetterChip>C</LetterChip>
        <Typography variant="h6" fontWeight={700}>
          {t('cashManagement.transfer-cash-title')}
        </Typography>
      </Stack>

      <Typography variant="body1">
        {t('cashManagement.transfer-cash-subtitle')}
      </Typography>

      <Stack direction="row" gap={1} justifyContent="end">
        <Button
          name={'changeCashTransferCash'}
          variant="contained"
          endIcon={
            <img
              style={{
                marginLeft: 0.5,
                width: 17,
              }}
              alt=""
              src={transferCashIcon}
            />
          }
          onClick={() => {
            setMode(ChangeCashMode.Deposit);
            setIsStripeTransfer(true);
            nextStep();
          }}
        >
          {t('cashManagement.transfer-cash')}
        </Button>
      </Stack>
    </Stack>
  );
};
