import { Iconify, Link, OneLineTypography } from '@pflegenavi/web-components';
import type { FC } from 'react';
import React, { useCallback } from 'react';
import type { CashTransactionGroupRowModel } from './model';
import { Avatar, Badge, Stack } from '@mui/material';
import { generatePath, useNavigate } from 'react-router-dom';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';

export const ResidentIcon: FC = () => {
  return (
    <Iconify
      icon="eva:person-fill"
      color="primary.main"
      width={24}
      height={24}
    />
  );
};

export const ReceiptIcon: FC = () => {
  return (
    <Iconify
      icon="mdi:receipt-text"
      color="primary.main"
      width={24}
      height={24}
    />
  );
};

export const EuroIcon: FC = () => {
  return (
    <Iconify
      icon="majesticons:euro-circle"
      color="primary.main"
      width={24}
      height={24}
    />
  );
};

export const EuroIconGrey: FC = () => {
  return (
    <Iconify
      icon="majesticons:euro-circle"
      sx={{
        color: 'grey.600',
      }}
      width={24}
      height={24}
    />
  );
};

export const BankIcon: FC = () => {
  return (
    <Iconify
      icon="ant-design:bank-filled"
      color="primary.main"
      width={24}
      height={24}
    />
  );
};

export const DepositIcon: FC = () => {
  return (
    <Avatar sx={{ backgroundColor: 'success.main', width: 16, height: 16 }}>
      <Iconify
        width={14}
        height={14}
        icon="eva:diagonal-arrow-right-up-fill"
        color="white"
      />
    </Avatar>
  );
};

export const WithdrawalIcon: FC = () => {
  return (
    <Avatar
      sx={{
        backgroundColor: 'error.main',
        width: 16,
        height: 16,
      }}
    >
      <Iconify
        width={14}
        height={14}
        color="white"
        icon="eva:diagonal-arrow-left-down-fill"
      />
    </Avatar>
  );
};

export const ZeroChangeIcon: FC = () => {
  return (
    <Avatar
      sx={{
        backgroundColor: 'grey.500',
        width: 16,
        height: 16,
        p: 0,
      }}
    >
      <Iconify
        width={13}
        height={13}
        color="white"
        icon="majesticons:minus-line"
      />
    </Avatar>
  );
};

interface ColumnAvatarProps {
  icon: React.ReactNode;
  subIcon: React.ReactNode;
}

const ColumnAvatar: FC<ColumnAvatarProps> = ({ subIcon, icon }) => {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={subIcon}
    >
      <Avatar>{icon}</Avatar>
    </Badge>
  );
};

interface TypeColumnProps {
  row: Pick<
    CashTransactionGroupRowModel,
    | 'type'
    | 'typeString'
    | 'typeSubString'
    | 'typeIcon'
    | 'typeSubIcon'
    | 'typeLinkedResidentId'
  >;
}

export const TypeColumn: FC<TypeColumnProps> = ({ row }) => {
  const navigate = useNavigate();
  const onNavigateUser = useCallback(
    (residentId?: string) => {
      if (!residentId) {
        return;
      }

      navigate(
        generatePath(nhAppMainPages.RESIDENT_DETAIL_ACCOUNT, {
          resident_id: row.typeLinkedResidentId,
        })
      );
    },
    [navigate, row.typeLinkedResidentId]
  );

  const typeStringElement = (
    <OneLineTypography variant="subtitle2" color="text.secondary">
      {row.typeString}
    </OneLineTypography>
  );

  return (
    <Stack direction="row" gap={2} alignItems="center">
      <ColumnAvatar icon={row.typeIcon} subIcon={row.typeSubIcon} />
      <Stack gap={0.5}>
        {row.typeLinkedResidentId ? (
          <Link
            variant={'subtitle2'}
            sx={{
              color: 'text.secondary',
            }}
            to={'/'}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onNavigateUser(row.typeLinkedResidentId);
            }}
          >
            {typeStringElement}
          </Link>
        ) : (
          typeStringElement
        )}
        <OneLineTypography fontWeight={700} variant="subtitle2">
          {row.typeSubString}
        </OneLineTypography>
      </Stack>
    </Stack>
  );
};
