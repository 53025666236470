import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FTextInput,
  Iconify,
  LoadingPromiseButtonAdvanced,
} from '@pflegenavi/web-components';
import type { FormikHelpers } from 'formik';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Card,
  IconButton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { experimental_sx as sx } from '@mui/system';
import type { BaseData, LegalEntity } from '@pflegenavi/shared/api';

const myInitialValues: BaseData = {
  company: '',
  address: '',
  house_number: '',
  zip_code: '',
  city: '',
  phone: '',
  fax: '',
  email: '',
  website: '',
};

export interface LegalEntityFormModalProps {
  title: string;
  setOpen: (open: boolean) => void;
  handleSubmitLegalEntity: (data: {
    id: string;
    base_data: BaseData;
  }) => Promise<void>;
  legalEntity?: LegalEntity;
}

export const LegalEntityForm: FC<LegalEntityFormModalProps> = ({
  title,
  legalEntity,
  setOpen,
  handleSubmitLegalEntity,
}) => {
  const { t } = useTranslation();

  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const validationSchema = useMemo(() => {
    return Yup.object({
      company: Yup.string().required(t('legal-entities.form.required.company')),
      address: Yup.string().required(t('legal-entities.form.required.address')),
      house_number: Yup.string().required(
        t('legal-entities.form.required.house-number')
      ),
      zip_code: Yup.string().required(t('legal-entities.form.required.zip')),
      city: Yup.string().required(t('legal-entities.form.required.city')),
      phone: Yup.string().required(t('legal-entities.form.required.phone')),
      fax: Yup.string().nullable(),
      email: Yup.string()
        .email(t('legal-entities.form.valid.email'))
        .nullable(),
      website: Yup.string().nullable(),
    });
  }, [t]);

  const submitForm = useCallback(
    async (values: BaseData, { setSubmitting }: FormikHelpers<BaseData>) => {
      await handleSubmitLegalEntity({
        id: legalEntity?.id ?? '',
        base_data: values,
      });
      closeModal();
      setSubmitting(false);
    },
    [handleSubmitLegalEntity, legalEntity?.id, closeModal]
  );

  const formik = useFormik({
    initialValues: {
      ...myInitialValues,
      ...legalEntity?.base_data,
    },
    enableReinitialize: true,
    onSubmit: submitForm,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
  });

  return (
    <FormCard>
      <Stack gap={3}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6" fontWeight={700}>
            {title}
          </Typography>
          <IconButton
            onClick={closeModal}
            sx={{
              padding: '16px',
              marginRight: '-8px',
            }}
          >
            <Iconify
              icon={'eva:close-fill'}
              sx={{
                position: 'absolute',
              }}
              width={20}
            />
          </IconButton>
        </Stack>
        <Stack
          gap={2}
          direction="row"
          flexWrap="wrap"
          sx={{
            '& > .MuiFormControl-root': {
              width: '49%',
            },
          }}
        >
          <FTextInput
            id="company"
            name="company"
            required
            formik={formik}
            label={t('legal-entities.form.company')}
          />

          <FTextInput
            id="address"
            name="address"
            required
            formik={formik}
            label={t('legal-entities.form.address')}
          />

          <FTextInput
            id="house_number"
            name="house_number"
            required
            formik={formik}
            label={t('legal-entities.form.house-number')}
          />

          <FTextInput
            id="zip_code"
            name="zip_code"
            required
            formik={formik}
            label={t('legal-entities.form.zip')}
          />

          <FTextInput
            id="city"
            name="city"
            required
            formik={formik}
            label={t('legal-entities.form.city')}
          />

          <FTextInput
            id="phone"
            name="phone"
            type="tel"
            required
            formik={formik}
            label={t('legal-entities.form.phone')}
          />

          <FTextInput
            id="fax"
            name="fax"
            formik={formik}
            label={`${t('legal-entities.form.fax')} (${t('common.optional')})`}
          />

          <FTextInput
            id="email"
            name="email"
            formik={formik}
            label={`${t('legal-entities.form.email')} (${t(
              'common.optional'
            )})`}
          />

          <FTextInput
            id="website"
            name="website"
            formik={formik}
            label={`${t('legal-entities.form.website')} (${t(
              'common.optional'
            )})`}
            fullWidth
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" gap={1}>
          <Button onClick={closeModal}>
            {t('legal-entities.form.cancel')}
          </Button>
          <LoadingPromiseButtonAdvanced
            variant="contained"
            disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
            onClick={formik.submitForm}
          >
            {t('legal-entities.form.save')}
          </LoadingPromiseButtonAdvanced>
        </Stack>
      </Stack>
    </FormCard>
  );
};

const FormCard = styled(Card)(
  sx({
    p: 3,
    minWidth: 600,
    width: 900,
  })
);
