import { Button } from '@mui/material';
import { Iconify } from '@pflegenavi/web-components';
import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface SkipCashSelectionButtonProps {
  onClick: () => void;
}
export const SkipCashSelectionButton: FC<SkipCashSelectionButtonProps> = ({
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <Button onClick={onClick} startIcon={<Iconify icon="mdi:fast-forward" />}>
      {t('cashManagement.skip-cash-transaction')}
    </Button>
  );
};
