import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Card,
  Iconify,
  LoadingContainer,
  Stack,
  Typography,
} from '@pflegenavi/web-components';
import { CardHeader, Divider } from '@mui/material';
import { coinsToCashState, compareCashStates, sumCashState } from '../euro';
import type { Coin } from '@pflegenavi/shared/api';
import { CashListStorageType, FeatureFlag } from '@pflegenavi/shared/api';
import type { CashState } from './model';
import { ChangeCashMode } from './model';
import { useTranslation } from 'react-i18next';
import { useFormatting } from '@pflegenavi/frontend/localization';
import { useTransfer } from '@pflegenavi/frontend/api-nursing-home';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';
import { BankOrCash } from './ChangeCashForm';
import { CashSelectionSummary } from './CashSelectionSummary';
import { BankAccountAdjustForm } from './BankAccountAdjustForm';
import { QuickSelectionCard } from '../selection/QuickSelectionCard';
import { SkipCashSelectionButton } from './atoms/SkipCashSelectionButton';
import { useFeatureFlagEnabled } from '@pflegenavi/frontend/feature-flags';

interface CashSelectionFormProps {
  nextStep: () => void;
  mode: ChangeCashMode;
  cashState: CashState;
  setCashState: Dispatch<SetStateAction<CashState>>;
  handleClose: VoidFunction;
  bankNotes: Coin[] | undefined;
  coins: Coin[] | undefined;
  isStripeTransfer: boolean;
  setBankOrCash: React.Dispatch<React.SetStateAction<BankOrCash>>;
  bankAccountChange?: number;
  setBankAccountChange: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  requiredAmount?: number;
  allowSkipCashSelection: boolean;
  setSkippedCashSelection: React.Dispatch<React.SetStateAction<boolean>>;
  cashListId: string;
  storageType: CashListStorageType;
}

// eslint-disable-next-line complexity
export const CashSelectionForm: FC<CashSelectionFormProps> = ({
  mode,
  nextStep,
  cashState,
  setCashState,
  handleClose,
  coins,
  bankNotes,
  isStripeTransfer,
  setBankOrCash,
  bankAccountChange,
  setBankAccountChange,
  requiredAmount: requiredAmountInitial,
  allowSkipCashSelection,
  setSkippedCashSelection,
  cashListId,
  storageType,
}) => {
  const { t } = useTranslation();
  const newAccountingPaymentsFeatureFlagEnabled = useFeatureFlagEnabled(
    FeatureFlag.AccountingPaymentsNew
  );

  const [requiredAmount, setRequiredAmountOriginal] = useState(
    requiredAmountInitial
  );
  const setRequiredAmount = requiredAmountInitial
    ? undefined
    : setRequiredAmountOriginal;

  const handleSwitchToBankAccount = useCallback(() => {
    setCashState({});
    setBankOrCash(BankOrCash.Bank);
  }, [setBankOrCash, setCashState]);

  const cashStateChanged = useMemo(() => {
    const coinsCashState = coinsToCashState(
      (coins ?? []).concat(bankNotes ?? [])
    );
    return !compareCashStates(coinsCashState, cashState);
  }, [coins, bankNotes, cashState]);

  const nextStepDisabled =
    mode === ChangeCashMode.Adjust &&
    !cashStateChanged &&
    (bankAccountChange === 0 || bankAccountChange === undefined);

  return (
    <Stack direction="row" gap={3} alignItems="flex-start">
      {storageType & CashListStorageType.Cash ? (
        <QuickSelectionCard
          mode={mode}
          cashState={cashState}
          bankNotes={bankNotes}
          setCashState={setCashState}
          coins={coins}
          targetAmount={requiredAmount ?? 0}
          setTargetAmount={setRequiredAmount}
        />
      ) : undefined}
      <Stack direction="column" gap={3} flex={1}>
        {storageType & CashListStorageType.Cash ? (
          <CashSelectionSummary
            cashState={cashState}
            bankNotes={bankNotes}
            coins={coins}
            setCashState={setCashState}
            mode={mode}
            nextStep={nextStep}
            handleClose={handleClose}
            isStripeTransfer={isStripeTransfer}
            requiredAmount={requiredAmount === 0 ? undefined : requiredAmount}
          />
        ) : undefined}
        {isStripeTransfer && (
          <TransferRemainingOverviewCard
            amountToBeTransferred={sumCashState(cashState)}
          />
        )}
        {!isStripeTransfer ? (
          mode === ChangeCashMode.Adjust ? (
            <BankAccountAdjustForm
              bankAccountChange={bankAccountChange}
              setBankAccountChange={setBankAccountChange}
              nextStep={nextStepDisabled ? undefined : nextStep}
              handleClose={handleClose}
              cashListId={cashListId}
              storageType={storageType}
            />
          ) : (
            <Stack direction="row" justifyContent="space-between">
              {!newAccountingPaymentsFeatureFlagEnabled && (
                <Button
                  onClick={handleSwitchToBankAccount}
                  startIcon={
                    <Iconify
                      style={{ marginTop: -4 }}
                      icon="ant-design:bank-filled"
                    />
                  }
                  sx={{
                    visibility:
                      storageType & CashListStorageType.BankAccount
                        ? undefined
                        : 'hidden',
                  }}
                >
                  {t(
                    `cashManagement.${
                      mode === ChangeCashMode.Deposit
                        ? 'deposit-to'
                        : 'withdraw-from'
                    }-bank-account`
                  )}
                </Button>
              )}
              {allowSkipCashSelection && (
                <SkipCashSelectionButton
                  onClick={() => {
                    setSkippedCashSelection(true);
                    nextStep();
                  }}
                />
              )}
            </Stack>
          )
        ) : null}
      </Stack>
    </Stack>
  );
};

type TransferRemainingOverviewCardProps = Omit<
  TransferRemainingOverviewCardViewProps,
  'available'
>;

export const TransferRemainingOverviewCard: FC<
  TransferRemainingOverviewCardProps
> = ({ amountToBeTransferred }) => {
  const { selectedNursingHome } = useNursingHomeContext();
  const { isLoading, data } = useTransfer(selectedNursingHome?.id);

  if (isLoading || data === undefined) {
    return <LoadingContainer />;
  }

  return (
    <TransferRemainingOverviewCardView
      available={data}
      amountToBeTransferred={amountToBeTransferred}
    />
  );
};

interface TransferRemainingOverviewCardViewProps {
  available: number;
  amountToBeTransferred: number;
}

export const TransferRemainingOverviewCardView: FC<
  TransferRemainingOverviewCardViewProps
> = ({ available, amountToBeTransferred }) => {
  const { t } = useTranslation();
  const { fCurrency } = useFormatting();

  const transferColor =
    amountToBeTransferred <= available ? 'success.main' : 'error.main';

  return (
    <Card
      sx={{
        p: 2,
        width: '100%',
      }}
    >
      <CardHeader
        title={t('cashManagement.transfer-cash-title')}
        sx={{ p: 0, mb: 2 }}
        titleTypographyProps={{
          variant: 'subtitle1',
          sx: { fontWeight: 700 },
        }}
      />

      <Stack direction="column" gap={1}>
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2" color="grey.600">
            {t('cashManagement.available-for-transfer')}
          </Typography>
          <Typography variant="subtitle2" color="grey.600">
            {fCurrency(available)}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2" color="grey.600">
            {t('cashManagement.current-transfer')}
          </Typography>
          <Typography variant="subtitle2" color={transferColor}>
            {fCurrency(amountToBeTransferred)}
          </Typography>
        </Stack>
        <Divider />
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle1" fontWeight={700}>
            {t('cashManagement.remaining-funds')}
          </Typography>
          <Typography variant="subtitle1" fontWeight={700}>
            {fCurrency(available - amountToBeTransferred)}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};
