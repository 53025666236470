import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import type { CircleFilterValues } from './useResidentsPage';
import { TabFilterValues } from './useResidentsPage';

interface NavigateToResidentsPageParams {
  circleFilter?: CircleFilterValues;
  selectedTab?: TabFilterValues;
  searchTerm?: string;
  tablePage?: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useNavigateToResidentsPage = () => {
  const navigate = useNavigate();
  const navigateToResidentPage = useCallback(
    (params: NavigateToResidentsPageParams) => {
      const selectedTab = params.selectedTab ?? TabFilterValues.ALL;
      navigate(
        {
          pathname: nhAppMainPages.RESIDENTS,
          search: `?tabFilter=${selectedTab}`,
        },
        {
          state: undefined,
        }
      );
    },
    [navigate]
  );

  return {
    navigateToResidentPage,
  };
};
