import type { Coin } from '@pflegenavi/shared/api';
import { ChangeCashMode } from '../form/model';
import type { FC } from 'react';
import React from 'react';
import { Stack } from '@mui/material';
import { CashRow } from './CashRow';
import { animation } from './QuickSelectionProposedCash';

interface SelectedCoinsProps {
  selectedCoins: Coin[];
  mode: ChangeCashMode;
  coins: Coin[] | undefined;
  bankNotes: Coin[] | undefined;
  incrementFactor: (factor: number) => void;
  decrementFactor: (factor: number) => void;
  overrideFactor: (factor: number, newValue: number) => void;
}

export const SelectedCoins: FC<SelectedCoinsProps> = ({
  mode,
  bankNotes,
  coins,
  selectedCoins,
  overrideFactor,
  incrementFactor,
  decrementFactor,
}) => {
  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      {selectedCoins.map((coin, index) => {
        const quantity = coin.amount;

        if (mode !== ChangeCashMode.Deposit) {
          const amount =
            (coins ?? [])
              .concat(bankNotes ?? [])
              .find((c) => c.factor === coin.factor)?.amount ?? 0;
          coin = {
            ...coin,
            amount,
          };
        }
        return (
          <CashRow
            key={coin.factor}
            mode={mode}
            coin={coin}
            increment={incrementFactor}
            decrement={decrementFactor}
            override={overrideFactor}
            quantity={quantity}
            sx={{ ...animation }}
            autoFocus={quantity > 0}
          />
        );
      })}
    </Stack>
  );
};
