import type { ReactNode } from 'react';
// @mui
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
// hooks
import type { SupportedLocales } from '@pflegenavi/frontend/localization';
import { useLocale } from '@pflegenavi/frontend/localization';
import type { Localization } from '@mui/material/locale';
import { deDE, enUS } from '@mui/material/locale';
//

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

interface Props {
  children: ReactNode;
}

const muiTranslationMap: { [key in SupportedLocales]: Localization } = {
  'de-AT': deDE,
  'en-GB': enUS,
  cimode: deDE,
};

export function ThemeLocalization({ children }: Props): JSX.Element {
  const defaultTheme = useTheme();
  const locale = useLocale();

  const theme = createTheme(defaultTheme, muiTranslationMap[locale] ?? deDE);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
