import { LocalizationProvider as LocalizationProviderBase } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import type { FC, PropsWithChildren } from 'react';
import { useLocale } from '@pflegenavi/frontend/localization';
import de from 'date-fns/locale/de-AT';
import en from 'date-fns/locale/en-GB';

const LOCALE_MAP = {
  'en-GB': en,
  'de-AT': de,
  cimode: de,
};

export const LocalizationProvider: FC<PropsWithChildren> = ({ children }) => {
  const locale = useLocale();

  const getLocale = () => {
    return LOCALE_MAP[locale] ?? de;
  };

  return (
    <LocalizationProviderBase
      dateAdapter={AdapterDateFns}
      adapterLocale={getLocale()}
    >
      {children}
    </LocalizationProviderBase>
  );
};
