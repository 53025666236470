import { lazy, useMemo } from 'react';
import { Route } from 'react-router-dom';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import { DefaultSuspense } from '@pflegenavi/web-components';

const CashOverviewPage = lazy(() => import('./CashOverviewPage'));
const CashOverviewPageV3 = lazy(() => import('./CashOverviewPageV3'));
const CashTransactionsPage = lazy(() => import('./CashTransactionsPage'));
const CashTransactionGroupDetailPage = lazy(
  () => import('./CashTransactionGroupDetailPage')
);

export const useCashManagementRoutes = (): JSX.Element => {
  const routes = useMemo(
    () => (
      <Route path={nhAppMainPages.CASH_MANAGEMENT}>
        <Route
          index={true}
          element={
            <DefaultSuspense>
              <CashOverviewPageV3 />
            </DefaultSuspense>
          }
        />
        <Route
          path={nhAppMainPages.CASH_MANAGEMENT_CASH}
          element={<CashOverviewPage />}
        />

        <Route
          path={nhAppMainPages.CASH_MANAGEMENT_GROUP_DETAIL}
          element={<CashTransactionGroupDetailPage />}
        />
        <Route
          path={nhAppMainPages.CASH_MANAGEMENT_TRANSACTIONS}
          element={
            <DefaultSuspense>
              <CashTransactionsPage />
            </DefaultSuspense>
          }
        />
        <Route
          path={nhAppMainPages.CASH_MANAGEMENT_CHANGE_CASH}
          element={null}
        />
      </Route>
    ),
    []
  );

  return routes;
};
