import React, { useState } from 'react';
import { Button, HeaderBreadcrumbs } from '@pflegenavi/web-components';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import { Container, Stack } from '@mui/material';
import IconSyncOutline from '~icons/eva/sync-outline';

import { CashCards } from './components/CashCards';
import { DetailedCashOverviewCard } from './components/DetailedCashOverview';
import { useTranslation } from 'react-i18next';
import { ChangeCashModal } from './components/form/ChangeCashModal';
import { useFeatureFlagEnabled } from '@pflegenavi/frontend/feature-flags';
import { FeatureFlag } from '@pflegenavi/shared/api';

export const CashOverviewPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth={'lg'}>
      <CashManagementHeaderBreadcrumbs
        pageTitle={t('nav.cash-management.overview')}
      />

      <Stack direction="column" gap={3} width="fit-content">
        <CashCards />
        <DetailedCashOverviewCard />
      </Stack>
    </Container>
  );
};

export const CashManagementHeaderBreadcrumbs: React.FC<{
  pageTitle: string;
  hideActionButton?: boolean;
}> = ({ pageTitle, hideActionButton = false }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { mainTitle, mainRoute, pageRoute } = useFeatureFlagEnabled(
    FeatureFlag.AccountingPaymentsNew
  )
    ? {
        mainTitle: t('accounting.page.title-new'),
        mainRoute: nhAppMainPages.NURSING_HOME_ACCOUNTING_OVERVIEW_NEW,
        pageRoute: nhAppMainPages.NURSING_HOME_ACCOUNTING_TRANSACTIONS,
      }
    : {
        mainTitle: t('cashManagement.title'),
        mainRoute: nhAppMainPages.CASH_MANAGEMENT,
        pageRoute: nhAppMainPages.CASH_MANAGEMENT_TRANSACTIONS,
      };

  return (
    <Stack>
      <HeaderBreadcrumbs
        heading={mainTitle}
        links={[
          {
            name: mainTitle,
            href: mainRoute,
          },
          {
            name: pageTitle,
            href: pageRoute,
          },
        ]}
        action={
          hideActionButton ? null : (
            <Button
              variant="contained"
              startIcon={<IconSyncOutline />}
              onClick={() => setOpen(true)}
            >
              {t('cashManagement.change-cash')}
            </Button>
          )
        }
      />
      <ChangeCashModal open={open} setOpen={setOpen} />
    </Stack>
  );
};

export default CashOverviewPage;
