import type { FC } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';
import { LoadingContainer } from '@pflegenavi/web-components';
import { useRedirectUrlContext } from '@pflegenavi/frontend/nursing-home-context';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface InitNursingHomePageProps {}

export const InitNursingHomePage: FC<InitNursingHomePageProps> = () => {
  const navigate = useNavigate();
  const { nursingHomeId } = useParams(); // Access the parameter from props.match.params

  const { nursingHomeList, initNursingHome } = useNursingHomeContext();
  const { redirectUrl } = useRedirectUrlContext();

  useEffect(() => {
    const nursingHome = nursingHomeList.find(
      (nursingHome) => nursingHome.id === nursingHomeId
    );
    if (nursingHome) {
      setTimeout(async () => {
        await initNursingHome(nursingHome);
        navigate(redirectUrl);
      }, 500);
    }
  }, [initNursingHome, navigate, nursingHomeId, nursingHomeList, redirectUrl]);

  return (
    <LoadingContainer
      style={{
        height: '50vh',
      }}
    />
  );
};
