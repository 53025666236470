import { alpha, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { experimental_sx as sx } from '@mui/system';
import { ChangeCashMode } from '../model';

export const CashChangeAvatarIcon = styled(Box)(
  sx({
    bgcolor: 'grey.300',
    height: 40,
    width: 40,
    borderRadius: '500%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })
);

export const IconWrapperStyle = styled('div')<{
  mode: ChangeCashMode;
  amount: number;
}>(({ theme, mode, amount }) => {
  const getColor = () => {
    if (mode === ChangeCashMode.Adjust) {
      return amount < 0 ? theme.palette.error.main : theme.palette.success.main;
    }
    return mode === ChangeCashMode.Withdraw
      ? theme.palette.error.main
      : theme.palette.success.main;
  };
  return {
    width: 24,
    height: 24,
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    color: getColor(),
    backgroundColor: alpha(getColor(), 0.16),
    transform: 'translateY(-1px)',
  };
});
