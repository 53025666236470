import type { VFC } from 'react';
import React from 'react';
import { Container, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CashListTransaction } from './components/table/CashListTransaction';
import { CashManagementHeaderBreadcrumbs } from './CashOverviewPage';
import { useCashListTableQuery } from './useCashListTableQuery';
import { CashListDropdownView } from './components/CashListDropdown';
import { useFeatureFlagEnabled } from '@pflegenavi/frontend/feature-flags';
import { FeatureFlag } from '@pflegenavi/shared/api';
import { LoadingContainer } from '@pflegenavi/web-components';

export const CashTransactionsPage: VFC = () => {
  const { t } = useTranslation();

  const { cashListId, setCashListId, cashLists } = useCashListTableQuery();
  const hasAccountingPaymentsNew = useFeatureFlagEnabled(
    FeatureFlag.AccountingPaymentsNew
  );

  if (!cashListId) {
    return (
      <Container maxWidth={'lg'}>
        <CashManagementHeaderBreadcrumbs
          pageTitle={
            hasAccountingPaymentsNew
              ? t('accounting.nav.transactions')
              : t('nav.cash-management.cash-transactions')
          }
        />
        <LoadingContainer />
      </Container>
    );
  }

  return (
    <Container maxWidth={'lg'}>
      <CashManagementHeaderBreadcrumbs
        pageTitle={
          hasAccountingPaymentsNew
            ? t('accounting.nav.transactions')
            : t('nav.cash-management.cash-transactions')
        }
      />
      <Stack direction="column" gap={3}>
        {hasAccountingPaymentsNew && (
          <CashListDropdownView
            selectedCashListId={cashListId}
            setSelectedCashListId={setCashListId}
            cashLists={cashLists}
          />
        )}
        <CashListTransaction cashListId={cashListId} />
      </Stack>
    </Container>
  );
};

export default CashTransactionsPage;
