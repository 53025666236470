import type { LocaleStorage } from '@pflegenavi/frontend/localization';
export { ThemeLocalization } from './lib/ThemeLocalization';
export { LocalizationProvider } from './lib/LocalizationProvider';
export { DevLangSwitcher } from './lib/components/DevLangSwitcher';

const LANGUAGE_KEY = 'i18nextLng';
const LOCALE_KEY = 'locale';

export const localeStorage: LocaleStorage = {
  getLanguage(): Promise<string | undefined> {
    return Promise.resolve(localStorage.getItem(LANGUAGE_KEY) ?? undefined);
  },
  getLocale(): Promise<string | undefined> {
    return Promise.resolve(localStorage.getItem(LOCALE_KEY) ?? undefined);
  },
  setLanguage(language: string): Promise<void> {
    localStorage.setItem(LANGUAGE_KEY, language);
    return Promise.resolve();
  },
  setLocale(locale): Promise<void> {
    localStorage.setItem(LOCALE_KEY, locale);
    return Promise.resolve();
  },
};
