import { Card, Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import type { FC } from 'react';
import { useMemo } from 'react';
import { TypeColumn } from '../groupTable/TypeColumn';
import {
  useFormatDate,
  useFormatting,
} from '@pflegenavi/frontend/localization';
import { useTranslation } from 'react-i18next';
import type { CashTransactionGroupType } from '@pflegenavi/shared/api';
import { formatTypeColumn } from '../groupTable/model';
import { TIME_FORMAT } from '@pflegenavi/shared/constants';

interface GroupOverviewProps {
  type: CashTransactionGroupType;
  lastUpdateDate: Date;

  cashAmount: number;
  cashTransactionCount: number;

  linkedAmount: number;
  linkedTransactionCount: number;
  linkedReceiptCount: number;
  linkedPayoutCount: number;
  linkedPaymentIntentCount: number;
  linkedCashListTransactionCount: number;
}

export const GroupOverview: FC<GroupOverviewProps> = ({
  type,
  lastUpdateDate,
  cashTransactionCount,
  linkedReceiptCount,
  linkedTransactionCount,
  linkedAmount,
  linkedPaymentIntentCount,
  linkedPayoutCount,
  linkedCashListTransactionCount,
  cashAmount,
}) => {
  const { t } = useTranslation();
  const fDate = useFormatDate();
  const row = useMemo(() => {
    return formatTypeColumn({ type }, { t });
  }, [t, type]);

  const difference = cashAmount - linkedAmount;

  const smallScreens = useMediaQuery('(max-width:895px)');

  const linkSubItem = useMemo(() => {
    if (linkedPaymentIntentCount > 0) {
      return (
        <SubItem
          label={t('cashManagement.group-detail.linked-payment-intents')}
          amount={linkedPaymentIntentCount}
        />
      );
    }
    if (linkedPayoutCount > 0) {
      return (
        <SubItem
          label={t('cashManagement.group-detail.linked-payouts')}
          amount={linkedPayoutCount}
        />
      );
    }
    if (linkedCashListTransactionCount > 0) {
      return (
        <SubItem
          label={t('cashManagement.group-detail.linked-cash-list-transactions')}
          amount={linkedCashListTransactionCount}
        />
      );
    }
    return (
      <>
        <SubItem
          label={t('cashManagement.group-detail.linked-transactions')}
          amount={linkedTransactionCount}
        />
        <SubItem
          label={t('cashManagement.group-detail.linked-receipts')}
          amount={linkedReceiptCount}
        />
      </>
    );
  }, [
    linkedCashListTransactionCount,
    linkedPaymentIntentCount,
    linkedPayoutCount,
    linkedReceiptCount,
    linkedTransactionCount,
    t,
  ]);

  return (
    <Card sx={{ p: 3 }}>
      <Stack gap={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
          gap={3}
        >
          <TypeColumn row={row} />
          <Typography fontWeight={700} fontSize={14}>
            {t('cashManagement.group-detail.last-update')}{' '}
            <Typography display={'inline'} fontSize={14}>
              {fDate(lastUpdateDate, TIME_FORMAT)}
            </Typography>
          </Typography>
        </Stack>
        <Divider />
        <Stack
          direction="row"
          justifyContent={smallScreens ? 'center' : 'space-between'}
          flexWrap="wrap"
          gap={3}
        >
          <HeaderAmountColumn
            label={t('cashManagement.group-detail.cash-amount')}
            amount={cashAmount}
            subItems={
              <SubItem
                label={t('cashManagement.group-detail.cash-transactions')}
                amount={cashTransactionCount}
              />
            }
          />
          <HeaderAmountColumn
            label={t('cashManagement.group-detail.linked-amount')}
            amount={linkedAmount}
            subItems={linkSubItem}
          />

          <HeaderAmountColumn
            label={t('cashManagement.group-detail.open-amount')}
            amount={difference}
            subItems={null}
          />
        </Stack>
      </Stack>
    </Card>
  );
};

interface HeaderAmountColumnProps {
  label: string;
  amount: number;
  subItems: React.ReactNode;
}

const HeaderAmountColumn: FC<HeaderAmountColumnProps> = ({
  label,
  amount,
  subItems,
}) => {
  const { fCurrencyCents } = useFormatting();
  return (
    <Stack gap={1}>
      <Typography variant="overline" color="text.secondary">
        {label}
      </Typography>
      <Stack
        sx={{
          minWidth: 250,
          backgroundColor: 'grey.300',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 2,
          p: 1,
        }}
      >
        <Typography fontWeight={700} variant="h4">
          {fCurrencyCents(amount)}
        </Typography>
      </Stack>
      {subItems}
    </Stack>
  );
};

interface SubItemProps {
  label: string;
  amount: number;
}

const SubItem: FC<SubItemProps> = ({ label, amount }) => {
  return (
    <Typography variant="subtitle2" fontSize={14}>
      {label}:{' '}
      <Typography
        display="inline"
        fontSize={14}
        variant="subtitle2"
        fontWeight={700}
      >
        {amount}
      </Typography>
    </Typography>
  );
};
