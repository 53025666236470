/// <reference types="vite-plugin-svgr/client" />
import { Link as RouterLink } from 'react-router-dom';
// @mui
import type { BoxProps } from '@mui/material';

import { ReactComponent as SvgLogo } from '../assets/PflegenaviLogo.svg';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export function Logo({ disabledLink = false, sx }: Props): JSX.Element {
  const logo = <SvgLogo height={35} width={155} />;

  if (disabledLink) {
    return logo;
  }

  return (
    <RouterLink to="/" style={{ height: 35 }}>
      {logo}
    </RouterLink>
  );
}
