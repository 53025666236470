import { ChangeCashMode } from '../model';
import type { Gender } from '@pflegenavi/shared/api';
import type { FC } from 'react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormatting } from '@pflegenavi/frontend/localization';
import {
  Card,
  NameAvatar,
  Stack,
  Typography,
} from '@pflegenavi/web-components';
import { Divider } from '@mui/material';

interface ResidentPreviewCardProps {
  mode: ChangeCashMode;
  total: number;
  resident: {
    name: string;
    balanceInCents: number;
    gender: Gender;
  };
}

export const ResidentPreviewCard: FC<ResidentPreviewCardProps> = ({
  mode,
  total,
  resident,
}) => {
  const { t } = useTranslation();
  const { fCurrencyCents } = useFormatting();

  const GREY = 'grey.400';

  const getColour = useCallback(
    (amount: number): string => {
      if (amount === 0) {
        return GREY;
      }

      if (mode === ChangeCashMode.Adjust) {
        return amount < 0 ? 'error.main' : 'success.main';
      }
      return mode === ChangeCashMode.Withdraw ? 'error.main' : 'success.main';
    },
    [mode]
  );

  return (
    <Card
      sx={{
        p: 2,
      }}
    >
      <Stack direction="column" gap={1}>
        <Stack direction="row" gap={1} alignItems="center">
          <NameAvatar name={resident.name} gender={resident.gender} />
          <Typography variant="subtitle2">{resident.name}</Typography>
        </Stack>
        <Divider />
        <Stack
          direction="row"
          spacing={8}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2" color="grey">
            {`${t('cashManagement.old-account-balance')}:`}
          </Typography>
          <Typography variant="subtitle2" color="grey">
            {fCurrencyCents(resident.balanceInCents)}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={8}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2">
            {`${
              mode === ChangeCashMode.Withdraw
                ? t('cashManagement.resident-withdrawal-amount')
                : t('cashManagement.resident-deposit-amount')
            }:`}
          </Typography>
          <Typography variant="subtitle2" color={getColour(total)}>
            {fCurrencyCents(total)}
          </Typography>
        </Stack>
        <Divider />
        <Stack
          direction="row"
          spacing={8}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2">
            {`${t('cashManagement.new-account-balance')}:`}
          </Typography>
          <Typography variant="subtitle2" fontWeight={700}>
            {fCurrencyCents(resident.balanceInCents + total)}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};
