import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ChangeCashStepper } from './ChangeCashStepper';
import {
  Card,
  Iconify,
  Stack,
  useErrorSnackbar,
} from '@pflegenavi/web-components';
import { ChangeCashMode } from './model';
import type { Coin, Gender } from '@pflegenavi/shared/api';
import {
  CASH_TRANSACTION_BANK_ACCOUNT_AMOUNT_OR_COINS_MUST_BE_SET,
  CashListStorageType,
  CashManagementTransactionType,
  FeatureFlag,
} from '@pflegenavi/shared/api';
import { computeCashStateDelta, euro } from '../euro';
import { Divider, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BankAccountDepositOrWithdrawForm } from './BankAccountForm';
import { CashSelectionForm } from './CashSelectionForm';
import { useChangeCashForm } from './useChangeCashForm';
import { ChangeCashFormConfirmation } from './ChangeCashFormConfirmation';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';
import {
  useCashListConfiguration,
  useCashManagementAddTransaction,
  useResident,
} from '@pflegenavi/frontend/api-nursing-home';
import { enqueueSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import { PayoutsFormStep1 } from './PayoutsFormStep1';
import { AdjustCashFormStep1 } from './AdjustCashFormStep1';
import { ChangeCashFormStep1 } from './ChangeCashFormStep1';
import { TransferForm } from './transfer/TransferForm';
import { useFeatureFlagEnabled } from '@pflegenavi/frontend/feature-flags';
import { CashListDropdown } from '../CashListDropdown';

export enum BankOrCash {
  Bank = 'Bank',
  Cash = 'Cash',
  Adjustment = 'Adjustment',
}

export interface InitialValues {
  /**
   * The amount that will be used to auto select coins. If quick selection is enabled, this will be the target amount instead.
   * The amount should be positive
   */
  amount?: number;

  step?: number;
  mode?: ChangeCashMode;
  isStripeTransfer?: boolean;

  /**
   * Allow to explicity set the bankAmount or coins.
   */
  coins?: Coin[];
  bankAccountAmount?: number;
  bankOrCash?: BankOrCash;
}

interface ChangeCashFormProps {
  /**
   * Force a specific cash list (e.g. when performing operations on the cash transaction group,
   * the user cannot select different cash lists.
   */
  forceCashListId: string | undefined;
  handleClose: VoidFunction;
  initialValues?: InitialValues;
  residentId?: string;
  residentData?: {
    name: string;
    balanceInCents: number;
    gender: Gender;
  };
  accountingPaymentsFeatureFlag: boolean;
  onConfirm?: OnConfirmCallback;
  requireNote?: boolean;
  requiredAmount?: number; // If set, this amount must be set in the form
  onSkip?: OnSkippedCallback;
  onConfirmOnSecondStep?: OnConfirmOnSecondStepCallback;
}

// eslint-disable-next-line complexity
export const ChangeCashForm: FC<ChangeCashFormProps> = ({
  handleClose,
  residentId,
  residentData,
  initialValues,
  accountingPaymentsFeatureFlag,
  onConfirm,
  requireNote,
  onSkip,
  onConfirmOnSecondStep,
  forceCashListId,
}) => {
  const { t } = useTranslation();

  const { enqueueErrorSnackbar } = useErrorSnackbar();

  const { pathname } = useLocation();

  // Prefetch coin and bank note images
  useEffect(() => {
    const imagesArray = Object.values(euro);
    imagesArray.forEach(function (src) {
      const img = new Image();
      img.src = src;
    });
  }, []);

  const {
    cashState,
    bankOrCash,
    allowSkipCashSelection,
    setSkippedCashSelection,
    bankAccountChange,
    setBankAccountChange,
    setMode,
    setIsStripeTransfer,
    isStripeTransfer,
    step,
    setStep,
    setCashState,
    mode,
    setBankOrCash,
    skippedCashSelection,
    initialAmount,
    selectedCashListId,
    setSelectedCashListId,
    bankNotes,
    coins,
    isCoinsAndBankNotesLoading,
    bankAccountAmount,
    storageType,
  } = useChangeCashForm({
    onSkip,
    initialValues,
    forceCashListId,
  });

  const cashListConfiguration = useCashListConfiguration();

  const nextStep = useCallback(() => {
    setStep((step) => step + 1);
  }, [setStep]);
  const previousStep = useCallback(() => {
    setStep((step) => step - 1);
    setSkippedCashSelection(false);
  }, [setStep, setSkippedCashSelection]);

  // eslint-disable-next-line complexity
  const cardWidth = useMemo(() => {
    if (isStripeTransfer) {
      return 1200;
    }
    if (step === 0) {
      return 768;
    }
    if (step === 1) {
      if (
        bankOrCash === BankOrCash.Cash ||
        (bankOrCash === BankOrCash.Adjustment &&
          storageType & CashListStorageType.Cash)
      ) {
        return 1200;
      } else {
        return 680;
      }
    }

    return 850;
  }, [bankOrCash, isStripeTransfer, step, storageType]);

  const { selectedNursingHome } = useNursingHomeContext();
  const { mutateAsync: addCashTransaction } = useCashManagementAddTransaction({
    onBeforeInvalidateCashList: handleClose,
    nursingHomeId: selectedNursingHome?.id,
  });
  const { data: resident } = useResident(residentId ?? '', {
    enabled: !!residentId,
  });

  const [notes, setNotes] = useState('');

  const cashTransactionType = useMemo(() => {
    if (mode === ChangeCashMode.Adjust) {
      return CashManagementTransactionType.Adjustment;
    }
    if (mode === ChangeCashMode.Withdraw) {
      return CashManagementTransactionType.Withdrawal;
    }
    if (isStripeTransfer && mode === ChangeCashMode.Deposit) {
      return CashManagementTransactionType.StripeTransfer;
    }
    return CashManagementTransactionType.Deposit;
  }, [isStripeTransfer, mode]);

  const cashStateForConfirmation = useMemo(() => {
    return mode === ChangeCashMode.Adjust
      ? computeCashStateDelta(cashState, (bankNotes ?? []).concat(coins ?? []))
      : cashState;
  }, [cashState, bankNotes, coins, mode]);

  const coinsToBeSubmitted = useMemo(() => {
    return Object.keys(cashStateForConfirmation).map((factorString) => {
      const factor = Number(factorString);
      return {
        factor: factor,
        amount:
          cashStateForConfirmation[factor] *
          (mode === ChangeCashMode.Withdraw ? -1 : 1),
      };
    });
  }, [cashStateForConfirmation, mode]);

  const bankAccountChangeInCentsToBeSubmitted = useMemo(() => {
    return bankAccountChange
      ? bankAccountChange * (mode === ChangeCashMode.Withdraw ? -1 : 1)
      : undefined;
  }, [bankAccountChange, mode]);

  // eslint-disable-next-line complexity
  const confirm = useCallback(async () => {
    if (selectedNursingHome === undefined) {
      throw new Error('selectedNursingHome is undefined');
    }

    try {
      if (onConfirm) {
        await onConfirm({
          type: cashTransactionType,
          coins: coinsToBeSubmitted,
          resident_id: residentId,
          notes: notes.length > 0 ? notes : undefined,
          bankAccountAmount: bankAccountChangeInCentsToBeSubmitted,
          mode,
          cashListId: selectedCashListId,
        });
        handleClose();
      } else {
        await addCashTransaction({
          cashListId: selectedCashListId,
          type: cashTransactionType,
          coins: coinsToBeSubmitted,
          resident_id: residentId,
          notes: notes.length > 0 ? notes : undefined,
          bankAccountAmount: bankAccountChangeInCentsToBeSubmitted,
        });
        // addCashTransaction is setup to call handleClose on success
        // handleClose();
        enqueueSnackbar(t('cashManagement.cash-change-success'), {
          variant: 'success',
        });
      }
    } catch (e) {
      if (
        e instanceof Error &&
        e.message.includes(
          CASH_TRANSACTION_BANK_ACCOUNT_AMOUNT_OR_COINS_MUST_BE_SET
        )
      ) {
        enqueueErrorSnackbar(
          e,
          t('cashManagement.bank-account-amount-or-coins-required-error'),
          {
            variant: 'error',
          }
        );
      } else {
        enqueueErrorSnackbar(e, t('errors.something-went-wrong'), {
          variant: 'error',
        });
      }
    }
  }, [
    selectedNursingHome,
    onConfirm,
    cashTransactionType,
    coinsToBeSubmitted,
    residentId,
    notes,
    bankAccountChangeInCentsToBeSubmitted,
    mode,
    selectedCashListId,
    handleClose,
    addCashTransaction,
    t,
    enqueueErrorSnackbar,
  ]);

  const skip = useCallback(async () => {
    if (onSkip === undefined) {
      throw new Error('onSkip is undefined');
    }

    try {
      await onSkip({
        amount: initialAmount ?? 0,
        notes,
        resident_id: residentId,
        mode,
      });

      handleClose();
    } catch (e) {
      enqueueSnackbar(t('errors.something-went-wrong'), {
        variant: 'error',
      });
    }
  }, [initialAmount, notes, residentId, handleClose, t, mode, onSkip]);

  const bankOnly = storageType === CashListStorageType.BankAccount;
  const hasAccountingPaymentsNew = useFeatureFlagEnabled(
    FeatureFlag.AccountingPaymentsNew
  );

  return (
    <Card
      sx={{
        width: cardWidth,
        p: 3,
        maxHeight: '100vh',
        overflowY: 'auto',
      }}
    >
      <Stack gap={1}>
        <IconButton
          name={'changeCashModalClose'}
          onClick={handleClose}
          sx={{
            padding: '16px',
            position: 'absolute',
            right: 16,
            top: 16,
          }}
        >
          <Iconify
            icon={'eva:close-fill'}
            sx={{
              position: 'absolute',
            }}
            width={20}
          />
        </IconButton>
        <Stack
          direction="row"
          sx={{
            width: '100%',
            ml: bankOnly ? 0 : 3,
            mb: bankOnly ? 3 : 0,
            alignItems: 'center',
          }}
          gap={bankOrCash ? 0 : 3}
        >
          {step === 1 &&
            !isStripeTransfer &&
            !forceCashListId &&
            hasAccountingPaymentsNew &&
            cashListConfiguration.cashLists.length > 1 && (
              <Stack sx={{ flex: 0 }} gap={1} mr={bankOnly ? 3 : 0}>
                <Typography variant="subtitle2">Kontoauswahl</Typography>
                <CashListDropdown
                  label={t('cashManagement.select-cash-list')}
                  size="small"
                  selectedCashListId={selectedCashListId}
                  setSelectedCashListId={setSelectedCashListId}
                />
              </Stack>
            )}
          <ChangeCashStepper activeStep={step} />
        </Stack>

        {step === 0 && (
          <>
            <ChangeCashFormStep1 setMode={setMode} nextStep={nextStep} />
            <Divider sx={{ borderStyle: 'dashed' }} />
            <AdjustCashFormStep1 setMode={setMode} nextStep={nextStep} />

            {hasAccountingPaymentsNew &&
              cashListConfiguration.cashLists.length > 1 && (
                <>
                  <Divider sx={{ borderStyle: 'dashed' }} />
                  <PayoutsFormStep1
                    setMode={setMode}
                    setIsStripeTransfer={setIsStripeTransfer}
                    nextStep={nextStep}
                  />
                </>
              )}
          </>
        )}

        {(step === 1 || step === 2) && isStripeTransfer && (
          <TransferForm
            step={step}
            onNextStep={nextStep}
            onCancel={handleClose}
            onPrevStep={previousStep}
          />
        )}
        {step === 1 && !isStripeTransfer ? (
          bankOrCash === BankOrCash.Cash ||
          bankOrCash === BankOrCash.Adjustment ? (
            <CashSelectionForm
              handleClose={handleClose}
              nextStep={() => {
                if (onConfirmOnSecondStep) {
                  onConfirmOnSecondStep({
                    coins: coinsToBeSubmitted,
                    bankAccountAmount: bankAccountChangeInCentsToBeSubmitted,
                    cashListId: selectedCashListId,
                  });
                  handleClose();
                } else {
                  nextStep();
                }
              }}
              mode={mode}
              setCashState={setCashState}
              cashState={cashState}
              coins={coins}
              bankNotes={bankNotes}
              isStripeTransfer={isStripeTransfer}
              setBankOrCash={setBankOrCash}
              bankAccountChange={bankAccountChange}
              setBankAccountChange={setBankAccountChange}
              requiredAmount={initialAmount}
              allowSkipCashSelection={allowSkipCashSelection}
              setSkippedCashSelection={setSkippedCashSelection}
              cashListId={selectedCashListId}
              storageType={storageType}
            />
          ) : (
            <BankAccountDepositOrWithdrawForm
              handleClose={handleClose}
              nextStep={() => {
                if (onConfirmOnSecondStep) {
                  onConfirmOnSecondStep({
                    coins: coinsToBeSubmitted,
                    bankAccountAmount: bankAccountChangeInCentsToBeSubmitted,
                    cashListId: selectedCashListId,
                  });
                  handleClose();
                } else {
                  nextStep();
                }
              }}
              mode={mode}
              setBankOrCash={setBankOrCash}
              bankAccountChange={bankAccountChange}
              setBankAccountChange={setBankAccountChange}
              requiredAmount={initialAmount === 0 ? undefined : initialAmount}
              allowSkipCashSelection={allowSkipCashSelection}
              setSkippedCashSelection={setSkippedCashSelection}
              cashListId={selectedCashListId}
              storageType={storageType}
            />
          )
        ) : null}

        {step >= 2 && !isStripeTransfer && (
          <ChangeCashFormConfirmation
            cashState={
              mode === ChangeCashMode.Adjust
                ? computeCashStateDelta(
                    cashState,
                    (bankNotes ?? []).concat(coins ?? [])
                  )
                : cashState
            }
            mode={mode}
            handleClose={handleClose}
            previousStep={previousStep}
            residentData={resident || residentData}
            requireNote={requireNote}
            bankOrCash={bankOrCash}
            bankAccountChange={bankAccountChange}
            skippedCashSelection={skippedCashSelection}
            skip={skip}
            confirm={confirm}
            skippedAmount={initialAmount}
            totalBankAccountBalance={bankAccountAmount}
            totalBankAccountBalanceLoading={isCoinsAndBankNotesLoading}
            notes={notes}
            setNotes={setNotes}
            showResidentPreview={pathname.includes('/residents')}
          />
        )}
      </Stack>
    </Card>
  );
};

export type OnConfirmCallback = (opts: {
  /**
   * Indicate the cash list this cash transaction should be applied on.
   * The user can select the cash list, if they have multiple.
   */
  cashListId: string;
  type: CashManagementTransactionType;
  coins: Coin[];
  resident_id?: string;
  notes?: string;
  bankAccountAmount?: number;
  mode?: ChangeCashMode;
}) => Promise<void>;

export type OnConfirmOnSecondStepCallback = (opts: {
  /**
   * Indicate the cash list this cash transaction should be applied on.
   * The user can select the cash list, if they have multiple.
   */
  cashListId: string;
  coins: Coin[];
  bankAccountAmount?: number;
}) => void;

export type OnSkippedCallback = (opts: {
  amount: number;
  notes?: string;
  resident_id?: string;
  mode?: ChangeCashMode;
}) => Promise<void>;
