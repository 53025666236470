import React, { Suspense, useEffect } from 'react';
import { DashboardPage } from '@pflegenavi/frontend/pages/dashboard';
import {
  recurringItemRoutes,
  residentRoutes,
} from '@pflegenavi/frontend/pages/residents';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';

import * as Sentry from '@sentry/react';

import { Navigate, Route, useLocation, useNavigate } from 'react-router-dom';
import {
  IncompleteTransactionsProvider,
  receiptRoutes,
  useReceiptBatchRoutes,
} from '@pflegenavi/frontend/pages/transactions';
import { DashboardLayout } from '@pflegenavi/frontend/layout';
import { PageNotFound, Text } from '@pflegenavi/web-components';
import {
  DevLangSwitcher,
  localeStorage,
} from '@pflegenavi/frontend/localization-web-app';
import { useAuthentication } from '@pflegenavi/frontend/authentication';
import { Box, CircularProgress, styled } from '@mui/material';
import { experimental_sx as sx } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useCashManagementRoutes } from '@pflegenavi/frontend/pages/cash-management';
import { reportingRoutes } from '@pflegenavi/frontend/pages/reporting';
import { useNursingHomeAccountingRoutes } from '@pflegenavi/frontend/pages/accountingPayments';
import { useNursingHomeRoutes } from '@pflegenavi/frontend/pages/nursing-home';
import {
  useEmployeeProfile,
  useUserProfileApi,
} from '@pflegenavi/frontend/api-nursing-home';
import { useFeatureFlagModal } from '@pflegenavi/frontend/feature-flags-web';
import { useTenantId } from '@pflegenavi/frontend/tenant';
import { TestingModal } from '@pflegenavi/frontend/pages/testing';
import { DevPage } from './DevPage';
import { useSyncLocaleWithServer } from '@pflegenavi/shared/frontend';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';
import { SelectNursingHome } from './components/SelectNursingHome';
import { InitNursingHomePage } from './components/InitNursingHomePage';
import { FeatureFlag } from '@pflegenavi/shared/api';
import { MenuItems } from './menu/MenuItems';
import { serviceProviderRoutes } from '@pflegenavi/frontend/pages/service-provider';
import { renderSwitchLanguageMenuItem } from './components/SwitchLanguageMenuItem';
import { SentryRoutes } from '../sentry';
import { useFeatureFlag } from '@pflegenavi/frontend/feature-flags';
import {
  Login,
  RedirectResetPassword,
} from '@pflegenavi/shared-web/authentication';
import { shouldUseNewLogin } from '@pflegenavi/shared-frontend/authentication';

// eslint-disable-next-line complexity
export default function App(): JSX.Element {
  const { t } = useTranslation();
  const { user } = useAuthentication();
  const navigate = useNavigate();
  const location = useLocation();
  const tenant = useTenantId();

  const api = useUserProfileApi(true);

  const { data: userProfile } = useEmployeeProfile({
    enabled: api !== undefined,
    refetchOnWindowFocus: false,
  });

  const { setNursingHomeList, selectedNursingHome } = useNursingHomeContext();

  useEffect(() => {
    if (userProfile?.nursingHomes) {
      setNursingHomeList(userProfile.nursingHomes);
    }
  }, [setNursingHomeList, userProfile]);

  const accountingRoutes = useNursingHomeAccountingRoutes();
  const receiptBatchRoutes = useReceiptBatchRoutes();
  const cashManagementRoutes = useCashManagementRoutes();
  const nursingHomeRoutes = useNursingHomeRoutes();

  useSyncLocaleWithServer({ localeStorage });

  useSetupSentryScopeWithNursingHome();

  const addFamilyMemberFeatureFlag = useFeatureFlag(
    FeatureFlag.AddFamilyMember
  );
  const importFamilyMembersFeatureFlag = useFeatureFlag(
    FeatureFlag.ImportFamilyMembers
  );

  const serviceProviderAppFeatureFlag = useFeatureFlag(
    FeatureFlag.dev_ServiceProviderApp
  );

  const { FeatureFlagModalComponent } = useFeatureFlagModal();

  // eslint-disable-next-line complexity
  useEffect(() => {
    // @ts-expect-error Beacon is untyped
    if (!window.Beacon || !userProfile || !userProfile.user) {
      return;
    }

    // @ts-expect-error Beacon is untyped
    window.Beacon('identify', {
      name: `${userProfile.user.firstName} ${userProfile.user.lastName}`,
      email: userProfile.user.eMail,
      'nursing-home': selectedNursingHome?.name ?? 'Unknown',
      tenant,
      'user-id': userProfile.user.id,
      environment: window.location.hostname.startsWith('localhost')
        ? 'local'
        : tenant === 'default_tenant' || tenant === 'other_tenant'
        ? 'staging'
        : 'production',
      application: 'Nursing home web',
    });
  }, [userProfile, selectedNursingHome, tenant]);

  useEffect(() => {
    // @ts-expect-error Beacon is untyped
    if (!window.Beacon) {
      return;
    }

    // @ts-expect-error Beacon is untyped
    window.Beacon('event', {
      type: 'page-viewed',
      url: location.pathname,
      title: document.title,
    });
    // @ts-expect-error Beacon is untyped
    window.Beacon('suggest');
    if (location && location.pathname === '/') {
      // @ts-expect-error Beacon is untyped
      window.Beacon('navigate', '/');
    }
  }, [location.pathname, location]);

  if (!user || !userProfile) {
    if (shouldUseNewLogin()) {
      return <Login />;
    }

    return (
      <LoadingContainer>
        <CircularProgress variant="indeterminate" />
      </LoadingContainer>
    );
  }

  return (
    <Suspense
      fallback={
        <Text color="primary" variant="h2">
          {t('')}
        </Text>
      }
    >
      {!import.meta.env.PROD && <DevLangSwitcher />}
      {FeatureFlagModalComponent}
      <IncompleteTransactionsProvider>
        <>
          <TestingModal />
          <SentryRoutes>
            <Route
              path={nhAppMainPages.WELCOME}
              element={<Navigate to={nhAppMainPages.HOME} replace />}
            />
            <Route
              path={'/reset-password/:token'}
              element={<RedirectResetPassword />}
            />
            <Route
              path={nhAppMainPages.HOME}
              element={
                <DashboardLayout
                  UnderLogoComponent={<SelectNursingHome />}
                  configKey="nh-web"
                  home={nhAppMainPages.HOME}
                  menuItems={<MenuItems />}
                  helpUrl={'https://pn.helpscoutdocs.com/'}
                  renderSwitchLanguageMenuItem={renderSwitchLanguageMenuItem}
                />
              }
            >
              <Route
                path={nhAppMainPages.DASHBOARD}
                element={<DashboardPage />}
              />
              <Route path={nhAppMainPages.DEV_PAGE} element={<DevPage />} />
              {residentRoutes(
                addFamilyMemberFeatureFlag,
                importFamilyMembersFeatureFlag,
                serviceProviderAppFeatureFlag
              )}
              {recurringItemRoutes()}
              {serviceProviderRoutes()}
              {receiptRoutes()}
              <Route
                path={nhAppMainPages.HOME}
                element={<Navigate to={nhAppMainPages.DASHBOARD} replace />}
              />
              {receiptBatchRoutes}
              {cashManagementRoutes}
              {accountingRoutes}
              {reportingRoutes()}
              {selectedNursingHome?.isSupervisor && nursingHomeRoutes}
              <Route
                path={nhAppMainPages.NURSING_HOME_SELECT}
                element={<InitNursingHomePage />}
              />
              <Route
                path="*"
                element={
                  <PageNotFound
                    handleGoHomeClick={() => navigate(nhAppMainPages.HOME)}
                  />
                }
              />
            </Route>
          </SentryRoutes>
        </>
      </IncompleteTransactionsProvider>
    </Suspense>
  );
}

const useSetupSentryScopeWithNursingHome = () => {
  const { selectedNursingHome } = useNursingHomeContext();
  const { user } = useAuthentication();

  useEffect(() => {
    if (!selectedNursingHome || !user) {
      return;
    }

    Sentry.configureScope((scope) => {
      scope.setUser(
        user
          ? {
              id: user?.userId,
            }
          : null
      );
      scope.setTags({
        nursingHomeId: selectedNursingHome.id,
        nursingHomeName: selectedNursingHome.name,
      });
    });
  }, [selectedNursingHome, user]);
};

const LoadingContainer = styled(Box)(
  sx({
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  })
);
