import { Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';

export const TestEnvironmentBadge: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        position: 'fixed',
        top: 0,
        width: '100%',
        borderTop: '2px solid',
        borderColor: theme.palette.warning.main,
        zIndex: 9999,
      }}
    >
      <Typography
        sx={{
          backgroundColor: theme.palette.warning.main,
          px: 1,
          textAlign: 'center',
          width: 'fit-content',
          alignSelf: 'center',
          borderRadius: '0 0 4px 4px',
          height: 18,
        }}
        variant="caption"
        color={theme.palette.warning.lighter}
      >
        {t('common.test-environment')}
      </Typography>
    </Stack>
  );
};
