import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormatting } from '@pflegenavi/frontend/localization';
import { Stack, Typography } from '@pflegenavi/web-components';
import { ChangeCashMode } from './model';

interface TotalRowProps {
  sum: number;
  initialSum: number;
  mode: ChangeCashMode;
}

// eslint-disable-next-line complexity
export const TotalRow: FC<TotalRowProps> = ({ sum, initialSum, mode }) => {
  const { t } = useTranslation();
  const { fCurrencyCents } = useFormatting();
  const isModeWithdraw = mode === ChangeCashMode.Withdraw;
  const isModeAdjust = mode === ChangeCashMode.Adjust;
  const isDifferenceNegative = sum - initialSum < 0;

  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="subtitle1" fontWeight={700}>
        {t('cashManagement.total')}
      </Typography>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Typography
          variant="subtitle1"
          fontWeight={700}
          color={
            isModeAdjust
              ? 'primary'
              : isModeWithdraw
              ? 'error.main'
              : 'success.main'
          }
        >
          {fCurrencyCents(sum)}
        </Typography>
        {isModeAdjust && (
          <Typography
            fontSize={13}
            fontWeight={700}
            color={isDifferenceNegative ? 'error.main' : 'success.main'}
            sx={{
              width: 102,
              textAlign: 'right',
            }}
          >
            {`${isDifferenceNegative ? '' : '+'}${fCurrencyCents(
              sum - initialSum
            )}`}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
