import type { StackProps } from '@mui/material';
import { Tooltip, useTheme } from '@mui/material';
import { CircularProgress, Stack, styled, Typography } from '@mui/material';
import type { FC, SVGProps } from 'react';
import React from 'react';
import { useFormatting } from '@pflegenavi/frontend/localization';
import { experimental_sx as sx } from '@mui/system';
import UpdateIcon from '@mui/icons-material/Update';

// ----------------------------------------------------------------------

interface Props extends StackProps {
  subAmount?: number;
  subAmountDetails?: React.ReactNode;
  title: string;
  total: number;
  percent: number;
  amount: number;
  color?: string;
  Icon: React.ComponentType<SVGProps<SVGSVGElement>>;
}

export const TotalAnalytic: FC<Props> = ({
  title,
  subAmount,
  subAmountDetails,
  total,
  color,
  percent,
  amount,
  Icon,
  ...props
}) => {
  const { fCurrency, fCurrencyCents } = useFormatting();
  const theme = useTheme();
  return (
    <TotalContainer
      direction="row"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ position: 'relative' }}
      >
        <Icon
          color={color}
          width={24}
          height={24}
          style={{ position: 'absolute' }}
        />

        <CircularProgress
          variant="determinate"
          value={percent}
          size={56}
          thickness={4}
          sx={{ color, opacity: 0.48 }}
        />

        <CircularProgress
          variant="determinate"
          value={100}
          size={56}
          thickness={4}
          sx={{
            color: 'grey.50016',
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0.48,
          }}
        />
      </Stack>

      <Stack sx={{ ml: 2 }}>
        <Typography variant="h6">{title}</Typography>
        <Stack direction="column">
          <Typography variant="subtitle2" sx={{ color }}>
            {fCurrency(amount)}
          </Typography>
          {subAmount && (
            <Tooltip
              title={subAmountDetails}
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    color: theme.palette.primary.main,
                    bgcolor: theme.palette.info.lighter,
                    p: 2,
                    '& .MuiTooltip-arrow': {
                      color: theme.palette.info.lighter,
                    },
                  },
                },
              }}
            >
              <Stack direction="row" alignItems="center" gap={0.5}>
                <UpdateIcon
                  sx={{
                    height: 14,
                    width: 14,
                    color: theme.palette.error.main,
                  }}
                />

                <Typography
                  variant="caption"
                  sx={{ color: theme.palette.error.main }}
                >
                  {fCurrencyCents(subAmount)}
                </Typography>
              </Stack>
            </Tooltip>
          )}
        </Stack>
      </Stack>
    </TotalContainer>
  );
};

const TotalContainer = styled(Stack)(
  sx({
    width: 1,
    minWidth: 200,
    padding: {
      xs: 2,
      lg: 0,
    },
  })
);
