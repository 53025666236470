import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberInputStateless, Stack } from '@pflegenavi/web-components';
import { Button } from '@mui/material';

interface BankAccountAdjustInputProps {
  bankAccountChange?: number;
  handleSetAmount: (mode: 'Deposit' | 'Withdrawal', amount: number) => void;
  showForm: boolean;
}

export const BankAccountAdjustInput: FC<BankAccountAdjustInputProps> = ({
  bankAccountChange,
  handleSetAmount,
  showForm,
}) => {
  const { t } = useTranslation();
  const [localeAmount, setLocaleAmount] = useState(bankAccountChange);

  const onWithdraw = useCallback(() => {
    handleSetAmount('Withdrawal', localeAmount ?? 0);
  }, [localeAmount, handleSetAmount]);

  const onDeposit = useCallback(() => {
    handleSetAmount('Deposit', localeAmount ?? 0);
  }, [localeAmount, handleSetAmount]);

  const buttonsDisabled = localeAmount === 0 || localeAmount === undefined;
  return (
    <Stack
      className={'bankAccountAdjustInput'}
      gap={1}
      sx={{
        mt: 2,
      }}
    >
      <NumberInputStateless
        label={t('common.amount')}
        value={localeAmount === undefined ? '' : Math.abs(localeAmount / 100)}
        onChange={(e) => {
          if (e.target.value === '') {
            setLocaleAmount(undefined);
            return;
          }

          if (Number(e.target.value) < 1000000) {
            setLocaleAmount(Math.round(Number(e.target.value) * 100));
          }
        }}
        InputLabelProps={{ shrink: true }}
        focused={showForm}
      />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
      >
        <Button
          variant="contained"
          color="error"
          disabled={buttonsDisabled}
          onClick={onWithdraw}
        >
          {t('cashManagement.withdrawal')}
        </Button>
        <Button
          variant="contained"
          disabled={buttonsDisabled}
          onClick={onDeposit}
        >
          {t('cashManagement.deposit')}
        </Button>
      </Stack>
    </Stack>
  );
};
