import type { FC, PropsWithChildren, ReactNode } from 'react';
import { Stack, useTheme, Typography } from '@mui/material';
import { Iconify } from '@pflegenavi/web-components';

type Variant = 'success' | 'warning';

interface CustomAlertProps {
  variant: Variant;
}

export const CustomAlert: FC<PropsWithChildren<CustomAlertProps>> = ({
  variant,
  children,
}) => {
  const theme = useTheme();
  const backgroundColors: Record<Variant, string> = {
    success: theme.palette.success.lighter,
    warning: theme.palette.warning.lighter,
  };

  const textColor: Record<Variant, string> = {
    success: theme.palette.success.darker,
    warning: theme.palette.warning.darker,
  };

  const icons: Record<Variant, ReactNode> = {
    success: (
      <Iconify
        width={20}
        height={20}
        sx={{ mt: '1px' }}
        icon={'eva:checkmark-circle-2-fill'}
        color={theme.palette.success.main}
      />
    ),
    warning: (
      <Iconify
        width={20}
        height={20}
        sx={{ mt: '1px' }}
        icon={'eva:alert-triangle-fill'}
        color={theme.palette.warning.main}
      />
    ),
  };

  return (
    <Stack
      direction="row"
      sx={{ borderRadius: 2, p: 2, backgroundColor: backgroundColors[variant] }}
      gap={1}
    >
      <Stack alignItems="flex-start">{icons[variant]}</Stack>
      <Typography
        variant="body2"
        color={textColor[variant]}
        sx={{ maxWidth: 270 }}
      >
        {children}
      </Typography>
    </Stack>
  );
};
