import { useCallback, useEffect } from 'react';
import type { ImportFamilyMemberFormValues } from '../components/ImportFamilyMembersTable';
import { useClientStoredResourcesContext } from '@pflegenavi/frontend/client-stored-resources-context';
import _debounce from 'lodash/debounce';
import {
  DEBOUNCE_SAVE_FM_IMPORT_STATE_DELAY_MS,
  FM_IMPORT_STATE_LOCAL_STORAGE_KEY,
} from '@pflegenavi/shared/constants';

export function usePersistFMImportState(
  state: ImportFamilyMemberFormValues
): void {
  const clientStoredResources = useClientStoredResourcesContext();

  // register resource to be able to clear it on logout
  useEffect(() => {
    clientStoredResources.registerResource(FM_IMPORT_STATE_LOCAL_STORAGE_KEY);
  }, [clientStoredResources]);

  const saveState = useCallback(() => {
    localStorage.setItem(
      FM_IMPORT_STATE_LOCAL_STORAGE_KEY,
      JSON.stringify(state)
    );
  }, [state]);

  // eslint-disable-next-line @grncdr/react-hooks/exhaustive-deps
  const debouncedSaveState = useCallback(
    _debounce(saveState, DEBOUNCE_SAVE_FM_IMPORT_STATE_DELAY_MS),
    [saveState]
  );

  useEffect(() => {
    debouncedSaveState();
  }, [debouncedSaveState, saveState]);
}

export const loadFMImportStateFromLocalStorage =
  (): ImportFamilyMemberFormValues | null => {
    const state = localStorage.getItem(FM_IMPORT_STATE_LOCAL_STORAGE_KEY);
    if (!state) {
      return null;
    }
    return JSON.parse(state);
  };

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const clearFMImportStateFromLocalStorage = () => {
  localStorage.removeItem(FM_IMPORT_STATE_LOCAL_STORAGE_KEY);
};
