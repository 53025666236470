import type { FC } from 'react';
import React, { useMemo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  CardHeader,
  styled,
  Table,
  TableBody,
  TableCell as TableCellOriginal,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  Iconify,
  OverdueDaysLabel,
  TableEmptyRows,
  TableNoData,
} from '@pflegenavi/web-components';
import { differenceInDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { generatePath, nhAppMainPages } from '@pflegenavi/frontend/routing';
import { useNavigate } from 'react-router-dom';
import { useFormatting } from '@pflegenavi/frontend/localization';
import type { TabFilterValues } from '@pflegenavi/frontend/pages/residents';
import { useNavigateToResidentsPage } from '@pflegenavi/frontend/pages/residents';

const TableCell = styled(TableCellOriginal)`
  padding: 12px;
`;

// ----------------------------------------------------------------------

export interface Balance {
  resident: {
    id: string;
    name: string;
  };
  balance: number;
  overdueSince: Date;
}

export interface BalanceTableProps {
  data: Balance[];
  cardTitle: string;
  negative: boolean;
  navigateTo: TabFilterValues;
  dataCyPrefix: string;
  noDataImg: string;
  noDataText: string;
}

export const BalanceCard: FC<BalanceTableProps> = ({
  data,
  cardTitle,
  negative,
  navigateTo,
  dataCyPrefix,
  noDataImg,
  noDataText,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { fCurrency } = useFormatting();

  const { navigateToResidentPage } = useNavigateToResidentsPage();

  const handleViewAll = () => {
    navigateToResidentPage({
      selectedTab: navigateTo,
    });
  };

  const sortedData = useMemo(() => {
    return data.sort(sortBalanceOverdue);
  }, [data]);

  return (
    <Card
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: {
          xs: '100%',
          md: 500,
        },
      }}
      data-cy={dataCyPrefix}
    >
      <CardHeader
        title={cardTitle}
        sx={{ mb: 3 }}
        titleTypographyProps={{
          sx: { fontWeight: 600 },
        }}
      />
      <TableContainer
        sx={{
          maxWidth: '96%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('dashboard.table.resident')}</TableCell>
              <TableCell align="right">{t('dashboard.table.amount')}</TableCell>
              <TableCell align="center">
                {t('dashboard.table.daysOverdue')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length === 0 && (
              <TableNoData colSpan={3} isNotFound>
                <img
                  alt="empty content"
                  src={noDataImg}
                  style={{ marginBottom: 25 }}
                  height={180}
                  width={220}
                />
                <Typography variant="inherit" fontWeight="700" sx={{ mb: 2 }}>
                  {noDataText}
                </Typography>
              </TableNoData>
            )}

            {sortedData.map((row, index) => {
              const daysOverdue = differenceInDays(
                new Date(),
                row.overdueSince
              );
              const onClick = () => {
                navigate(
                  generatePath(nhAppMainPages.RESIDENT_DETAIL_ACCOUNT, {
                    resident_id: row.resident.id,
                  })
                );
              };
              return (
                <TableRow
                  hover
                  sx={{
                    borderBottomStyle: 'solid',
                    borderBottomColor: theme.palette.grey[500_24],
                    borderBottomWidth: 'thin',
                    cursor: 'pointer',
                  }}
                  key={index}
                  onClick={onClick}
                >
                  <TableCell align="left">
                    <Typography variant="subtitle2" noWrap>
                      {row.resident.name}
                    </Typography>
                  </TableCell>

                  <TableCell align="right">{fCurrency(row.balance)}</TableCell>

                  <TableCell align="center">
                    <OverdueDaysLabel
                      negative={negative}
                      daysOverdue={daysOverdue}
                    />
                  </TableCell>
                </TableRow>
              );
            })}

            {sortedData && sortedData.length > 0 && (
              <TableEmptyRows
                height={48}
                emptyRows={tableMaxRows - sortedData.length}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {data?.length > 0 && (
        <Box
          sx={{
            p: 2,
            textAlign: 'right',
            mt: 'auto',
          }}
        >
          <Button
            data-cy={`${dataCyPrefix}-view-all-button`}
            size="small"
            color="inherit"
            endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'} />}
            onClick={handleViewAll}
          >
            {t('dashboard.label.viewAll')}
          </Button>
        </Box>
      )}
    </Card>
  );
};

const sortBalanceOverdue = (a: Balance, b: Balance): number => {
  const dateA = new Date(a.overdueSince).getTime();
  const dateB = new Date(b.overdueSince).getTime();
  const balanceA = new Date(a.balance);
  const balanceB = new Date(b.balance);

  if (dateA < dateB) {
    return -1;
  } else if (dateB < dateA) {
    return 1;
  } else {
    if (balanceA < balanceB) {
      return -1;
    } else if (balanceB < balanceA) {
      return 1;
    } else {
      return 0;
    }
  }
};

const tableMaxRows = 5;
