interface CsvDownloadProps {
  data: any[];
  filename?: string;
  delimiter?: string;
  headers?: string[];
}

const CSV_FILE_TYPE = 'text/csv;charset=utf-8;';

export const csvDownload = (csvProps: CsvDownloadProps): void => {
  const formattedFilename = getFilename(csvProps.filename);

  const csvAsString = getCsvContent(csvProps);

  triggerCsvDownload(csvAsString, formattedFilename);
};

export const getCsvContent = ({
  data,
  headers,
  delimiter = ';',
}: Omit<CsvDownloadProps, 'filename'>): string => {
  if (data.length === 0) {
    return headers ? headers.join(delimiter) : '';
  }

  const headerKeys = Object.keys(data[0]);

  const columnNames = headers ?? headerKeys;
  const csv = data.map((row) =>
    headerKeys
      .map((fieldName) => formatCsvValue(row[fieldName]))
      .join(delimiter)
  );
  csv.unshift(columnNames.join(delimiter));
  return csv.join('\r\n');
};

const formatCsvValue = (value: any): string => {
  if (value === undefined || value === null) {
    return '';
  }

  // If a value is a number, return it as is
  if (typeof value === 'number') {
    return value.toString();
  }

  // If value is an object, return it as JSON
  if (typeof value === 'object') {
    return JSON.stringify(value);
  }

  // If value is a string, escape any double quotes inside it
  // wrap it in double quotes and remove any new lines
  return `"${value.toString().replace(/"/g, '""').replace(/\r?\n/g, ' ')}"`;
};

const triggerCsvDownload = (csvAsString: string, fileName: string) => {
  const blob = new Blob(['\ufeff' + csvAsString], {
    type: CSV_FILE_TYPE,
  });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const getFilename = (providedFilename?: string): string => {
  return providedFilename
    ? providedFilename.toLowerCase().endsWith('.csv')
      ? providedFilename
      : `${providedFilename}.csv`
    : 'export.csv';
};

export default csvDownload;
