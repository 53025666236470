import { Button } from '@mui/material';
import { Iconify } from '@pflegenavi/web-components';
import type { FC, PropsWithChildren } from 'react';
import React from 'react';

interface ResetButtonProps {
  reset: () => void;
  disabled?: boolean;
}

export const ResetButton: FC<PropsWithChildren<ResetButtonProps>> = ({
  reset,
  disabled,
  children,
}) => {
  return (
    <Button
      variant="text"
      color="error"
      startIcon={
        <Iconify
          icon="eva:trash-2-outline"
          style={{
            marginTop: -3, // Icon/text alignment
          }}
        />
      }
      onClick={reset}
      disabled={disabled}
      sx={{
        marginRight: -1,
        visibility: disabled ? 'hidden' : 'visible',
      }}
    >
      {children}
    </Button>
  );
};
