import { useCallback, useEffect, useState } from 'react';
import {
  isApiError,
  useNursingHomeDirectDebitStatus,
  usePaymentApi,
} from '@pflegenavi/frontend/api-nursing-home';
import { loadStripe } from '@stripe/stripe-js';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';

export enum MandateStatus {
  Idle,
  Loading,
  Success,
  Error,
}

interface UseSetupDirectDebitResult {
  error: string | undefined;
  mandateStatus: MandateStatus;
  confirm: (params: DirectDebitParams) => void;
}

interface DirectDebitParams {
  name: string;
  email: string;
  iban: string;
}

export const useSetupDirectDebit = (): UseSetupDirectDebitResult => {
  const api = usePaymentApi();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { selectedNursingHome } = useNursingHomeContext();

  const [mandateStatus, setMandateStatus] = useState(MandateStatus.Idle);
  const [error, setError] = useState<undefined | string>(undefined);

  const { refetch: refetchDirecDebit } = useNursingHomeDirectDebitStatus(
    selectedNursingHome?.id
  );

  useEffect(() => {
    setError(undefined);
    setMandateStatus(MandateStatus.Idle);
  }, []);

  const confirm = useCallback(
    ({ name, email, iban }: DirectDebitParams) => {
      // eslint-disable-next-line complexity
      async function setup({ name, email, iban }: DirectDebitParams) {
        try {
          if (!selectedNursingHome?.id) {
            setMandateStatus(MandateStatus.Error);
            return;
          }
          setMandateStatus(MandateStatus.Loading);
          const result = await api.setupDirectDebitForNursingHome(
            selectedNursingHome?.id,
            {
              name,
              email,
              iban,
            }
          );

          if (isApiError(result)) {
            setMandateStatus(MandateStatus.Error);
            setError(result.message);
            return;
          }

          const stripe = await loadStripe(result.publishableKey);

          if (stripe) {
            const { error } = await stripe.confirmSepaDebitSetup(
              result.paymentIntent,
              {
                payment_method: {
                  sepa_debit: { iban },
                  billing_details: {
                    name,
                    email,
                  },
                },
              }
            );
            if (!error) {
              await api.setupConfirmNursingHome({
                body: undefined,
                params: {
                  nursingHomeId: selectedNursingHome.id,
                  intentId: result.intentId,
                },
              });
              await refetchDirecDebit();
              setMandateStatus(MandateStatus.Success);
              enqueueSnackbar(
                t('mobile.family-member.mandate-activation-success'),
                {
                  variant: 'success',
                }
              );
            } else {
              setMandateStatus(MandateStatus.Error);
              enqueueSnackbar(
                t('mobile.family-member.mandate-activation-failure'),
                {
                  variant: 'error',
                }
              );
            }
          }
        } catch (err) {
          setMandateStatus(MandateStatus.Error);
        }
      }

      setup({
        name,
        email,
        iban,
      });
    },
    [api, enqueueSnackbar, selectedNursingHome?.id, refetchDirecDebit, t]
  );

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined;
    if (mandateStatus === MandateStatus.Success) {
      timeout = setTimeout(() => {
        setMandateStatus((status) => {
          if (status === MandateStatus.Success) {
            return MandateStatus.Idle;
          }
          return status;
        });
      }, 3000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [mandateStatus]);

  return {
    error,
    mandateStatus,
    confirm,
  };
};
