import type { FC } from 'react';
import React, { useMemo } from 'react';
import { GroupContainer } from './GroupContainer';
import { Title } from './Title';
import { Row } from './Row';
import { Typography, useTheme } from '@mui/material';
import { TitleWithSubtitleContainer } from './TitleWithSubtitleContainer';
import { RowDivider } from './RowDivider';
import { useTranslation } from 'react-i18next';
import { useFormatting } from '@pflegenavi/frontend/localization';

interface StripeProps {
  stripeAvailableAmount: number;
  stripeAvailableSoonAmount: number;
  stripePayoutAmount: number;
  stripeAmount: number;
  totalPendingReversals: number;
}

export const Stripe: FC<StripeProps> = ({
  stripeAvailableAmount,
  stripeAvailableSoonAmount,
  stripePayoutAmount,
  stripeAmount,
  totalPendingReversals,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { fCurrencyCents } = useFormatting();

  const { rowTextProps, rowSubTextProps, totalTextProps } = useMemo(() => {
    return {
      rowTextProps: {
        variant: 'subtitle2' as const,
        color: theme.palette.grey['600'],
      },
      rowSubTextProps: {
        variant: 'caption' as const,
        color: theme.palette.grey['600'],
      },
      totalTextProps: {
        variant: 'subtitle2' as const,
        fontWeight: 700,
      },
    };
  }, [theme.palette.grey]);

  return (
    <GroupContainer>
      <Title>{t('accounting.dashboard.stripe-title')}</Title>
      <Row>
        <Typography {...rowTextProps}>
          {t('accounting.dashboard.stripe-available-balance')}
        </Typography>
        <Typography {...rowTextProps}>
          {fCurrencyCents(stripeAvailableAmount)}
        </Typography>
      </Row>
      <Row>
        <Typography {...rowTextProps}>
          {`- ${t('accounting.dashboard.stripe-available-soon-balance')}`}
        </Typography>
        <Typography {...rowTextProps}>
          {fCurrencyCents(stripeAvailableSoonAmount)}
        </Typography>
      </Row>
      <Row>
        <Typography {...rowTextProps}>
          {`- ${t('accounting.dashboard.stripe-outgoing-payouts')}`}
        </Typography>
        <Typography {...rowTextProps}>
          {fCurrencyCents(stripePayoutAmount)}
        </Typography>
      </Row>
      {totalPendingReversals !== 0 && (
        <TitleWithSubtitleContainer>
          <Row>
            <Typography {...rowTextProps}>
              {`- ${t('accounting.dashboard.stripe-upcoming-reversals')}`}
            </Typography>
            <Typography {...rowTextProps}>
              {fCurrencyCents(totalPendingReversals)}
            </Typography>
          </Row>
          <Typography {...rowSubTextProps}>
            {t('accounting.dashboard.stripe-upcoming-reversals-description')}
          </Typography>
        </TitleWithSubtitleContainer>
      )}
      <RowDivider />
      <Row>
        <Typography {...totalTextProps} fontWeight={700}>
          {t('accounting.dashboard.total-stripe-balance')}
        </Typography>
        <Typography {...totalTextProps} fontWeight={700}>
          {fCurrencyCents(stripeAmount)}
        </Typography>
      </Row>
    </GroupContainer>
  );
};
