// Implement a component with nursing home accounting breadcrumbs

import type { ReactNode, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderBreadcrumbs } from '@pflegenavi/web-components';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';

interface NursingHomeAccountingBreadcrumbsProps {
  pageTitle: string;
  route: string;
  action?: ReactNode;
}

export const NursingHomeAccountingBreadcrumbs: VFC<
  NursingHomeAccountingBreadcrumbsProps
> = ({ pageTitle, route, action }) => {
  const { t } = useTranslation();
  return (
    <HeaderBreadcrumbs
      heading={t('accounting.page.title-new')}
      links={[
        {
          name: t('accounting.page.title-new'),
          href: nhAppMainPages.NURSING_HOME_ACCOUNTING,
        },
        {
          name: pageTitle,
          href: route,
        },
      ]}
      action={action}
    />
  );
};
