import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useCashListConfiguration } from '@pflegenavi/frontend/api-nursing-home';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function useSetQueryParam() {
  const navigate = useNavigate();
  return useCallback(
    (key: string, value: string) => {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set(key, value);
      navigate(`?${queryParams.toString()}`);
    },
    [navigate]
  );
}

export function useCashListTableQuery(): {
  cashListId: string | undefined;
  setCashListId: (cashListId: string) => void;
  cashLists: Array<{ id: string; name: string }>;
} {
  const cashListConfiguration = useCashListConfiguration();
  const cashListId =
    useQuery().get('cashListId') ?? cashListConfiguration.selectedCashListId;

  const setQueryParam = useSetQueryParam();
  const setSelectedCashListId = useCallback(
    (cashListId: string) => {
      setQueryParam('cashListId', cashListId);
    },
    [setQueryParam]
  );

  return {
    cashListId,
    setCashListId: setSelectedCashListId,
    cashLists: cashListConfiguration.cashLists,
  };
}
