import {
  Avatar,
  Badge,
  Card,
  CircularProgress,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import type { FC } from 'react';
import React, { useState } from 'react';
import type { GetCashTransactionGroupCashTransactions } from '@pflegenavi/shared/api';
import { useTranslation } from 'react-i18next';
import {
  DepositIcon,
  EuroIcon,
  WithdrawalIcon,
} from '../groupTable/TypeColumn';
import { Iconify, Label, TableMoreMenu } from '@pflegenavi/web-components';
import { DateIcon } from '../cashLink/CashLinkView';
import { TIME_FORMAT } from '@pflegenavi/shared/constants';
import {
  useFormatDate,
  useFormatting,
} from '@pflegenavi/frontend/localization';
import {
  pflegenaviBase64Logo,
  usePrintPdfWithdrawalWithoutResidentConfirmation,
} from '@pflegenavi/frontend/pdf-export';
import { enqueueSnackbar } from 'notistack';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';
import {
  useEmployeeProfile,
  useImage,
} from '@pflegenavi/frontend/api-nursing-home';
import { CoinsModal } from '../modals/CoinsModal';
import { useTrackStructuredEvent } from '@pflegenavi/frontend/tracking';
import { format } from 'date-fns';
import { makeSafeFilename } from '@pflegenavi/shared/utils';

interface CashTransactionCardProps
  extends GetCashTransactionGroupCashTransactions {
  showDownloadPdf: boolean;
}

// eslint-disable-next-line complexity
export const CashTransactionCard: FC<CashTransactionCardProps> = ({
  employee,
  date,
  amount,
  notes,
  showDownloadPdf,
  coinChange,
  bankAccountAmountChange,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { fCurrencyCents } = useFormatting();
  const fDate = useFormatDate();

  const { data: user } = useEmployeeProfile({});
  const { selectedNursingHome } = useNursingHomeContext();
  const { data: pdfLogo } = useImage(selectedNursingHome?.pdfLogoId);

  const [showWithdrawalPdfLoading, setShowWithdrawalPdfLoading] =
    useState(false);

  const { handlePrintPdfWithdrawalWithoutResidentConfirmation } =
    usePrintPdfWithdrawalWithoutResidentConfirmation();

  const showWithdrawalPdf = async () => {
    try {
      if (!selectedNursingHome) {
        throw new Error('No selectedNursingHome');
      }

      setShowWithdrawalPdfLoading(true);
      const pdfPromise = handlePrintPdfWithdrawalWithoutResidentConfirmation({
        withdrawalAmount: amount / 100,
        date: date,
        employee: user ? `${user.user.firstName} ${user.user.lastName}` : '',
        nursingHome: selectedNursingHome?.name,
        logo: pdfLogo ? pdfLogo.url : pflegenaviBase64Logo,
        notes: notes,
      });

      const minTime = new Promise((resolve) => setTimeout(resolve, 500));
      const [pdf] = await Promise.all([pdfPromise, minTime]);

      const dateString = format(date, 'yyyy-MM-dd');

      pdf.save(
        makeSafeFilename(
          t('transactions.withdrawal.pdfName-no-resident', {
            date: dateString,
          })
        )
      );
    } catch (error) {
      enqueueSnackbar(t('errors.something-went-wrong'), {
        variant: 'error',
      });
    } finally {
      setShowWithdrawalPdfLoading(false);
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [menuOpen, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <Card sx={{ p: 3 }}>
      <Stack gap={2}>
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" gap={2} alignItems="center">
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={amount >= 0 ? <DepositIcon /> : <WithdrawalIcon />}
            >
              <Avatar>
                <EuroIcon />
              </Avatar>
            </Badge>
            <Stack gap={0.5}>
              <Typography fontWeight={700} variant="subtitle2" lineHeight={1}>
                {employee?.firstName} {employee?.lastName}
              </Typography>
              <Typography
                variant="subtitle2"
                lineHeight={1}
                color="text.secondary"
              >
                {amount >= 0
                  ? t('cashManagement.deposit')
                  : t('cashManagement.withdrawal')}
              </Typography>
            </Stack>
          </Stack>
          <CardMenu
            handleCloseMenu={handleCloseMenu}
            handleOpenMenu={handleOpenMenu}
            menuOpen={menuOpen}
            handleModalClick={() => setModalOpen(true)}
            showDownloadPdf={showDownloadPdf}
            showWithdrawalPdf={showWithdrawalPdf}
            showWithdrawalPdfLoading={showWithdrawalPdfLoading}
          />
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center" gap={1}>
            <DateIcon />
            <Typography variant="subtitle2">
              {fDate(date, TIME_FORMAT)}
            </Typography>
          </Stack>

          <Label color={amount >= 0 ? 'success' : 'error'}>
            {fCurrencyCents(amount)}
          </Label>
        </Stack>
        <Notes notes={notes} />
      </Stack>

      <CoinsModal
        open={modalOpen}
        setOpen={setModalOpen}
        coins={coinChange}
        bankAccountAmount={bankAccountAmountChange ?? 0}
        amountColor={
          amount < 0
            ? 'error.main'
            : amount > 0
            ? 'success.main'
            : theme.palette.grey['400']
        }
        amountString={fCurrencyCents(amount)}
        selectedCashListName={undefined}
      />
    </Card>
  );
};

interface NotesProps {
  notes: string | null;
}

// Lorem ipsum with 255 characters
// Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget ultricies ultrices, nunc nisl ultricies nunc, quis aliqu

const Notes: FC<NotesProps> = ({ notes }) => {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{
        minHeight: 220,
        maxWidth: '100%',
        p: 2,
        backgroundColor: 'grey.200',
        borderRadius: 2,
      }}
    >
      <Typography variant="subtitle2" fontWeight={600} color="text.secondary">
        {t('cashManagement.group-detail.cash-transaction-card.notes-title')}
      </Typography>
      <Typography variant="subtitle2" color="grey.800">
        {notes ||
          t('cashManagement.group-detail.cash-transaction-card.no-notes')}
      </Typography>
    </Stack>
  );
};

interface CardMenuProps {
  handleCloseMenu: () => void;
  handleOpenMenu: (e: React.MouseEvent<HTMLElement>) => void;
  menuOpen: HTMLElement | null;

  showDownloadPdf: boolean;
  showWithdrawalPdf: () => Promise<void>;
  showWithdrawalPdfLoading: boolean;

  handleModalClick: () => void;
}

const CardMenu: React.FC<CardMenuProps> = ({
  handleCloseMenu,
  handleOpenMenu,
  menuOpen,
  handleModalClick,
  showWithdrawalPdf,
  showDownloadPdf,
  showWithdrawalPdfLoading,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const trackEvent = useTrackStructuredEvent('CashTransactionGroupDetails');

  return (
    <TableMoreMenu
      open={menuOpen}
      onOpen={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleOpenMenu(e);
      }}
      onClose={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleCloseMenu();
      }}
      actions={
        <>
          <MenuItem
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              void trackEvent({
                action: 'click',
                label: 'CashTransactionCardDetails',
              });
              handleModalClick();
              handleCloseMenu();
            }}
          >
            <Iconify icon={'eva:eye-fill'} />
            {t(
              'cashManagement.group-detail.cash-transaction-card.show-details'
            )}
          </MenuItem>
          <MenuItem
            disabled={!showDownloadPdf || showWithdrawalPdfLoading}
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              void trackEvent({
                action: 'click',
                label: 'CashTransactionCardDownloadReceipt',
              });
              await showWithdrawalPdf();
              handleCloseMenu();
            }}
          >
            {showWithdrawalPdfLoading ? (
              <CircularProgress size={18} sx={{ color: 'grey.400' }} />
            ) : (
              <Iconify
                icon="ph:download-simple-bold"
                color={theme.palette.primary.main}
              />
            )}
            {t(
              'cashManagement.group-detail.cash-transaction-card.download-pdf'
            )}
          </MenuItem>
        </>
      }
    />
  );
};
