import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { type FC, useCallback, useMemo, useState } from 'react';
import { Button, Card, Stack } from '@mui/material';
import {
  LoadingPromiseButtonAdvanced,
  Modal,
  ModalInner,
  Typography,
} from '@pflegenavi/web-components';
import type { ImportFamilyMemberFormValues } from './ImportFamilyMembersTable';

interface FMImportActionsButtonsProps {
  resetForm: () => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const FMImportActionsButtons = ({
  resetForm,
}: FMImportActionsButtonsProps) => {
  const { t } = useTranslation();
  const {
    values: { familyMembers },
    submitForm,
    isValid,
    isSubmitting,
  } = useFormikContext<ImportFamilyMemberFormValues>();

  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [resetModalOpen, setResetModalOpen] = useState(false);

  const openSubmitModal = useCallback(() => {
    setSubmitModalOpen(true);
  }, []);

  const closeSubmitModal = useCallback(() => {
    setSubmitModalOpen(false);
  }, []);

  const openResetModal = useCallback(() => {
    setResetModalOpen(true);
  }, []);

  const closeResetModal = useCallback(() => {
    setResetModalOpen(false);
  }, []);

  const handleResetForm = useCallback(() => {
    resetForm();
    closeResetModal();
  }, [closeResetModal, resetForm]);

  const emptyState = useMemo(() => {
    return (
      familyMembers.length === 1 &&
      familyMembers[0].residentId === '' &&
      familyMembers[0].firstName === '' &&
      familyMembers[0].lastName === '' &&
      familyMembers[0].email === ''
    );
  }, [familyMembers]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="ceenter"
        gap={1}
      >
        {!emptyState && (
          <Button variant="outlined" color="error" onClick={openResetModal}>
            {t('family-members-import.table.reset-form.reset')}
          </Button>
        )}
        <Button
          variant="contained"
          disabled={emptyState || !isValid}
          onClick={openSubmitModal}
        >
          {t('family-members-import.table.submit-and-import')}
        </Button>
      </Stack>

      <ResetFormModal
        open={resetModalOpen}
        handleClose={closeResetModal}
        resetForm={handleResetForm}
      />

      <SubmitAndImportModal
        open={submitModalOpen}
        handleClose={closeSubmitModal}
        handleSubmit={async () => {
          await submitForm();
          closeSubmitModal();
        }}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

interface SubmitAndImportModalProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  isSubmitting: boolean;
}

export const SubmitAndImportModal: FC<SubmitAndImportModalProps> = ({
  open,
  handleClose,
  handleSubmit,
  isSubmitting,
}) => {
  const { t } = useTranslation();

  const handleClickEscapeOrOutOfModal = useCallback(
    (event: unknown, reason: 'backdropClick' | 'escapeKeyDown') => {
      // do nothing
      return;
    },
    []
  );

  return (
    <Modal open={open} handleClose={handleClickEscapeOrOutOfModal}>
      <ModalInner>
        <Card
          sx={{
            width: 600,
            p: 2,
            maxHeight: '100vh',
            overflowY: 'auto',
          }}
        >
          <Stack gap={2}>
            <Typography variant="h6">
              {t('family-members-import.table.submit-import-modal.title')}
            </Typography>
            <Typography variant="subtitle2">
              {t('family-members-import.table.submit-import-modal.description')}
            </Typography>
            <Typography variant="subtitle2">
              {t(
                'family-members-import.table.submit-import-modal.invite-will-be-sent'
              )}
            </Typography>
            <Stack direction="row" justifyContent="flex-end" gap={1}>
              <Button
                variant="outlined"
                onClick={handleClose}
                disabled={isSubmitting}
              >
                {t('family-members-import.table.submit-import-modal.cancel')}
              </Button>
              <LoadingPromiseButtonAdvanced
                variant="contained"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {t('family-members-import.table.submit-import-modal.submit')}
              </LoadingPromiseButtonAdvanced>
            </Stack>
          </Stack>
        </Card>
      </ModalInner>
    </Modal>
  );
};

interface ResetFormModalProps {
  open: boolean;
  handleClose: () => void;
  resetForm: () => void;
}

export const ResetFormModal: FC<ResetFormModalProps> = ({
  open,
  handleClose,
  resetForm,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} handleClose={handleClose}>
      <ModalInner>
        <Card
          sx={{
            width: 600,
            p: 2,
            maxHeight: '100vh',
            overflowY: 'auto',
          }}
        >
          <Stack gap={2}>
            <Typography variant="h6">
              {t('family-members-import.table.reset-form.title')}
            </Typography>
            <Typography variant="subtitle2">
              {t('family-members-import.table.reset-form.description')}
            </Typography>

            <Stack direction="row" justifyContent="flex-end" gap={1}>
              <Button variant="outlined" onClick={handleClose}>
                {t('family-members-import.table.reset-form.cancel')}
              </Button>
              <Button variant="contained" onClick={resetForm}>
                {t('family-members-import.table.reset-form.reset')}
              </Button>
            </Stack>
          </Stack>
        </Card>
      </ModalInner>
    </Modal>
  );
};
