import { CircularProgress, Grid, Stack, styled } from '@mui/material';
import type { FC } from 'react';
import React from 'react';
import { experimental_sx as sx } from '@mui/system';
import { SetupDirectDebit } from './components/SetupDirectDebit';
import { DirectDebitStatusCard } from './components/DirectDebitStatusCard';
import { CancelDirectDebit } from './components/CancelDirectDebit';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';
import { useNursingHomeDirectDebitStatus } from '@pflegenavi/frontend/api-nursing-home';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DirectDebitPageProps {}

export const DirectDebitPage: FC<DirectDebitPageProps> = () => {
  const { selectedNursingHome } = useNursingHomeContext();

  const { data: directDebit, isLoading: isStatusLoading } =
    useNursingHomeDirectDebitStatus(selectedNursingHome?.id);

  if (isStatusLoading || !directDebit) {
    return (
      <LoadingContainer alignItems="center">
        <CircularProgress variant="indeterminate" />
      </LoadingContainer>
    );
  }

  return (
    <Grid container direction="row" alignItems="flex-start" spacing={3}>
      <Grid item xs={12} md={7} lg={8}>
        <SetupDirectDebit
          status={directDebit.status}
          paymentMethod={directDebit.paymentMethod}
        />
      </Grid>
      <Grid item xs={12} md={5} lg={4}>
        <DirectDebitStatusCard status={directDebit.status} />
        <CancelDirectDebit status={directDebit.status} />
      </Grid>
    </Grid>
  );
};

const LoadingContainer = styled(Stack)(
  sx({
    mt: 12,
  })
);
