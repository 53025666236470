import { TransferConfirmationView } from './TransferConfirmationView';
import type { FC } from 'react';
import React, { useCallback } from 'react';
import {
  cashStateToCoins,
  sumCashStateInCents,
  sumCoinsInCents,
} from '../../euro';
import { Box, Stack } from '@mui/material';
import {
  Button,
  LoadingPromiseButtonAdvanced,
  useErrorSnackbar,
} from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';
import { useTransferCallback } from '@pflegenavi/frontend/api-nursing-home';
import { CashNotesField } from '../inputs/CashNotesField';
import { useTransferFormStore } from './TransferFormStoreProvider';
import { enqueueSnackbar } from 'notistack';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';

export interface TransferConfirmationProps {
  onCancel: () => void;
}

export const TransferConfirmation: FC<TransferConfirmationProps> = ({
  onCancel,
}) => {
  const state = useTransferFormStore((state) => state);

  if (state.from.isLoading || state.to.isLoading) {
    throw new Error('Unexpected loading state');
  }

  const fromCashAmount = sumCashStateInCents(state.from.cashState);
  const toCashAmount = sumCashStateInCents(state.to.cashState);

  const fromAvailableCashAmount = sumCoinsInCents(
    state.from.availableCoins.concat(state.from.availableBankNotes)
  );
  const toAvailableCashAmount = sumCoinsInCents(
    state.to.availableCoins.concat(state.to.availableBankNotes)
  );

  return (
    <Stack gap={3} flex={1}>
      <TransferConfirmationView
        from={{
          storageType: state.from.selectedStorageType,
          bankAmount: state.from.bankAmount,
          availableBankAmount: state.from.availableBankAmount,
          cashAmount: fromCashAmount,
          availableCashAmount: fromAvailableCashAmount,
        }}
        to={{
          storageType: state.to.selectedStorageType,
          bankAmount: state.to.bankAmount,
          availableBankAmount: state.to.availableBankAmount,
          cashAmount: toCashAmount,
          availableCashAmount: toAvailableCashAmount,
        }}
      />
      <Box sx={{ flex: 1 }}>
        <CashNotesField
          notes={state.note}
          setNotes={state.setNote}
          error={state.noteError}
          setError={state.setNoteError}
          required={true}
          rows={3}
        />
      </Box>
      <ConfirmButton onCancel={onCancel} />
    </Stack>
  );
};

interface ConfirmButtonProps {
  onCancel: () => void;
}
const ConfirmButton: FC<ConfirmButtonProps> = ({ onCancel }) => {
  const { t } = useTranslation();
  const state = useTransferFormStore((state) => state);

  if (state.from.isLoading || state.to.isLoading) {
    throw new Error('Unexpected loading state');
  }

  const { enqueueErrorSnackbar } = useErrorSnackbar();

  const { selectedNursingHome } = useNursingHomeContext();
  const transfer = useTransferCallback(selectedNursingHome?.id);

  // eslint-disable-next-line complexity
  const onConfirm = useCallback(async () => {
    if (state.from.isLoading || state.to.isLoading) {
      throw new Error('Unexpected loading state');
    }

    const fromCoins = cashStateToCoins(state.from.cashState).map((coin) => ({
      ...coin,
      amount: -coin.amount,
    }));

    const toCoins = cashStateToCoins(state.to.cashState);

    try {
      await transfer({
        params: {
          fromCashListId: state.from.cashListId,
          toCashListId: state.to.cashListId,
        },
        body: {
          from: {
            bankAccountAmount:
              state.from.bankAmount === 0 ? undefined : -state.from.bankAmount,
            coins: fromCoins.length === 0 ? undefined : fromCoins,
            note: state.note,
          },
          to: {
            bankAccountAmount:
              state.to.bankAmount === 0 ? undefined : state.to.bankAmount,
            coins: toCoins.length === 0 ? undefined : toCoins,
            note: state.note,
          },
        },
      });

      onCancel();

      enqueueSnackbar(t('cashManagement.cash-change-success'), {
        variant: 'success',
      });
    } catch (err) {
      enqueueErrorSnackbar(err, t('error.something-went-wrong'), {
        variant: 'error',
      });
    }
  }, [
    transfer,
    enqueueErrorSnackbar,
    onCancel,
    state.from.bankAmount,
    state.from.cashListId,
    state.from.cashState,
    state.from.isLoading,
    state.note,
    state.to.bankAmount,
    state.to.cashListId,
    state.to.cashState,
    state.to.isLoading,
    t,
  ]);

  const canContinue = state.note.trim().length > 0;

  return (
    <Stack direction="row" justifyContent="flex-end" gap={1}>
      <Button variant="outlined" onClick={onCancel}>
        {t('actions.cancel')}
      </Button>
      <LoadingPromiseButtonAdvanced
        data-cy="confirm-cash-mgmt-button"
        variant="contained"
        onClick={onConfirm}
        disabled={!canContinue}
      >
        {t('action.confirm')}
      </LoadingPromiseButtonAdvanced>
    </Stack>
  );
};
