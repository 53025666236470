import React, { type FC, useCallback } from 'react';
import { Tooltip } from '@mui/material';
import { Iconify } from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';
import { MenuBarIconButton } from './atoms/MenuBarIconButton';

interface Props {
  helpUrl: string;
}
export const HelpIcon: FC<Props> = ({ helpUrl }) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    window.open(helpUrl, '_blank');
  }, [helpUrl]);

  return (
    <Tooltip title={t('menu-bar.help.title')}>
      <MenuBarIconButton onClick={handleClick}>
        <Iconify
          icon={'eva:question-mark-circle-fill'}
          width={24}
          height={24}
        />
      </MenuBarIconButton>
    </Tooltip>
  );
};
