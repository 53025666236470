import type { NavBarExtension } from '@pflegenavi/frontend/layout';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';

import reportingIcon from './assets/reporting.svg';
import { SvgIconStyle } from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';

export const useConfig: () => NavBarExtension = () => {
  const { t } = useTranslation();

  return {
    item: {
      icon: <SvgIconStyle src={reportingIcon} sx={{ width: 1, height: 1 }} />,
      title: t('reporting.main'),
      path: nhAppMainPages.REPORTING,
      children: [
        { title: t('common.overview'), path: nhAppMainPages.REPORTING },
        {
          title: t('common.transactions'),
          path: nhAppMainPages.REPORTING_TRANSACTIONS,
        },
      ],
    },
  };
};
