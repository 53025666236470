import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import type { ReceiptTableRowModel } from '@pflegenavi/transaction-components';
import { formatReceiptTableRowModel } from '@pflegenavi/transaction-components';
import {
  Link,
  makeConfig,
  PaginatedTable2,
  ReceiptStatusLabel,
} from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';
import {
  useFormatDate,
  useFormatting,
} from '@pflegenavi/frontend/localization';
import { generatePath, useNavigate } from 'react-router-dom';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';
import { useReceiptsPaginated } from '@pflegenavi/frontend/api-nursing-home';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import { Stack, Typography } from '@mui/material';
import { useTrackStructuredEvent } from '@pflegenavi/frontend/tracking';

type ReceiptTableColumnNames =
  | 'residentName'
  | 'receiptType'
  | 'serviceProvider'
  | 'amount'
  | 'submission-date'
  | 'receipt-date'
  | 'status'
  | 'receipt';
type ReceiptTableFilters = never;
const ReceiptStatus: FC<{ row: ReceiptTableRowModel }> = ({ row }) => {
  const receiptStatus = useMemo(
    () => (row.cancellation ? 'Cancelled' : row.status),
    [row.cancellation, row.status]
  );

  return <ReceiptStatusLabel status={receiptStatus} />;
};
export const ReceiptTable: FC<{ groupId: string | undefined }> = ({
  groupId,
}) => {
  const { t } = useTranslation();
  const { fCurrency } = useFormatting();
  const fDate = useFormatDate();

  const navigate = useNavigate();

  const { selectedNursingHome } = useNursingHomeContext();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const { data: receipts, isLoading } = useReceiptsPaginated(
    {
      nursingHomeId: selectedNursingHome?.id,
      pageSize,
      page,
      cashTransactionGroupId: groupId,
    },
    {
      enabled: groupId !== undefined,
    }
  );

  const formatted = useMemo(() => {
    if (!receipts) {
      return [];
    }
    return receipts.data.map((receipt) =>
      formatReceiptTableRowModel(receipt, { fDate, fCurrency, t })
    );
  }, [fCurrency, fDate, t, receipts]);

  const onNavigateUser = useCallback(
    (residentId: string) => {
      navigate(
        generatePath(nhAppMainPages.RESIDENT_DETAIL_ACCOUNT, {
          resident_id: residentId,
        })
      );
    },
    [navigate]
  );

  const config = useMemo(
    () =>
      makeConfig<
        ReceiptTableRowModel,
        ReceiptTableColumnNames,
        ReceiptTableFilters
      >({
        showAll: undefined,
        onClickRow: (row) => {
          navigate(
            generatePath(nhAppMainPages.TRANSACTIONS_EDIT, {
              transaction_id: row.id,
            })
          );
        },
        columns: [
          {
            key: 'residentName',
            label: t('residents.main'),
            align: 'left',
            component: ({ row }) => (
              <Link
                to={'/'}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onNavigateUser(row.residentId);
                }}
              >
                <Typography variant="subtitle2" noWrap>
                  {row.residentNameString}
                </Typography>
              </Link>
            ),
          },
          {
            key: 'receiptType',
            label: t('receipts.type'),
            align: 'left',
            // @ts-expect-error returning a string should not be a type error
            component: ({ row }) => row.receiptTypeString,
          },
          {
            key: 'serviceProvider',
            label: t('receipts.serviceProvider'),
            align: 'left',
            // @ts-expect-error returning a string should not be a type error
            component: ({ row }) => row.serviceProviderString,
          },
          {
            key: 'amount',
            label: t('resident.form.amount'),
            align: 'right',
            // @ts-expect-error returning a string should not be a type error
            component: ({ row }) => row.amountString,
          },
          {
            key: 'submission-date',
            label: t('common.submission-date'),
            align: 'center',
            // @ts-expect-error returning a string should not be a type error
            component: ({ row }) => row.submissionDateString ?? '--',
          },
          {
            key: 'receipt-date',
            label: t('common.receipt-date'),
            align: 'center',
            // @ts-expect-error returning a string should not be a type error
            component: ({ row }) => row.dateString,
          },
          {
            key: 'status',
            label: t('common.status'),
            align: 'left',
            component: ReceiptStatus,
          },
        ],
        pagination: {
          page,
          pageSize,
          pageSizeOptions: [5, 10],
          total: receipts?.meta.totalItems || 0,
          onPageChange: setPage,
          onPageSizeChange: setPageSize,
        },
      }),
    [t, receipts, page, pageSize, navigate, onNavigateUser]
  );

  if (!receipts || receipts.data.length === 0) {
    return null;
  }

  return (
    <Stack gap={2}>
      <Stack gap={0.5}>
        <Typography variant="h6" fontWeight="bold">
          {t('cashManagement.group-detail.overview.linked-receipts-title')}
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">
          {t('cashManagement.link.table-row.link-count.receipt', {
            count: receipts?.meta.totalItems || 0,
          })}
        </Typography>
      </Stack>

      <PaginatedTable2
        name="CashTransactionGroupDetailReceiptTable"
        useTrackStructuredEvent={useTrackStructuredEvent}
        config={config}
        rows={formatted ?? []}
        isLoading={isLoading}
      />
    </Stack>
  );
};
