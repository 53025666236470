import { Divider, Stack } from '@mui/material';
import type { FC } from 'react';
import { ChangeCashMode } from '../model';
import { CashListStorageType } from '@pflegenavi/shared/api';
import { CustomBankContent } from './CustomBankContent';
import { CustomCashContent } from './CustomCashContent';

interface TransferConfirmationViewProps {
  from: {
    storageType: CashListStorageType;
    bankAmount: number;
    availableBankAmount: number;
    cashAmount: number;
    availableCashAmount: number;
  };
  to: {
    storageType: CashListStorageType;
    bankAmount: number;
    availableBankAmount: number;
    cashAmount: number;
    availableCashAmount: number;
  };
}

export const TransferConfirmationView: FC<TransferConfirmationViewProps> = (
  props
) => {
  return (
    <Stack direction="row" gap={3} minHeight={100} alignItems="center">
      <Stack flex={1}>
        {props.from.storageType === CashListStorageType.BankAccount && (
          <CustomBankContent
            mode={ChangeCashMode.Withdraw}
            bankAccountChange={props.from.bankAmount}
            totalBankBalance={props.from.availableBankAmount}
          />
        )}
        {props.from.storageType === CashListStorageType.Cash && (
          <CustomCashContent
            mode={ChangeCashMode.Withdraw}
            cashChange={props.from.cashAmount}
            totalCashBalance={props.from.availableCashAmount}
          />
        )}
      </Stack>

      <Divider flexItem orientation="vertical" variant="middle" />

      <Stack flex={1}>
        {props.to.storageType === CashListStorageType.BankAccount && (
          <CustomBankContent
            mode={ChangeCashMode.Deposit}
            bankAccountChange={props.to.bankAmount}
            totalBankBalance={props.to.availableBankAmount}
          />
        )}
        {props.to.storageType === CashListStorageType.Cash && (
          <CustomCashContent
            mode={ChangeCashMode.Deposit}
            cashChange={props.to.cashAmount}
            totalCashBalance={props.to.availableCashAmount}
          />
        )}
      </Stack>
    </Stack>
  );
};
