import type { FC } from 'react';
import React from 'react';
import { Typography } from '@mui/material';

interface TitleProps {
  children: string;
}

export const Title: FC<TitleProps> = ({ children }) => {
  return (
    <Typography variant="h6" fontWeight={700} lineHeight={1} sx={{ mb: 2 }}>
      {children}
    </Typography>
  );
};
