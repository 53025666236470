import type { FC } from 'react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import IconBaselinePlus from '~icons/ic/baseline-plus';

import { generatePath, nhAppMainPages } from '@pflegenavi/frontend/routing';
import {
  Button,
  Container,
  HeaderBreadcrumbs,
} from '@pflegenavi/web-components';
import { useNavigate } from 'react-router-dom';
import { ReceiptBatchOverviewTable } from '@pflegenavi/transaction-components';

export const ReceiptBatchOverviewPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onAddReceiptBatch = useCallback(() => {
    navigate(generatePath(nhAppMainPages.RECEIPTS_BATCH_ADD));
  }, [navigate]);

  return (
    <Container maxWidth="lg">
      <HeaderBreadcrumbs
        heading={t('transactions.label')}
        links={[
          {
            name: t('transactions.label'),
            href: nhAppMainPages.TRANSACTIONS_PENDING,
          },
          {
            name: t('receipts.batch.batch-list-page-breadcrumb'),
          },
        ]}
        action={
          <Button
            variant="contained"
            color="primary"
            onClick={onAddReceiptBatch}
            startIcon={<IconBaselinePlus />}
          >
            {t('receipts.batch.add-receipt-batch')}
          </Button>
        }
        sx={{
          pb: 0,
        }}
      />
      <ReceiptBatchOverviewTable
        showReceiptTypeDropdown
        filters={['receiptDate', 'submissionDate']}
      />
    </Container>
  );
};
