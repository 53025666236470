import {
  Iconify,
  Label,
  MenuItem,
  TableCell,
  TableMoreMenu,
  TableRow,
} from '@pflegenavi/web-components';
import type { CashTransactionTableRowModel } from '../../interfaces/CashTransactionTableRowModel';
import { Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { FeatureFlag } from '@pflegenavi/shared/api';
import { useTranslation } from 'react-i18next';
import { generatePath, nhAppMainPages } from '@pflegenavi/frontend/routing';
import { useNavigate } from 'react-router-dom';
import { CoinsModal } from '../modals/CoinsModal';
import { useFeatureFlagEnabled } from '@pflegenavi/frontend/feature-flags';

// components
// ----------------------------------------------------------------------

interface Props {
  row: CashTransactionTableRowModel;
  selected: boolean;
}

export function CashListTransactionTableRow({
  row,
  selected,
}: Props): JSX.Element {
  const {
    typeLabelString,
    typeLabelColor,
    residentNameString,
    dateString,
    timeString,
    employeeString,
    amountString,
    amountColor,
    balanceAfterString,
    balanceBeforeString,
    info,
    coinChange,
    bankAccountAmount,
    groupId,
    selectedCashListName,
    type,
  } = row;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);

  const showModal = () => {
    setModalOpen(true);
  };

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleCloseMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(null);
    event.preventDefault();
    event.stopPropagation();
  };
  const groupDetailPath = useFeatureFlagEnabled(
    FeatureFlag.AccountingPaymentsNew
  )
    ? nhAppMainPages.NURSING_HOME_ACCOUNTING_GROUP_DETAIL
    : nhAppMainPages.CASH_MANAGEMENT_GROUP_DETAIL;
  const handleNavigateToGroupDetails = useCallback(() => {
    if (groupId) {
      navigate(
        generatePath(groupDetailPath, {
          groupId,
        })
      );
    }
  }, [groupId, navigate, groupDetailPath]);

  return (
    <>
      <TableRow
        hover
        selected={selected}
        onClick={showModal}
        sx={{ cursor: 'pointer' }}
      >
        <TableCell align="left" className={`cashListTransactionStatus${type}`}>
          <Label
            variant="ghost"
            color={typeLabelColor}
            sx={{ textTransform: 'capitalize' }}
          >
            {typeLabelString}
          </Label>
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {residentNameString}
        </TableCell>

        <TableCell align="left">
          <Typography component="span" variant="subtitle2">
            {dateString}&nbsp;
          </Typography>
          <Typography
            component="span"
            variant="body2"
            sx={{ color: 'text.secondary' }}
          >
            {timeString}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {employeeString}
        </TableCell>

        <TableCell align="right" sx={{ textTransform: 'capitalize' }}>
          <Typography variant="subtitle2">{balanceAfterString}</Typography>
        </TableCell>

        <TableCell align="right" sx={{ textTransform: 'capitalize' }}>
          <Typography color={amountColor} variant="subtitle2">
            {amountString}
          </Typography>
        </TableCell>

        <TableCell align="left">{info}</TableCell>

        <TableCell align="right">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              <>
                <MenuItem
                  onClick={(event) => {
                    handleNavigateToGroupDetails();
                    handleCloseMenu(event);
                  }}
                  disabled={!groupId}
                >
                  <Iconify icon={'eva:eye-fill'} />
                  {t('cashManagement.view-transaction-group')}
                </MenuItem>
              </>
            }
          />
        </TableCell>
      </TableRow>
      <CoinsModal
        open={modalOpen}
        setOpen={setModalOpen}
        coins={coinChange}
        bankAccountAmount={bankAccountAmount}
        amountColor={amountColor}
        amountString={amountString}
        balanceAfterString={balanceAfterString}
        balanceBeforeString={balanceBeforeString}
        selectedCashListName={selectedCashListName}
      />
    </>
  );
}
