import type { FC } from 'react';
import React from 'react';
import { Modal, ModalInner } from '@pflegenavi/web-components';
import type { OnConfirmCallback, OnSkippedCallback } from './ChangeCashForm';
import { ChangeCashForm } from './ChangeCashForm';
import type { ChangeCashMode } from './model';
import { useFeatureFlag } from '@pflegenavi/frontend/feature-flags';
import { FeatureFlag, FeatureFlagStatus } from '@pflegenavi/shared/api';

interface InitialValues {
  amount?: number;
  step?: number;
  mode?: ChangeCashMode;
  isStripeTransfer?: boolean;
}

interface ChangeCashModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  residentId?: string;
  initialValues?: InitialValues;
  onSkip?: OnSkippedCallback;
  onConfirm?: OnConfirmCallback;
}

export const ChangeCashModal: FC<ChangeCashModalProps> = ({
  open,
  setOpen,
  residentId,
  initialValues,
  onSkip,
  onConfirm,
}) => {
  const handleClose = (_: any, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const accountingPaymentsFeatureFlag =
    useFeatureFlag(FeatureFlag.AccountingPayments) ===
    FeatureFlagStatus.Enabled;

  return (
    <Modal open={open} handleClose={handleClose}>
      <ModalInner>
        <ChangeCashForm
          handleClose={() => setOpen(false)}
          initialValues={initialValues}
          residentId={residentId}
          accountingPaymentsFeatureFlag={accountingPaymentsFeatureFlag}
          onSkip={onSkip}
          onConfirm={onConfirm}
          forceCashListId={undefined}
        />
      </ModalInner>
    </Modal>
  );
};
