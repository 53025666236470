import React, { useEffect, useMemo, useState } from 'react';
import type {
  IFuseOptionsExtended,
  TableTab,
} from '@pflegenavi/web-components';
import {
  applySortFilter,
  Card,
  DefaultTable,
  DefaultTableToolbar,
  Divider,
  MonthNavigationBlock,
  Stack,
  TableTabs,
  useResetSearchInput,
  useTable,
} from '@pflegenavi/web-components';
import {
  CashManagementTransactionType,
  FeatureFlag,
} from '@pflegenavi/shared/api';
import {
  useCashListConfiguration,
  useCashManagementTransactions,
} from '@pflegenavi/frontend/api-nursing-home';
import { useFeatureFlagEnabled } from '@pflegenavi/frontend/feature-flags';
import { useTranslation } from 'react-i18next';
import { CashListTransactionTableRow } from './CashListTransactionTableRow';
import {
  useFormatDate,
  useFormatTime,
  useFormatting,
  useLocale,
} from '@pflegenavi/frontend/localization';
import { endOfMonth, startOfMonth } from 'date-fns';
import { formatCashTransactionTableRowModel } from '../../helpers/formatCashTransactionTableRowModel';
import type { CashTransactionTableRowModel } from '../../interfaces/CashTransactionTableRowModel';
import { useTheme } from '@mui/material/styles';

type CashManagementTransactionTypeAll = CashManagementTransactionType | 'all';

const getFuseOptions = (
  language: string
): IFuseOptionsExtended<CashTransactionTableRowModel> => {
  return {
    includeScore: true, // mostly for test purposes
    threshold: 0.5,
    keys: [
      'typeLabelString',
      'residentFirstName',
      'residentLastName',
      'residentNameString',
      'amount',
      'dateString',
      'employeeFirstName',
      'employeeLastName',
      'employeeString',
      'amountString',
      'statusLabelString',
    ],
  };
};

export enum CashListColumn {
  Type = 'type',
  Resident = 'resident',
  Date = 'date',
  Employee = 'employee',
  Balance = 'balance',
  Amount = 'amount',
  Info = 'info',
  Actions = 'actions',
}

interface CashListTransactionProps {
  cashListId: string;
}

export function CashListTransaction({
  cashListId,
}: CashListTransactionProps): JSX.Element {
  const { t } = useTranslation();
  const { fCurrencyCents } = useFormatting();
  const fDate = useFormatDate();
  const fTime = useFormatTime();
  const theme = useTheme();

  const locale = useLocale();

  const accountingPaymentsFeatureFlagEnabled = useFeatureFlagEnabled(
    FeatureFlag.AccountingPayments
  );

  const newAccountingPaymentsFeatureFlagEnabled = useFeatureFlagEnabled(
    FeatureFlag.AccountingPaymentsNew
  );

  const [reportingMonth, setReportingMonth] = useState(new Date());
  const { data, isLoading, isError } = useCashManagementTransactions({
    cashListId,
    dateFrom: startOfMonth(reportingMonth),
    dateTo: endOfMonth(reportingMonth),
  });

  const {
    order,
    orderBy,
    setPage,
    comparator,
    onSelectAllRows: _void,
    rowsPerPage,
    ...tableProps
  } = useTable<CashTransactionTableRowModel, CashListColumn>({
    defaultOrder: 'desc',
    defaultRowsPerPage: 10,
    defaultOrderBy: CashListColumn.Date,
    tableKey: 'cashList',
    sortKeys: {
      [CashListColumn.Type]: ['typeLabelString'],
      [CashListColumn.Resident]: ['residentNameString'],
      [CashListColumn.Date]: ['date'],
      [CashListColumn.Employee]: ['employeeString'],
      [CashListColumn.Balance]: ['balanceAfterString'],
      [CashListColumn.Amount]: ['amountInCents'],
      [CashListColumn.Info]: ['info'],
      [CashListColumn.Actions]: [],
    },
  });

  const { cashLists } = useCashListConfiguration();
  const selectedCashListName = useMemo(() => {
    return (
      cashLists?.find((cashList) => cashList.id === cashListId)?.name ?? ''
    );
  }, [cashListId, cashLists]);

  const [tabFilterValue, setTabFilterValue] = useState<
    CashManagementTransactionType | 'all'
  >('all');

  const [filterTerms, setFilterTerms] = useResetSearchInput(tabFilterValue);

  const { dataFiltered, dataFilteredAfterStatus } = useMemo(() => {
    const formattedData = (data ?? []).map((row) =>
      formatCashTransactionTableRowModel(row, {
        fCurrencyCents,
        fDate,
        fTime,
        theme,
        t,
        selectedCashListName,
      })
    );
    return applySortFilter({
      dataFiltered: formattedData,
      comparator: comparator,
      filterTerms,
      filterStatus: tabFilterValue,
      fuseOptions: getFuseOptions(locale),
    });
  }, [
    data,
    comparator,
    filterTerms,
    tabFilterValue,
    fCurrencyCents,
    fDate,
    t,
    fTime,
    locale,
    theme,
    selectedCashListName,
  ]);

  useEffect(() => {
    setPage(0);
  }, [filterTerms, orderBy, tabFilterValue, setPage]);

  const getLengthByStatus = (status: CashManagementTransactionTypeAll) =>
    (dataFiltered || []).filter(
      (item) => status === 'all' || item.type === status
    ).length;

  const TABS: Array<TableTab<CashManagementTransactionTypeAll>> = [
    {
      value: 'all',
      label: t('common.all'),
      color: 'default',
      count: getLengthByStatus('all'),
    },
    {
      value: CashManagementTransactionType.Deposit,
      label: t('cashManagement.table-head.deposit'),
      color: 'success',
      count: getLengthByStatus(CashManagementTransactionType.Deposit),
    },
    {
      value: CashManagementTransactionType.Withdrawal,
      label: t('cashManagement.table-head.withdrawal'),
      color: 'info',
      count: getLengthByStatus(CashManagementTransactionType.Withdrawal),
    },
    {
      value: CashManagementTransactionType.Adjustment,
      label: t('cashManagement.table-head.adjustments'),
      color: 'default',
      count: getLengthByStatus(CashManagementTransactionType.Adjustment),
    },
  ];

  if (accountingPaymentsFeatureFlagEnabled) {
    TABS.push({
      value: CashManagementTransactionType.StripeTransfer,
      label: t('cashManagement.table-head.stripe-transfer'),
      color: 'warning',
      count: getLengthByStatus(CashManagementTransactionType.StripeTransfer),
    });
  }
  return (
    <Card>
      <TableTabs<CashManagementTransactionTypeAll>
        value={tabFilterValue}
        setValue={setTabFilterValue}
        tabs={TABS}
      >
        <Stack
          sx={{
            flex: 1,
          }}
        />
        <Stack
          sx={{
            justifyContent: 'center',
          }}
        >
          <MonthNavigationBlock
            reportingMonth={reportingMonth}
            setReportingMonth={setReportingMonth}
          />
        </Stack>
      </TableTabs>
      <Divider />

      <DefaultTableToolbar
        searchPrompt={t('cashManagement.search-cash-transaction')}
        filterTerms={filterTerms}
        onFilterTerms={setFilterTerms}
      />

      <DefaultTable
        tableKey="cashList"
        dataAvailableBeforeFilter={(data ?? []).length > 0}
        data={dataFilteredAfterStatus}
        isFiltered={!!filterTerms}
        isError={isError}
        isLoading={isLoading}
        isNoDataThisMonth={(data ?? []).length === 0}
        customNoDataLabel={t('table.no-data')}
        Modals={null}
        tableHead={[
          {
            id: CashListColumn.Type,
            label: t('common.type'),
            align: 'left',
          },
          {
            id: CashListColumn.Resident,
            label: t('common.resident'),
            align: 'left',
          },
          {
            id: CashListColumn.Date,
            label: t('cashManagement.table-head.datetime'),
            align: 'left',
          },
          {
            id: CashListColumn.Employee,
            label: t('common.employee'),
            align: 'left',
          },
          {
            id: CashListColumn.Balance,
            label: newAccountingPaymentsFeatureFlagEnabled
              ? t('cashManagement.table-head.balance-new-wording')
              : t('cashManagement.table-head.balance'),
            align: 'right',
          },
          {
            id: CashListColumn.Amount,
            label: t('common.amount'),
            align: 'right',
          },
          {
            id: CashListColumn.Info,
            label: t('common.info'),
            align: 'left',
          },
          {
            id: CashListColumn.Actions,
            label: '',
          },
        ]}
        TableRow={CashListTransactionTableRow}
        order={order}
        orderBy={orderBy}
        comparator={comparator}
        {...tableProps}
        onSelectAllRows={undefined}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
      />
    </Card>
  );
}
