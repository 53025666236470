import type { FC } from 'react';
import { NursingHomeQuickOverview } from './NursingHomeQuickOverview';
import { QuickCreation } from './QuickCreation';

export const MenuItems: FC = () => {
  return (
    <>
      <QuickCreation />
      <NursingHomeQuickOverview />
    </>
  );
};
