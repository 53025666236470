import { ChangeCashMode } from '../model';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import { useFormatting } from '@pflegenavi/frontend/localization';
import { useTranslation } from 'react-i18next';
import { Card, Iconify, Stack, Typography } from '@pflegenavi/web-components';
import { Divider } from '@mui/material';
import {
  CashChangeAvatarIcon,
  IconWrapperStyle,
} from '../icons/ChangeCashIcons';

interface ChangeBankAccountSummaryCardProps {
  mode: ChangeCashMode;
  total: number;

  totalBankAccountBalance: number | undefined;
  totalBankAccountBalanceLoading: boolean;
}

export const ChangeBankAccountSummaryCard: FC<
  ChangeBankAccountSummaryCardProps
> = ({
  mode,
  total,
  totalBankAccountBalance,
  totalBankAccountBalanceLoading,
}) => {
  const { fCurrencyCents } = useFormatting();
  const { t } = useTranslation();

  const sign = useCallback(
    (amount: number) =>
      amount === 0 ? '' : mode === ChangeCashMode.Withdraw ? '-' : '',
    [mode]
  );

  const amountFactor = useMemo(() => {
    return mode === ChangeCashMode.Withdraw ? -1 : 1;
  }, [mode]);

  const icon = useMemo(() => {
    if (mode === ChangeCashMode.Adjust) {
      return `eva:trending-${total < 0 ? 'down' : 'up'}-fill`;
    }
    return `eva:trending-${
      mode === ChangeCashMode.Withdraw ? 'down' : 'up'
    }-fill`;
  }, [mode, total]);

  const GREY = 'grey.400';

  const getColour = useCallback(
    (amount: number): string => {
      if (amount === 0) {
        return GREY;
      }

      if (mode === ChangeCashMode.Adjust) {
        return amount < 0 ? 'error.main' : 'success.main';
      }
      return mode === ChangeCashMode.Withdraw ? 'error.main' : 'success.main';
    },
    [mode]
  );

  if (totalBankAccountBalance === undefined || totalBankAccountBalanceLoading) {
    return null;
  }

  return (
    <Card
      sx={{
        flex: 1,
        p: 2,
        width: 'fit-content',
      }}
    >
      <Stack direction="column" gap={1}>
        <Stack direction="row" gap={1} alignItems="center">
          <CashChangeAvatarIcon>
            <Iconify icon="ant-design:bank-filled" />
          </CashChangeAvatarIcon>
          <Typography variant="subtitle2">
            {t('cashManagement.bank-account-change')}
          </Typography>
        </Stack>
        <Divider />
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2" color="grey">
            {t('cashManagement.current-bank-account-balance')}
          </Typography>
          <Stack direction="row" gap={1} pl={8}>
            <Typography variant="subtitle2" color="grey">
              {fCurrencyCents(totalBankAccountBalance)}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2">
            {t(
              `cashManagement.${
                mode === ChangeCashMode.Deposit ? 'deposit' : 'withdrawal'
              }-amount`
            )}
          </Typography>

          <Stack direction="row" gap={1}>
            <IconWrapperStyle mode={mode} amount={total}>
              <Iconify width={16} height={16} icon={icon} />
            </IconWrapperStyle>
            <Typography variant="subtitle2" color={getColour(total)}>
              {sign(total)}
              {fCurrencyCents(total)}
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2">
            {t('cashManagement.new-bank-account-balance')}
          </Typography>
          <Typography variant="subtitle2" fontWeight={700}>
            {fCurrencyCents(totalBankAccountBalance + amountFactor * total)}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};
