import { Label, SvgIconStyle } from '@pflegenavi/web-components';
import {
  fmAppMainPages,
  nhAppMainPages,
  spAppMainPages,
} from '@pflegenavi/frontend/routing';

import residentsIcon from '../assets/contact_page_24dp.svg';
import dashboardIcon from '../assets/dashboard_24dp.svg';
import transactionsIcon from '../assets/ic_invoice.svg';
import paymentsIcon from '../assets/credit_card_24dp.svg';
import bankIcon from '../assets/bankIcon.svg';
import serviceIcon from '../assets/service_icon.svg';

import { useTranslation } from 'react-i18next';

import { createContext, useContext, useMemo } from 'react';
import { useExtensions } from './ExtensionContext';
import { isDefined } from '@pflegenavi/shared/utils';
import { Typography } from '@mui/material';
// ----------------------------------------------------------------------

export const IncompleteTransactionsCountContext = createContext<number>(0);
export const FailedAndFrozenPaymentsCountContext = createContext<number>(0);

const getIcon = (url: string) => (
  <SvgIconStyle
    src={url}
    sx={{
      width: 1,
      height: 1,
    }}
  />
);

export type ConfigKey = 'nh-web' | 'fm-web' | 'sp-web';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useNavConfig = ({ configKey }: { configKey: ConfigKey }) => {
  const { t } = useTranslation();
  const incompleteTransactions = useContext(IncompleteTransactionsCountContext);
  const extensions = useExtensions();

  const transactionsInfo = useMemo(() => {
    if (incompleteTransactions === 0) {
      return undefined;
    }
    if (incompleteTransactions > 9) {
      // For 3, 7, 9, we could also use  ml={0.1}, since they look a bit more centered that way (although all numbers
      // are correctly centered already). Just for future reference.
      return (
        <Label variant="outlined" color="error">
          <Typography variant={'inherit'} ml={0.1} mt={0.15}>
            9+
          </Typography>
        </Label>
      );
    }

    return (
      <Label variant="outlined" color="error">
        <Typography variant={'inherit'} mt={0.15}>
          {incompleteTransactions}
        </Typography>
      </Label>
    );
  }, [incompleteTransactions]);

  const navConfigs = {
    'nh-web': [
      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: 'management',
        items: [
          {
            title: t('dashboard.main'),
            path: nhAppMainPages.DASHBOARD,
            icon: getIcon(dashboardIcon),
          },
          // ResidentsTable
          {
            title: t('residents.main'),
            path: nhAppMainPages.RESIDENTS,
            icon: getIcon(residentsIcon),

            children: [
              {
                title: t('common.overview'),
                path: nhAppMainPages.RESIDENTS,
              },
              {
                title: t('nav.residents.new'),
                path: nhAppMainPages.RESIDENTS_ADD,
              },
              {
                title: t('nav.residents.inactive'),
                path: nhAppMainPages.RESIDENTS_INACTIVE,
              },
            ],
          },
          // Transactions
          {
            title: t('receipts.main'),
            path: nhAppMainPages.TRANSACTIONS,
            icon: getIcon(paymentsIcon),
            // TODO: Enable this to show transactions.
            // TODO: This also means we need to put the config inside a context so that we can inject it
            info: transactionsInfo,
            children: [
              {
                title: t('nav.receipts.pending'),
                path: nhAppMainPages.TRANSACTIONS_PENDING,
              },
              {
                title: t('nav.receipts.submitted'),
                path: nhAppMainPages.TRANSACTIONS_SUBMITTED,
              },
              {
                title: t('nav.receipts.batch.list'),
                path: nhAppMainPages.RECEIPTS_BATCH,
              },
              {
                title: t('nav.receipts.new'),
                path: nhAppMainPages.TRANSACTIONS_ADD_EMPTY_RESIDENT,
              },
              {
                title: t('receipts.batch.add-receipt-batch'),
                path: nhAppMainPages.RECEIPTS_BATCH_ADD,
              },
            ],
          },
          ...extensions.filter(isDefined).map(({ item }) => item),
        ],
      },
    ],
    'fm-web': [
      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: 'management',
        items: [
          {
            title: t('dashboard.main'),
            path: fmAppMainPages.DASHBOARD,
            icon: getIcon(dashboardIcon),
          },
          {
            title: t('receipts.main'),
            path: fmAppMainPages.TRANSACTIONS,
            icon: getIcon(transactionsIcon),
          },
          {
            title: t('fm-web-navigation.payments'),
            path: fmAppMainPages.PAYMENTS,
            icon: getIcon(paymentsIcon),
          },
          {
            title: t('fm-web-navigation.direct-debit'),
            path: fmAppMainPages.DIRECT_DEBIT,
            icon: getIcon(bankIcon),
          },
        ],
      },
    ],
    'sp-web': [
      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: 'management',
        items: [
          ...extensions.filter(isDefined).map(({ item }) => item),
          {
            title: t('service-provider-page.services.title'),
            path: spAppMainPages.SERVICES,
            icon: getIcon(serviceIcon),
            children: [
              {
                title: t('service-provider-page.services.overview'),
                path: spAppMainPages.SERVICES,
              },
            ],
          },
        ],
      },
    ],
  };

  return navConfigs[configKey];
};

export default useNavConfig;
