import type { LabelColor } from '@pflegenavi/web-components';
import type {
  useFormatDate,
  useFormatTime,
  useFormatting,
} from '@pflegenavi/frontend/localization';
import type { GetCashTransactionGroupListResultDto } from '@pflegenavi/shared/api';
import { CashTransactionGroupType } from '@pflegenavi/shared/api';
import {
  TIME_FORMAT,
  YEAR_MONTH_DAY_SHORT_FORMAT,
} from '@pflegenavi/shared/constants';
import type { ReactNode } from 'react';
import {
  BankIcon,
  DepositIcon,
  EuroIcon,
  EuroIconGrey,
  ReceiptIcon,
  ResidentIcon,
  WithdrawalIcon,
  ZeroChangeIcon,
} from './TypeColumn';
import type { DefaultNamespace, UseTranslationResponse } from 'react-i18next';

export interface CashTransactionGroupRowModel {
  id: string;
  type: CashTransactionGroupType;
  typeString: string;
  typeSubString: string;
  typeColor: LabelColor;
  typeIcon: ReactNode;
  typeSubIcon: ReactNode;
  typeLinkedResidentId?: string;
  lastUpdateDate: Date;
  lastUpdateDateString: string;
  lastUpdateTime: string;
  transferCount?: string;
  transferAmount?: string;
  transferColor: string;
  cashChanged?: number;
  amountLinked?: number;
  amountLinkedString?: string;
  linkCount?: string;
  notes: string;

  mismatchConfirmation: boolean;
  cashListId: string;
}

interface Context {
  fDate: ReturnType<typeof useFormatDate>;
  fTime: ReturnType<typeof useFormatTime>;
  fCurrency: ReturnType<typeof useFormatting>['fCurrency'];
  t: UseTranslationResponse<DefaultNamespace>['t'];
}

// eslint-disable-next-line complexity
function getLinkCount(
  row: GetCashTransactionGroupListResultDto,
  opts: Context
): string {
  const receiptLinkCount = row.receiptLinkCount ?? 0;
  const transactionLinkCount = row.transactionLinkCount ?? 0;
  if (receiptLinkCount > 0 && transactionLinkCount > 0) {
    return opts.t('cashManagement.link.table-row.link-count.mixed', {
      count: receiptLinkCount + transactionLinkCount,
    });
  }

  if (receiptLinkCount > 0) {
    return opts.t('cashManagement.link.table-row.link-count.receipt', {
      count: receiptLinkCount,
    });
  }

  if (transactionLinkCount > 0) {
    return opts.t('cashManagement.link.table-row.link-count.transaction', {
      count: transactionLinkCount,
    });
  }

  if (row.payoutLinkCount && row.payoutLinkCount > 0) {
    return opts.t('cashManagement.link.table-row.link-count.payout', {
      count: row.payoutLinkCount,
    });
  }

  if (row.paymentIntentLinkCount && row.paymentIntentLinkCount > 0) {
    return opts.t('cashManagement.link.table-row.link-count.payment-intent', {
      count: row.paymentIntentLinkCount,
    });
  }

  if (row.cashListTransactionCount && row.cashListTransactionCount > 0) {
    return opts.t(
      'cashManagement.link.table-row.link-count.cash-list-transaction',
      {
        count: row.cashListTransactionCount,
      }
    );
  }

  if (
    row.type === CashTransactionGroupType.ReceiptWithdrawal ||
    row.type === CashTransactionGroupType.ResidentWithdrawal
  ) {
    return opts.t('cashManagement.link.table-row.link-count.receipt', {
      count: 0,
    });
  }

  if (row.type === CashTransactionGroupType.ResidentDeposit) {
    return opts.t('cashManagement.link.table-row.link-count.transaction', {
      count: 0,
    });
  }

  return '--';
}

function getResidentNamePlusCount(
  residents: Array<{
    id: string;
    firstName: string;
    lastName: string;
  }>
) {
  return `${residents[0].firstName} ${residents[0].lastName}${
    residents.length > 1 ? ` + ${residents.length - 1}` : ''
  }`;
}

export const formatTypeColumn = (
  row: {
    type: CashTransactionGroupType;
    cashChanged?: number;
    residents?:
      | Array<{ id: string; firstName: string; lastName: string }>
      | undefined;
  },
  opts: Pick<Context, 't'>
): Pick<
  CashTransactionGroupRowModel,
  | 'type'
  | 'typeString'
  | 'typeSubString'
  | 'typeIcon'
  | 'typeSubIcon'
  | 'typeLinkedResidentId'
> => {
  // eslint-disable-next-line complexity
  const typeString = (() => {
    switch (row.type) {
      case CashTransactionGroupType.ResidentWithdrawal:
        return row.residents === undefined || row.residents.length === 0
          ? opts.t('cashManagement.withdrawal')
          : getResidentNamePlusCount(row.residents);
      case CashTransactionGroupType.ReceiptWithdrawal:
        return opts.t('cashManagement.withdrawal');
      case CashTransactionGroupType.ResidentDeposit:
        return row.residents === undefined || row.residents.length === 0
          ? opts.t('cashManagement.deposit')
          : getResidentNamePlusCount(row.residents);
      case CashTransactionGroupType.Deposit:
        return opts.t('cashManagement.deposit');
      case CashTransactionGroupType.Transfer:
        return row.cashChanged && row.cashChanged > 0
          ? opts.t('cashManagement.deposit')
          : opts.t('cashManagement.withdrawal');
      case CashTransactionGroupType.Adjustment:
        // TODO
        return opts.t('cashManagement.table-row.adjustment');
    }
    return '';
  })();

  // eslint-disable-next-line complexity
  const typeSubString = (() => {
    switch (row.type) {
      case CashTransactionGroupType.ReceiptWithdrawal:
        return opts.t('cashManagement.link.table-row.type.receipt-withdrawal');
      case CashTransactionGroupType.Deposit:
        return opts.t('cashManagement.link.table-row.type.deposit');
      case CashTransactionGroupType.ResidentDeposit:
        return opts.t('cashManagement.link.table-row.type.resident-deposit');
      case CashTransactionGroupType.ResidentWithdrawal:
        return opts.t('cashManagement.link.table-row.type.resident-withdrawal');
      case CashTransactionGroupType.Transfer:
        return opts.t('cashManagement.link.table-row.type.transfer');
      case CashTransactionGroupType.Adjustment:
        return opts.t('cashManagement.link.table-row.type.adjustment');
    }
    return '';
  })();

  // eslint-disable-next-line complexity
  const typeIcon = (() => {
    switch (row.type) {
      case CashTransactionGroupType.ResidentWithdrawal:
      case CashTransactionGroupType.ResidentDeposit:
        return <ResidentIcon />;
      case CashTransactionGroupType.ReceiptWithdrawal:
        return <ReceiptIcon />;
      case CashTransactionGroupType.Transfer:
        return <BankIcon />;
      case CashTransactionGroupType.Adjustment:
        if (row.cashChanged && row.cashChanged !== 0) {
          return <EuroIcon />;
        }
        return <EuroIconGrey />;
      case CashTransactionGroupType.Deposit:
        // TODO
        return <EuroIcon />;
    }
    return null;
  })();

  // eslint-disable-next-line complexity
  const typeSubIcon = (() => {
    switch (row.type) {
      case CashTransactionGroupType.ReceiptWithdrawal:
      case CashTransactionGroupType.ResidentWithdrawal:
        return <WithdrawalIcon />;
      case CashTransactionGroupType.Deposit:
      case CashTransactionGroupType.ResidentDeposit:
        return <DepositIcon />;
      case CashTransactionGroupType.Transfer:
      case CashTransactionGroupType.Adjustment:
        if (row.cashChanged) {
          if (row.cashChanged > 0) {
            return <DepositIcon />;
          } else if (row.cashChanged < 0) {
            return <WithdrawalIcon />;
          }
        }
        return <ZeroChangeIcon />;
    }
    return null;
  })();

  return {
    type: row.type,
    typeString: typeString,
    typeSubString: typeSubString,
    typeLinkedResidentId:
      row.residents !== undefined &&
      row.residents.length > 0 &&
      (row.type === CashTransactionGroupType.ResidentDeposit ||
        row.type === CashTransactionGroupType.ResidentWithdrawal)
        ? row.residents[0].id
        : undefined,
    typeIcon,
    typeSubIcon,
  };
};

// eslint-disable-next-line complexity
export const formatCashTransactionGroupRowModel = (
  row: GetCashTransactionGroupListResultDto,
  opts: Context
): CashTransactionGroupRowModel => {
  // eslint-disable-next-line complexity
  const color = (() => {
    switch (row.type) {
      case CashTransactionGroupType.Deposit:
        return 'success';
      case CashTransactionGroupType.ReceiptWithdrawal:
        return 'error';
      case CashTransactionGroupType.ResidentWithdrawal:
        return 'error';
      case CashTransactionGroupType.Transfer:
        return 'default';
      case CashTransactionGroupType.Adjustment:
        return 'warning';
    }
    return 'success';
  })();

  // eslint-disable-next-line complexity
  const amountLinked = (() => {
    switch (row.type) {
      case CashTransactionGroupType.Adjustment:
      case CashTransactionGroupType.Deposit: // No way to link a deposit atm
        return (row.amountLinked ?? 0) > 0
          ? opts.fCurrency(row.amountLinked ?? 0)
          : '--';
    }
    return opts.fCurrency(row.amountLinked ?? 0);
  })();

  return {
    id: row.id,
    cashListId: row.cashListId,
    typeColor: color,
    lastUpdateTime: opts.fTime(row.updateDate, TIME_FORMAT),
    lastUpdateDate: row.updateDate,
    lastUpdateDateString: opts.fDate(
      row.updateDate,
      YEAR_MONTH_DAY_SHORT_FORMAT
    ),
    transferCount: opts.t('cashManagement.link.table-row.transfer-count', {
      count: row.transferCount ?? 0,
    }),
    transferAmount: opts.fCurrency(row.cashChanged ?? 0),
    cashChanged: row.cashChanged,
    transferColor:
      (row.cashChanged ?? 0) > 0
        ? 'success.main'
        : (row.cashChanged ?? 0) < 0
        ? 'error.main'
        : 'grey.500',
    amountLinked: row.amountLinked,
    amountLinkedString: amountLinked,
    linkCount: getLinkCount(row, opts),
    notes: row.notes || '--',
    mismatchConfirmation: Boolean(row.mismatchConfirmationDate),
    ...formatTypeColumn(row, opts),
  };
};
