import { useTranslation } from 'react-i18next';
import { CashListStorageType } from '@pflegenavi/shared/api';
import { Button, Iconify } from '@pflegenavi/web-components';
import euroSign from '../../../assets/cash_list.svg';
import type { FC } from 'react';
import React from 'react';

interface ModeChangerProps {
  mode: CashListStorageType;
  setMode: (mode: CashListStorageType) => void;
}

export const ModeChanger: FC<ModeChangerProps> = (props) => {
  const { t } = useTranslation();
  if (props.mode === CashListStorageType.Cash) {
    return (
      <Button
        startIcon={
          <Iconify style={{ marginTop: -4 }} icon="ant-design:bank-filled" />
        }
        onClick={() => props.setMode(CashListStorageType.BankAccount)}
      >
        {t('cashManagement.transfer.switch-to-bank')}
      </Button>
    );
  } else {
    return (
      <Button
        startIcon={
          <img
            style={{
              width: 20,
              marginTop: -2,
            }}
            alt=""
            src={euroSign}
          />
        }
        onClick={() => props.setMode(CashListStorageType.Cash)}
      >
        {t('cashManagement.transfer.switch-to-cash')}
      </Button>
    );
  }
};
