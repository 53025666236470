import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Coin } from '@pflegenavi/shared/api';
import { CashListStorageType } from '@pflegenavi/shared/api';
import type { CashState } from './model';
import { ChangeCashMode } from './model';
import { coinsToCashState, useBankNotesAndCoins } from '../euro';
import type { InitialValues, OnSkippedCallback } from './ChangeCashForm';
import { BankOrCash } from './ChangeCashForm';
import type { ICashListConfigurationContext } from '@pflegenavi/frontend/api-nursing-home';
import {
  getOperationalCashList,
  useCashListConfiguration,
} from '@pflegenavi/frontend/api-nursing-home';

export interface UseChangeCashFormProps {
  initialValues?: InitialValues;
  onSkip?: OnSkippedCallback;
  forceCashListId: string | undefined;
}

function getInitialCashList(
  cashListConfiguration: ICashListConfigurationContext
): string {
  if (!cashListConfiguration.selectedCashListId) {
    return getOperationalCashList(cashListConfiguration).id;
  }
  return cashListConfiguration.selectedCashListId;
}

// eslint-disable-next-line complexity,@typescript-eslint/explicit-module-boundary-types
export const useChangeCashForm = ({
  initialValues,
  onSkip,
  forceCashListId,
}: UseChangeCashFormProps) => {
  const {
    step: initialStep = 0,
    amount: initialAmount = 0,
    mode: initialMode = ChangeCashMode.Deposit,
    isStripeTransfer: initialIsStripeTransfer = false,
  } = initialValues ?? {};

  const cashListConfiguration = useCashListConfiguration();
  const [selectedCashListId, setSelectedCashListIdOriginal] = useState(
    forceCashListId ?? getInitialCashList(cashListConfiguration)
  );

  const storageType =
    cashListConfiguration.cashLists.find(
      (cashList) => cashList.id === selectedCashListId
    )?.storageType ?? CashListStorageType.Cash;

  const {
    bankNotes,
    coins,
    isLoading: isCoinsAndBankNotesLoading,
    bankAccountAmount,
  } = useBankNotesAndCoins(selectedCashListId);

  const [step, setStep] = useState(initialStep);
  const [mode, setMode] = useState(initialMode);
  const [skippedCashSelection, setSkippedCashSelection] = useState(false);

  const allowSkipCashSelection = onSkip !== undefined;

  const [bankOrCash, setBankOrCash] = useState<BankOrCash>(
    initialValues?.bankOrCash ??
      (initialMode === ChangeCashMode.Adjust
        ? BankOrCash.Adjustment
        : BankOrCash.Cash)
  );

  useEffect(() => {
    if (mode === ChangeCashMode.Adjust) {
      setBankOrCash(BankOrCash.Adjustment);
      return;
    }
    let initialBankOrCash = initialValues?.bankOrCash ?? BankOrCash.Cash;
    if (storageType === CashListStorageType.Cash) {
      initialBankOrCash = BankOrCash.Cash;
    } else if (storageType === CashListStorageType.BankAccount) {
      initialBankOrCash = BankOrCash.Bank;
    }

    setBankOrCash(initialBankOrCash);
  }, [initialValues?.bankOrCash, mode, storageType]);

  const initialBankAccountAmount =
    initialValues?.bankAccountAmount || initialAmount || undefined;
  const [bankAccountChange, setBankAccountChange] = useState<
    number | undefined
  >(initialBankAccountAmount ? Math.abs(initialBankAccountAmount) : undefined);

  useEffect(() => {
    if (initialAmount && bankOrCash === BankOrCash.Bank) {
      setBankAccountChange(Math.abs(initialAmount));
    }
  }, [bankOrCash, initialAmount]);

  const [isStripeTransfer, setIsStripeTransfer] = useState(
    initialIsStripeTransfer
  );

  const { setCashState, cashState } = useInitializeCashState({
    initialValues,
    initialAmount,
    mode,
    coins,
    bankNotes,
    skippedCashSelection,
  });

  const setSelectedCashListId = useCallback(
    (update: React.SetStateAction<string>) => {
      if (forceCashListId) {
        throw new Error(
          'Called setSelectedCashListId even thou forceCashListId is set'
        );
      }
      setCashState({});
      setBankAccountChange(0);
      setSelectedCashListIdOriginal(update);
    },
    [forceCashListId, setCashState]
  );

  const finalBankAccountChange =
    bankOrCash === BankOrCash.Bank || bankOrCash === BankOrCash.Adjustment
      ? bankAccountChange
      : 0;
  const finalCashState =
    bankOrCash === BankOrCash.Cash || bankOrCash === BankOrCash.Adjustment
      ? cashState
      : {};

  return {
    cashState: finalCashState,
    bankAccountChange: finalBankAccountChange,
    setBankAccountChange,
    isStripeTransfer,
    setIsStripeTransfer,
    bankOrCash,
    allowSkipCashSelection,
    step,
    setStep,
    setMode,
    skippedCashSelection,
    setSkippedCashSelection,
    mode,
    setCashState,
    setBankOrCash,
    initialAmount,
    selectedCashListId,
    setSelectedCashListId,
    bankNotes,
    coins,
    isCoinsAndBankNotesLoading,
    bankAccountAmount,
    storageType,
  };
};

interface UseInitialCashStateProps {
  initialValues?: {
    coins?: Coin[];
  };
  initialAmount?: number;
  coins?: Coin[];
  bankNotes?: Coin[];
  mode: ChangeCashMode;
  skippedCashSelection?: boolean;
}

function useInitializeCashState({
  initialValues,
  coins,
  bankNotes,
  mode,
  initialAmount,
  skippedCashSelection,
}: UseInitialCashStateProps) {
  // Check if all initial coins are available
  // eslint-disable-next-line complexity
  const allInitialCoinsAvailable = useMemo(() => {
    if (!initialValues?.coins) {
      return false;
    }
    const allCoins = (bankNotes ?? []).concat(coins ?? []);

    for (const coin of initialValues.coins) {
      const foundCoin = allCoins.find((c) => c.factor === coin.factor);
      if (!foundCoin || foundCoin.amount < coin.amount) {
        return false;
      }
    }
    return true;
  }, [bankNotes, coins, initialValues?.coins]);

  const firstCashState = useMemo(() => {
    if (initialValues?.coins && allInitialCoinsAvailable) {
      return coinsToCashState(initialValues.coins);
    }
    return {};
  }, [allInitialCoinsAvailable, initialValues?.coins]);

  // In case of resident top up, the initial amount is the amount entered by the user
  const [cashState, setCashStateOriginal] = useState<CashState>(firstCashState);

  const initialCashState = useMemo(() => {
    const initialCoins = (bankNotes ?? []).concat(coins ?? []);
    return coinsToCashState(initialCoins);
  }, [bankNotes, coins]);

  useEffect(() => {
    if (mode === ChangeCashMode.Adjust) {
      setCashStateOriginal(initialCashState);
    }
  }, [mode, initialCashState]);

  const setCashState = useCallback(
    (valueOrCallback: CashState | ((prev: CashState) => CashState)) => {
      // eslint-disable-next-line complexity
      setCashStateOriginal((prevState) => {
        const newState =
          typeof valueOrCallback === 'function'
            ? valueOrCallback(prevState)
            : valueOrCallback;

        if (mode === ChangeCashMode.Adjust) {
          for (const key of Object.keys(newState)) {
            const keyAsNumber = Number(key);
            if (
              newState[keyAsNumber] === 0 &&
              (initialCashState[keyAsNumber] === 0 ||
                initialCashState[keyAsNumber] === undefined)
            ) {
              delete newState[keyAsNumber];
            }
          }
        } else {
          for (const key of Object.keys(newState)) {
            const keyAsNumber = Number(key);
            if (newState[keyAsNumber] === 0) {
              delete newState[keyAsNumber];
            }
          }
        }

        return newState;
      });
    },
    [initialCashState, mode]
  );

  useEffect(() => {
    if (skippedCashSelection) {
      setCashState({});
    }
  }, [skippedCashSelection, setCashState]);

  return { cashState, setCashState };
}
