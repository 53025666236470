import type { FC } from 'react';
import { Step, StepLabel, Stepper } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ChangeCashStepperProps {
  activeStep: number;
}

export const ChangeCashStepper: FC<ChangeCashStepperProps> = ({
  activeStep,
}) => {
  const { t } = useTranslation();
  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      sx={{ mb: 3, flex: 1, pt: 3, width: 1 }}
    >
      <Step>
        <StepLabel>{t('cashManagement.in-out')}</StepLabel>
      </Step>
      <Step>
        <StepLabel>{t('cashManagement.cash-selection')}</StepLabel>
      </Step>
      <Step>
        <StepLabel>{t('cashManagement.confirmation')}</StepLabel>
      </Step>
    </Stepper>
  );
};
