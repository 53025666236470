import type { FC } from 'react';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NursingHomeAccountingBreadcrumbs } from './NursingHomeAccountingBreadcrumbs';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import { Container } from '@mui/material';
import { PaymentStatus } from '@pflegenavi/shared/api';
import { useServiceProviderPayments } from '@pflegenavi/frontend/api-nursing-home';
import { endOfMonth, startOfMonth } from 'date-fns';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';
import type { Tab } from './serviceProviderPaymentsTable/ServiceProviderPaymentsTable';
import { ServiceProviderPaymentsTable } from './serviceProviderPaymentsTable/ServiceProviderPaymentsTable';

export const AccountingServiceProviderPaymentsPage: FC = () => {
  const { t } = useTranslation();

  const [dateRange, setDateRange] = useState<
    undefined | { from?: Date; to?: Date }
  >({
    from: startOfMonth(new Date()),
    to: endOfMonth(new Date()),
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tab, setTab] = useState<Tab>('all');

  const states = useMemo<PaymentStatus[] | undefined>(() => {
    switch (tab) {
      case PaymentStatus.Failed:
        return [PaymentStatus.Failed];
      case PaymentStatus.Completed:
        return [PaymentStatus.Completed];
      case PaymentStatus.Disputed:
        return [PaymentStatus.Disputed];
      case PaymentStatus.Pending:
        return [PaymentStatus.Pending];
      default:
        // All states
        return undefined;
    }
  }, [tab]);

  const { selectedNursingHome } = useNursingHomeContext();

  const { data, isLoading } = useServiceProviderPayments({
    nursingHomeId: selectedNursingHome?.id,
    page,
    pageSize: rowsPerPage,
    states,
    created: dateRange ? { gte: dateRange.from, lte: dateRange.to } : undefined,
    sortBy: 'created',
    sortOrder: 'desc',
  });

  return (
    <Container maxWidth={'lg'}>
      <NursingHomeAccountingBreadcrumbs
        pageTitle={t('accounting.nav.service-provider-payments')}
        route={nhAppMainPages.NURSING_HOME_ACCOUNTING_PAYOUTS}
      />
      <ServiceProviderPaymentsTable
        page={page}
        onPageChange={setPage}
        pageSize={rowsPerPage}
        onPageSizeChange={setRowsPerPage}
        serviceProviderPayments={data?.data}
        isLoading={isLoading}
        tab={tab}
        setTab={setTab}
        createdDateRange={dateRange}
        onChangeDateRange={(range) => setDateRange(range)}
        totalRowCount={data?.meta.totalItems ?? 0}
      />
    </Container>
  );
};

export default AccountingServiceProviderPaymentsPage;
