import type { FC } from 'react';
import * as React from 'react';
import { Card, Stack, styled, useTheme } from '@mui/material';
import IconWallet from '~icons/ant-design/wallet-filled';
import IconBankFilled from '~icons/ant-design/bank-filled';
import IconEuroCircle from '~icons/majesticons/euro-circle';
import IconFileFill from '~icons/eva/file-fill';

import { TotalAnalytic } from './TotalAnalytic';
import { useTranslation } from 'react-i18next';
import type { CashList } from '@pflegenavi/frontend/api-nursing-home';
import { useCashListConfiguration } from '@pflegenavi/frontend/api-nursing-home';
import { CashListStorageType } from '@pflegenavi/shared/api';

interface NewAccountingOverviewTotalsMangopayProps {
  wallet: number;
  receipts: number;
  cashLists: CashList[];
  payoutSum: number;
  payinSum: number;
}

export const NewAccountingOverviewTotalsMangopay: FC<
  NewAccountingOverviewTotalsMangopayProps
> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const cashListConfiguration = useCashListConfiguration();

  const { wallet, cashLists, payoutSum } = props;

  const bankAccountCashLists = cashLists
    .filter(
      (cashList) =>
        cashList && cashList.storageType === CashListStorageType.BankAccount
    )
    .map((cashList) => {
      let sum = cashList.totalInCent;

      if (cashListConfiguration.payoutTargetCashListId === cashList.id) {
        sum += payoutSum;
      }

      if (cashListConfiguration.payinTargetCashListId === cashList.id) {
        sum -= props.payinSum;
      }

      return {
        ...cashList,
        totalInCent: sum / 100,
      };
    });

  const bankAccountAmount = bankAccountCashLists.reduce(
    (acc, cashList) => acc + cashList.totalInCent,
    0
  );

  const cashAccountCashLists = cashLists
    .filter((cashList) => cashList.storageType & CashListStorageType.Cash)
    .map((cashList) => ({
      ...cashList,
      totalInCent: cashList.totalInCent / 100,
    }));
  const cashAccountAmount = cashAccountCashLists.reduce(
    (acc, cashList) => acc + cashList.totalInCent,
    0
  );

  const total = wallet + props.receipts + bankAccountAmount + cashAccountAmount;

  const percentages: {
    [key: string]: number;
  } = {
    wallet: (wallet / total) * 100,
    receipts: (props.receipts / total) * 100,
  };

  for (const bankCashList of bankAccountCashLists) {
    percentages[bankCashList.name] = (bankCashList.totalInCent / total) * 100;
  }

  for (const cashCashList of cashAccountCashLists) {
    percentages[cashCashList.name] = (cashCashList.totalInCent / total) * 100;
  }

  const totalItems =
    bankAccountCashLists.length + cashAccountCashLists.length + 2;

  return (
    <Stack
      direction="row"
      sx={{
        width: '100%',
        flexWrap: 'wrap',
        gap: totalItems > 4 ? 3 : 1.5,
        rowGap: totalItems > 4 ? 3 : 1.5,
      }}
    >
      <CardContainer totalItems={totalItems}>
        <TotalAnalytic
          sx={{ width: 'fit-content', minWidth: 190, padding: { xs: 0 } }}
          justifyContent="flex-start"
          title="Wallet"
          total={total}
          percent={percentages.wallet}
          amount={wallet}
          Icon={IconWallet}
          color={theme.palette.info.main}
        />
      </CardContainer>

      {bankAccountCashLists.map((cashList) => (
        <CardContainer key={cashList.id} totalItems={totalItems}>
          <TotalAnalytic
            sx={{ width: 'fit-content', minWidth: 190, padding: { xs: 0 } }}
            justifyContent="flex-start"
            title={cashList.name}
            total={total}
            percent={percentages[cashList.name]}
            amount={cashList.totalInCent}
            Icon={IconBankFilled}
            color={theme.palette.warning.main}
          />
        </CardContainer>
      ))}
      {cashAccountCashLists.map((cashList) => (
        <CardContainer key={cashList.id} totalItems={totalItems}>
          <TotalAnalytic
            sx={{ width: 'fit-content', minWidth: 190, padding: { xs: 0 } }}
            justifyContent="flex-start"
            title={cashList.name}
            total={total}
            percent={percentages[cashList.name]}
            amount={cashList.totalInCent}
            Icon={IconEuroCircle}
            color={theme.palette.success.main}
          />
        </CardContainer>
      ))}

      <CardContainer totalItems={totalItems}>
        <TotalAnalytic
          sx={{ width: 'fit-content', minWidth: 190, padding: { xs: 0 } }}
          justifyContent="flex-start"
          title={t('receipts.main')}
          total={total}
          percent={percentages.receipts}
          amount={props.receipts}
          Icon={IconFileFill}
          color={theme.palette.text.secondary}
        />
      </CardContainer>
    </Stack>
  );
};

const CardContainer = styled(Card)<{ totalItems: number }>(
  ({ totalItems }) => ({
    padding: totalItems > 4 ? 24 : 16,
    display: 'flex',
  })
);
