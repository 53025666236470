import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { MoneyInput } from '@pflegenavi/web-components';

interface TargetAmountProps {
  targetAmount: number;
  setTargetAmount:
    | React.Dispatch<React.SetStateAction<number | undefined>>
    | undefined;
}

export const TargetAmount: FC<TargetAmountProps> = ({
  targetAmount,
  setTargetAmount,
}) => {
  const { t } = useTranslation();
  return (
    <Stack gap={1}>
      <Typography variant="h6" sx={{ lineHeight: 1 }}>
        {t('quick-selection.target-amount')}
      </Typography>
      <MoneyInput
        disabled={setTargetAmount === undefined}
        sx={{ maxWidth: 250 }}
        autoFocus={true}
        value={targetAmount === 0 ? undefined : targetAmount}
        onChange={
          setTargetAmount
            ? (value) => {
                setTargetAmount(value ?? 0);
              }
            : undefined
        }
      />
    </Stack>
  );
};
