import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import { DefaultSuspense } from '@pflegenavi/web-components';

const ReceiptsPage = lazy(() => import('./ReceiptsPage'));
const AddReceiptPage = lazy(() => import('./AddReceiptPage'));
const EditReceiptPage = lazy(() => import('./EditReceiptPage'));

export const receiptRoutes = (): JSX.Element => {
  return (
    <>
      <Route
        path={nhAppMainPages.TRANSACTIONS_PENDING}
        element={
          <DefaultSuspense>
            <ReceiptsPage status="pending" />
          </DefaultSuspense>
        }
      />
      <Route
        path={nhAppMainPages.TRANSACTIONS_SUBMITTED}
        element={
          <DefaultSuspense>
            <ReceiptsPage status="submitted" />
          </DefaultSuspense>
        }
      />
      <Route
        path={nhAppMainPages.TRANSACTIONS_ADD}
        element={
          <DefaultSuspense>
            <AddReceiptPage />
          </DefaultSuspense>
        }
      />

      <Route
        path={nhAppMainPages.TRANSACTIONS_ADD_EMPTY_RESIDENT}
        element={
          <DefaultSuspense>
            <AddReceiptPage />
          </DefaultSuspense>
        }
      />
      <Route
        path={nhAppMainPages.TRANSACTIONS_EDIT}
        element={
          <DefaultSuspense>
            <EditReceiptPage />
          </DefaultSuspense>
        }
      />
    </>
  );
};
