import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Iconify, Stack, Typography } from '@pflegenavi/web-components';
import { LetterChip } from './LetterChip';
import { ChangeCashMode } from './model';

interface AdjustCashFormStep1Props {
  nextStep: () => void;
  setMode: (mode: ChangeCashMode) => void;
}

export const AdjustCashFormStep1: FC<AdjustCashFormStep1Props> = ({
  nextStep,
  setMode,
}) => {
  const { t } = useTranslation();

  return (
    <Stack direction="column" gap={1} sx={{ paddingY: 1 }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <LetterChip>B</LetterChip>
        <Typography variant="h6" fontWeight={700}>
          {t('cashManagement.adjust-title')}
        </Typography>
      </Stack>

      <Typography variant="body1">
        {t('cashManagement.adjust-subtitle')}
      </Typography>

      <Stack direction="row" gap={1} justifyContent="end">
        <Button
          name={'changeCashAdjustCash'}
          variant="contained"
          endIcon={
            <Iconify
              sx={{
                ml: 0.5,
                width: 16,
              }}
              icon="teenyicons:adjust-horizontal-solid"
            />
          }
          onClick={() => {
            setMode(ChangeCashMode.Adjust);
            nextStep();
          }}
        >
          {t('cashManagement.adjust-cash')}
        </Button>
      </Stack>
    </Stack>
  );
};
