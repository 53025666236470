import type { FC } from 'react';
import { useMemo } from 'react';
import React from 'react';
import type { QuickSelectionCardProps } from '../../selection/QuickSelectionCard';
import { ToggleShowAllCoins } from '../../selection/QuickSelectionCard';
import { useTranslation } from 'react-i18next';
import { useQuickSelection } from '../../selection/UseQuickSelection';
import { Stack, Typography } from '@mui/material';
import { QuickSelectionProposedCash } from '../../selection/QuickSelectionProposedCash';
import { SelectionHint } from '../../selection/SelectionHint';
import { SelectedCoins } from '../../selection/SelectedCoins';
import { useFormatting } from '@pflegenavi/frontend/localization';

export const CustomQuickSelection: FC<QuickSelectionCardProps> = (props) => {
  const { t } = useTranslation();
  const { fCurrencyCents } = useFormatting();

  const { coins, bankNotes, mode } = props;

  const {
    onlyShowCash,
    setShowAllCoins,
    selectedCoins,
    showAllCoins,
    fittingCoins,
    handleCoinSelect,
    overrideFactor,
    incrementFactor,
    decrementFactor,
    targetReached,
  } = useQuickSelection(props);

  const sumOfSelectedCoins = useMemo(() => {
    return selectedCoins.reduce(
      (acc, coin) => acc + coin.amount * coin.factor,
      0
    );
  }, [selectedCoins]);

  return (
    <Stack gap={3}>
      {!onlyShowCash && (
        <>
          {!showAllCoins && (
            <QuickSelectionProposedCash
              targetReached={targetReached}
              fittingCoins={fittingCoins}
              handleCoinSelect={handleCoinSelect}
            />
          )}
        </>
      )}

      <Stack gap={1}>
        <Typography variant="h6" lineHeight={1}>
          {t('quick-selection.selection')}{' '}
          {`(${fCurrencyCents(sumOfSelectedCoins)})`}
        </Typography>
        {selectedCoins.length === 0 && <SelectionHint />}
        {selectedCoins.length > 0 && (
          <SelectedCoins
            coins={coins}
            selectedCoins={selectedCoins}
            mode={mode}
            bankNotes={bankNotes}
            decrementFactor={decrementFactor}
            incrementFactor={incrementFactor}
            overrideFactor={overrideFactor}
          />
        )}
        <ToggleShowAllCoins
          showAllCoins={showAllCoins}
          setShowAllCoins={setShowAllCoins}
        />
      </Stack>
    </Stack>
  );
};
