import type { NavBarExtension } from '@pflegenavi/frontend/layout';

import { nhAppMainPages } from '@pflegenavi/frontend/routing';

import nursingHome from './assets/nursing_home_24dp.svg';
import { Label, SvgIconStyle } from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';
import { useFeatureFlagEnabled } from '@pflegenavi/frontend/feature-flags';
import { FeatureFlag } from '@pflegenavi/shared/api';
import { useMemo } from 'react';
import { Typography } from '@mui/material';

export const useConfig: () => NavBarExtension = () => {
  const EmployeeManagementFeatureFlagEnabled = useFeatureFlagEnabled(
    FeatureFlag.EmployeeManagement
  );

  const legalEntitiesFeatureFlagEnabled = useFeatureFlagEnabled(
    FeatureFlag.dev_LegalEntities
  );

  const { selectedNursingHome } = useNursingHomeContext();

  const { t } = useTranslation();

  const paymentsDisabledInfo = useMemo(() => {
    if (!selectedNursingHome?.paymentsDisabled) {
      return undefined;
    } else {
      return (
        <Label variant="outlined" color="error">
          <Typography variant={'inherit'} mr={0.1} mt={0.15}>
            €
          </Typography>
        </Label>
      );
    }
  }, [selectedNursingHome?.paymentsDisabled]);

  const navChildren = useMemo(() => {
    return [
      {
        title: t('common.overview'),
        path: nhAppMainPages.NURSING_HOME,
      },
      ...(EmployeeManagementFeatureFlagEnabled &&
      selectedNursingHome?.isSupervisor
        ? [
            {
              title: t('employees-management.employees-list.title'),
              path: nhAppMainPages.NURSING_HOME_EMPLOYEES_LIST,
            },
          ]
        : []),

      ...(legalEntitiesFeatureFlagEnabled && selectedNursingHome?.isSupervisor
        ? [
            {
              title: t('legal-entities.title'),
              path: nhAppMainPages.NURSING_HOME_LEGAL_ENTITIES,
            },
          ]
        : []),
    ];
  }, [
    EmployeeManagementFeatureFlagEnabled,
    selectedNursingHome?.isSupervisor,
    legalEntitiesFeatureFlagEnabled,
    t,
  ]);

  if (!selectedNursingHome?.isSupervisor) {
    return undefined;
  }

  return {
    item: {
      icon: (
        <SvgIconStyle
          src={nursingHome}
          sx={{
            width: 1,
            height: 1,
          }}
        />
      ),
      title: t('nursingHome.main'),
      path: nhAppMainPages.NURSING_HOME,
      info: paymentsDisabledInfo,
      children: navChildren,
    },
  };
};
