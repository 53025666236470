import type { FC, SVGProps } from 'react';
import { useMemo } from 'react';
import React from 'react';
import { experimental_sx as sx } from '@mui/system';
import IconRoundsPeople from '~icons/ic/round-people';
import IconEuroCircle from '~icons/majesticons/euro-circle';
import IconStripe from '~icons/bi/stripe';
import IconBankFilled from '~icons/ant-design/bank-filled';
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { MenuPopover } from '@pflegenavi/web-components';
import { styled, useTheme } from '@mui/material/styles';
import {
  useFormatDate,
  useFormatting,
} from '@pflegenavi/frontend/localization';

import HomeStatusIcon from './home_status.svg';
import ExclamationIcon from './exclamation_blue.svg';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import { useNursingHomeQuickOverview } from './useNursingHomeQuickOverview';
import { useMenuPopover } from './useMenuPopover';
import { MenuBarIconButton } from '@pflegenavi/frontend/layout';
import { useFeatureFlagEnabled } from '@pflegenavi/frontend/feature-flags';
import { FeatureFlag, StripePaymentMethodStatus } from '@pflegenavi/shared/api';
import { YEAR_MONTH_DAY_SHORT_FORMAT } from '@pflegenavi/shared/constants';
import { useGetTenantInformation } from '@pflegenavi/frontend/api-nursing-home';
import { NursingHomeQuickOverviewMangopay } from './NursingHomeQuickOverviewMangopay';

export const NursingHomeQuickOverview: FC = () => {
  const { data: tenantInfo, isLoading: isLoadingTenantInfo } =
    useGetTenantInformation();

  if (isLoadingTenantInfo) {
    return null;
  }

  if (tenantInfo?.payment_processor === 'stripe') {
    return <NursingHomeQuickOverviewStripe />;
  } else {
    return <NursingHomeQuickOverviewMangopay />;
  }
};

// eslint-disable-next-line complexity
const NursingHomeQuickOverviewStripe: FC = () => {
  const { t } = useTranslation();
  const { fCurrencyCents } = useFormatting();
  const fDate = useFormatDate();
  const navigate = useNavigate();
  const theme = useTheme();

  const coinListEnabled = useFeatureFlagEnabled(FeatureFlag.CoinList);
  const newAccountingPaymentsEnabled = useFeatureFlagEnabled(
    FeatureFlag.AccountingPaymentsNew
  );

  const {
    isLoading,
    totalResidentsBalance,
    cashRegisterBalance,
    stripeBalance,
    bankAccountCashLists,
    cashAccountCashLists,
    bankAccountAmount,
    cashAccountAmount,
    difference,
    stripeAccount,
    supervisor,
    serviceProviderPaymentSum,
    serviceProviderPaymentTargetCashListId,
  } = useNursingHomeQuickOverview();

  const { open, handleClick, handleClose, anchorEl } = useMenuPopover(
    'NursingHomeQuickOverview'
  );

  const navigateToReporting = () => {
    navigate(nhAppMainPages.REPORTING);
    handleClose();
  };

  const navigateToCashManagement = () => {
    navigate(nhAppMainPages.CASH_MANAGEMENT_CASH);
    handleClose();
  };

  const navigateToAccountingOverview = () => {
    navigate(nhAppMainPages.NURSING_HOME_ACCOUNTING_OVERVIEW_NEW);
    handleClose();
  };

  const navigateToNursingHomePage = () => {
    navigate(nhAppMainPages.NURSING_HOME);
    handleClose();
  };

  const showStripeWarning = useMemo(() => {
    return (
      stripeAccount?.status === StripePaymentMethodStatus.Active &&
      stripeAccount?.currentlyDue
    );
  }, [stripeAccount]);

  const showWarning = showStripeWarning;

  // If the account is inactive because they didn't do onboarding yet, we don't want to nag them with an error
  const showStripeError = useMemo(() => {
    return (
      stripeAccount?.status !== StripePaymentMethodStatus.Active &&
      stripeAccount?.onboardingStarted
    );
  }, [stripeAccount]);

  const showError = showStripeError;

  // At the moment, the difference will only be displayed if the cash management feature flag is enabled.
  // In the future, we will also integrate Stripe etc. Then we will also show the difference
  const showDifference = coinListEnabled && difference !== 0;

  return (
    <>
      <Tooltip title={t('menu-bar.nursing-home-status.title')}>
        <MenuBarIconButton onClick={handleClick} open={Boolean(open)}>
          <img alt="" src={HomeStatusIcon} width={24} height={24} />
          {showError ? (
            <DotBadge color={'error.main'} />
          ) : showWarning ? (
            <DotBadge color={'warning.main'} />
          ) : showDifference ? (
            <DotBadge color={'info.main'} />
          ) : null}
        </MenuBarIconButton>
      </Tooltip>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          width: 360,
          p: 0,
          mt: 0.5,
          ml: 0.5,
        }}
      >
        <MenuTitleContainer>
          <Typography variant="subtitle1" color="primary">
            {t('menu-bar.nursing-home-status.title')}
          </Typography>
        </MenuTitleContainer>

        <Divider />
        {isLoading ? (
          <NursingHomeStatusLoadingSkeleton />
        ) : (
          <>
            <List
              disablePadding
              subheader={
                <ListSubheader
                  disableSticky
                  sx={{
                    py: 1,
                    px: 2.5,
                    typography: 'overline',
                    mt: 1,
                  }}
                >
                  {t('menu-bar.nursing-home-status.account-balance')}
                </ListSubheader>
              }
              sx={{
                maxHeight: 'calc(100vh - 150px)',
                overflowY: 'auto',
              }}
            >
              <AccountBalanceItem
                onClick={navigateToReporting}
                title={t('menu-bar.nursing-home-status.total-resident-balance')}
                subtitle={fCurrencyCents(totalResidentsBalance)}
                Icon={IconRoundsPeople}
              />
              {coinListEnabled && !newAccountingPaymentsEnabled && (
                <AccountBalanceItem
                  onClick={navigateToCashManagement}
                  title={t(
                    'menu-bar.nursing-home-status.cash-register-balance'
                  )}
                  subtitle={fCurrencyCents(cashRegisterBalance)}
                  Icon={IconEuroCircle}
                />
              )}

              {newAccountingPaymentsEnabled && (
                <AccountBalanceItem
                  onClick={navigateToAccountingOverview}
                  title="Stripe"
                  subtitle={fCurrencyCents(stripeBalance)}
                  Icon={IconStripe}
                />
              )}
              {coinListEnabled && newAccountingPaymentsEnabled && (
                <>
                  {bankAccountCashLists.map((cashList) => (
                    <AccountBalanceItem
                      key={cashList.id}
                      onClick={navigateToAccountingOverview}
                      title={cashList.name}
                      subtitle={`${fCurrencyCents(cashList.totalInCent)}${
                        cashList.id ===
                          serviceProviderPaymentTargetCashListId &&
                        serviceProviderPaymentSum > 0
                          ? `- ${fCurrencyCents(serviceProviderPaymentSum)}`
                          : ''
                      }`}
                      Icon={IconBankFilled}
                    />
                  ))}

                  {cashAccountCashLists.map((cashList) => (
                    <AccountBalanceItem
                      key={cashList.id}
                      onClick={navigateToAccountingOverview}
                      title={cashList.name}
                      subtitle={fCurrencyCents(cashList.totalInCent)}
                      Icon={IconEuroCircle}
                    />
                  ))}
                </>
              )}

              {showDifference && (
                <Mismatch
                  totalResidentsBalance={totalResidentsBalance}
                  cashRegisterBalance={cashRegisterBalance}
                  stripeBalance={stripeBalance}
                  bankAccountAmount={
                    bankAccountAmount -
                    (newAccountingPaymentsEnabled
                      ? serviceProviderPaymentSum
                      : 0)
                  }
                  cashAccountAmount={cashAccountAmount}
                  difference={difference}
                />
              )}

              {showWarning && (
                <>
                  <ListSubheader
                    disableSticky
                    sx={{
                      py: 1,
                      px: 2.5,
                      typography: 'overline',
                      mt: 0,
                    }}
                  >
                    {t('menu-bar.nursing-home-status.warnings')}
                  </ListSubheader>

                  {showStripeWarning && (
                    <WarningItem
                      clickable={supervisor}
                      onClick={navigateToNursingHomePage}
                      color={theme.palette.warning.main}
                      title={t(
                        'menu-bar.nursing-home-status.stripe-needs-updates'
                      )}
                      subtitle={
                        stripeAccount?.dueDate
                          ? t(
                              'menu-bar.nursing-home-status.stripe-needs-updates-description-with-date',
                              {
                                date: fDate(
                                  stripeAccount.dueDate,
                                  YEAR_MONTH_DAY_SHORT_FORMAT
                                ),
                              }
                            )
                          : t(
                              'menu-bar.nursing-home-status.stripe-needs-updates-description-with-date'
                            )
                      }
                      Icon={IconStripe}
                    />
                  )}
                </>
              )}

              {showError && (
                <>
                  <ListSubheader
                    disableSticky
                    sx={{
                      py: 1,
                      px: 2.5,
                      typography: 'overline',
                      mt: 0,
                    }}
                  >
                    {t('menu-bar.nursing-home-status.errors')}
                  </ListSubheader>

                  {showStripeError && (
                    <WarningItem
                      clickable={supervisor}
                      onClick={navigateToNursingHomePage}
                      color={theme.palette.error.main}
                      title={t(
                        'menu-bar.nursing-home-status.stripe-deactivated'
                      )}
                      subtitle={t(
                        'menu-bar.nursing-home-status.stripe-deactivated-description'
                      )}
                      Icon={IconStripe}
                    />
                  )}
                </>
              )}

              <ListItem
                sx={{
                  py: 0.5,
                }}
              ></ListItem>
            </List>
          </>
        )}
      </MenuPopover>
    </>
  );
};

// ----------------------------------------------------------------------

interface AccountBalanceItemProps {
  title: string;
  subtitle: string;
  onClick?: () => void;
  Icon: React.ComponentType<SVGProps<SVGSVGElement>>;
}

const AccountBalanceItem: FC<AccountBalanceItemProps> = ({
  title,
  subtitle,
  onClick,
  Icon,
}) => {
  const theme = useTheme();

  return (
    <ListItemButton onClick={onClick}>
      <ListItemAvatar>
        <Avatar
          sx={{
            bgcolor: 'background.neutral',
          }}
        >
          <Icon color={theme.palette.primary.main} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          color: 'primary',
          fontWeight: 600,
        }}
        secondary={
          <Typography
            variant="subtitle2"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {subtitle}
          </Typography>
        }
      />
    </ListItemButton>
  );
};

// ----------------------------------------------------------------------

interface MismatchProps {
  totalResidentsBalance: number;
  cashRegisterBalance: number;
  stripeBalance: number;
  bankAccountAmount: number;
  cashAccountAmount: number;
  difference: number;
}

const Mismatch: FC<MismatchProps> = ({
  totalResidentsBalance,
  cashRegisterBalance,
  stripeBalance,
  cashAccountAmount,
  bankAccountAmount,
  difference,
}) => {
  const { t } = useTranslation();
  const { fCurrencyCents } = useFormatting();
  const newAccountingPaymentsEnabled = useFeatureFlagEnabled(
    FeatureFlag.AccountingPaymentsNew
  );

  return (
    <ListItem
      sx={{
        py: 1,
        px: 2,
      }}
      alignItems="flex-start"
    >
      <ListItemAvatar>
        <Avatar
          sx={{
            bgcolor: 'background.neutral',
            ml: -0.5,
          }}
        >
          <img alt="" src={ExclamationIcon} width={22} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={t('menu-bar.nursing-home-status.mismatch')}
        primaryTypographyProps={{
          color: 'primary',
          fontWeight: 600,
        }}
        secondary={
          <Typography color="grey.600" variant="subtitle2">
            {newAccountingPaymentsEnabled ? (
              <Trans
                i18nKey="menu-bar.nursing-home-status.mismatch-description-new"
                values={{
                  totalSumBalance: fCurrencyCents(
                    cashAccountAmount + stripeBalance + bankAccountAmount
                  ),
                  totalResidentsBalance: fCurrencyCents(totalResidentsBalance),
                  difference: fCurrencyCents(difference),
                }}
                components={{ bold: <strong /> }}
              />
            ) : (
              <Trans
                i18nKey="menu-bar.nursing-home-status.mismatch-description"
                values={{
                  totalRegisterBalance: fCurrencyCents(cashRegisterBalance),
                  totalResidentsBalance: fCurrencyCents(totalResidentsBalance),
                  difference: fCurrencyCents(difference),
                }}
                components={{ bold: <strong /> }}
              />
            )}
          </Typography>
        }
      />
    </ListItem>
  );
};

interface WarningProps {
  title: string;
  subtitle: string;
  onClick?: () => void;
  Icon: React.ComponentType<SVGProps<SVGSVGElement>>;
  clickable: boolean;
  color: string | undefined;
}

const WarningItem: FC<WarningProps> = ({
  title,
  subtitle,
  onClick,
  Icon,
  clickable,
  color,
}) => {
  const content = useMemo(() => {
    return (
      <>
        <ListItemAvatar>
          <Avatar
            sx={{
              bgcolor: 'background.neutral',
            }}
          >
            <Icon color={color} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            color: 'primary',
            fontWeight: 600,
          }}
          secondary={
            <Typography
              variant="subtitle2"
              sx={{
                mt: 0.5,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {subtitle}
            </Typography>
          }
        />
      </>
    );
  }, [title, subtitle, Icon, color]);

  return clickable ? (
    <ListItemButton
      onClick={onClick}
      sx={{
        py: 1,
        px: 2,
      }}
      alignItems="flex-start"
    >
      {content}
    </ListItemButton>
  ) : (
    <ListItem
      sx={{
        py: 1,
        px: 2,
      }}
      alignItems="flex-start"
    >
      {content}
    </ListItem>
  );
};

const NursingHomeStatusLoadingSkeleton = () => {
  return (
    <>
      <Skeleton variant="rectangular" height={90} />
      <Divider />
      <Skeleton variant="rectangular" height={90} />
    </>
  );
};

interface DotBadgeProps {
  color?: 'info.main' | 'error.main' | 'warning.main';
}

const DotBadge: FC<DotBadgeProps> = ({ color = 'info.main' }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 7,
        right: 9,
        height: 9,
        width: 9,
        borderRadius: '50%',
        bgcolor: color,
      }}
    />
  );
};

const MenuTitleContainer = styled(Box)(
  sx({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    py: 2,
    px: 2.5,
  })
);
