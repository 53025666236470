import { alpha, experimental_sx as sx, styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';

export const MenuBarIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open?: boolean }>(({ open, theme }) =>
  sx({
    position: 'relative',
    ...(open && {
      '&:before': {
        zIndex: 1,
        content: "''",
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        position: 'absolute',
        bgcolor: alpha(theme.palette.grey[300], 0.5),
      },
    }),
  })
);
