import type { StackProps } from '@mui/material';
import { ChangeCashMode } from '../form/model';
import type { Coin } from '@pflegenavi/shared/api';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import { Incrementer, Stack } from '@pflegenavi/web-components';
import { CashImage } from '../CashImage';

interface CashRowProps extends StackProps {
  mode: ChangeCashMode;
  /** The factor of coin is used to identify this row. The amount is used in adjust/withdraw mode to limit the amount */
  coin: Coin;
  increment: (factor: number) => void;
  decrement: (Factor: number) => void;
  override: (factor: number, newValue: number) => void;
  quantity: number;
  autoFocus?: boolean;
}

export const CashRow: FC<CashRowProps> = ({
  mode,
  coin,
  increment,
  decrement,
  override,
  quantity,
  autoFocus,
  ...props
}) => {
  const canIncrease = useMemo(() => {
    return (
      (mode === ChangeCashMode.Withdraw && coin.amount > quantity) ||
      mode !== ChangeCashMode.Withdraw
    );
  }, [mode, coin.amount, quantity]);

  const onIncrease = useCallback(() => {
    if (canIncrease) {
      increment(coin.factor);
    }
  }, [canIncrease, increment, coin.factor]);

  const onDecrease = useCallback(() => {
    decrement(coin.factor);
  }, [decrement, coin.factor]);

  const onOverride = useCallback(
    (newValue: number) => {
      override(coin.factor, newValue);
    },
    [override, coin.factor]
  );

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      gap={2}
      data-cy={`cash-${coin.factor}`}
      {...props}
    >
      <CashImage
        factor={coin.factor}
        onClick={onIncrease}
        sx={{ '&:hover': { cursor: canIncrease ? 'pointer' : 'default' } }}
      />
      <Incrementer
        available={
          mode === ChangeCashMode.Withdraw || mode === ChangeCashMode.Adjust
            ? coin.amount
            : undefined
        }
        quantity={quantity}
        onIncrease={onIncrease}
        onDecrease={onDecrease}
        onOverride={onOverride}
        mode={mode}
        autoFocus={autoFocus}
      />
    </Stack>
  );
};
