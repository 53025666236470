import type { FC } from 'react';
import * as React from 'react';
import { useMemo } from 'react';
import { Card, Stack, styled, Typography } from '@mui/material';
import type { PaymentMethodDto } from '@pflegenavi/shared/api';
import { DirectDebitStatus } from '@pflegenavi/shared/api';
import { experimental_sx as sx } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';

import type { FormikHelpers } from 'formik';
import { getIn, useFormik } from 'formik';
import { FTextInput, IbanInput } from '@pflegenavi/web-components';

import { useSetupDirectDebitValidationSchema } from '@pflegenavi/shared/frontend';
import {
  MandateStatus,
  useSetupDirectDebit,
} from '../hooks/useSetupDirectDebit';

interface SetupDirectDebitProps {
  status: DirectDebitStatus;
  paymentMethod?: PaymentMethodDto;
}

export interface ISetUpDirectDebitValues {
  name: string;
  email: string;
  iban: string;
}

export const SetupDirectDebit: FC<SetupDirectDebitProps> = ({
  status,
  paymentMethod,
}) => {
  const { t } = useTranslation();
  const isFormDisabled =
    status === DirectDebitStatus.Active || status === DirectDebitStatus.Pending;

  const validationSchema = Yup.object(useSetupDirectDebitValidationSchema());

  const initialValues: ISetUpDirectDebitValues = useMemo(() => {
    return {
      name: paymentMethod?.name ?? '',
      email: paymentMethod?.email ?? '',
      iban: paymentMethod?.last4
        ? `AT**************${paymentMethod.last4}`
        : '',
    };
  }, [paymentMethod]);

  const { mandateStatus, confirm } = useSetupDirectDebit();

  const handleSubmit = (
    values: ISetUpDirectDebitValues,
    actions: FormikHelpers<ISetUpDirectDebitValues>
  ) => {
    actions.setSubmitting(true);
    actions.setSubmitting(false);
    confirm(values);
    actions.resetForm({ values });
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <RootContainer>
      <Stack direction="column" gap={3}>
        <FTextInput
          id="name"
          name="name"
          label={t('nursing-home.full-name-field')}
          placeholder={t('mobile.family-member.full-name-field')}
          fullWidth
          InputLabelProps={{ shrink: true }}
          disabled={isFormDisabled}
          formik={formik}
        />
        <FTextInput
          id="email"
          name="email"
          label={t('nursing-home.email-field')}
          placeholder={t('mobile.family-member.email-field')}
          fullWidth
          InputLabelProps={{ shrink: true }}
          disabled={isFormDisabled}
          formik={formik}
        />

        <IbanInput
          value={formik.values.iban}
          setValue={(newIban) => formik.setFieldValue('iban', newIban)}
          error={getIn(formik.errors, 'iban')}
          disabled={isFormDisabled}
        />
      </Stack>
      <Typography
        color="grey.500"
        variant="subtitle2"
        align="left"
        sx={{ p: 2, marginY: 2 }}
      >
        {t('nursing-home.mandate-agreement-text')}
      </Typography>
      <Stack alignItems="flex-end">
        <LoadingButton
          type="submit"
          onClick={() => formik.handleSubmit()}
          id="activate-direct-debit-button"
          variant="contained"
          disabled={isFormDisabled}
          loading={mandateStatus === MandateStatus.Loading}
        >
          {t('nursing-home.confirm-mandate-button')}
        </LoadingButton>
      </Stack>
    </RootContainer>
  );
};

const RootContainer = styled(Card)(
  sx({
    p: 3,
  })
);
