import {
  Card,
  LoadingContainer,
  Stack,
  SvgIconStyle,
  Typography,
} from '@pflegenavi/web-components';
import type { FC } from 'react';
import React from 'react';
import { Box, CardHeader, Divider, styled, useMediaQuery } from '@mui/material';
import type { Coin } from '@pflegenavi/shared/api';
import { equals, isBanknote, times, useBankNotesAndCoins } from './euro';

import { CashImage } from './CashImage';
import { useTranslation } from 'react-i18next';
import { useFormatting } from '@pflegenavi/frontend/localization';
import { useCashListConfiguration } from '@pflegenavi/frontend/api-nursing-home';

interface DetailedCashOverviewCardViewProps {
  bankNotes: Coin[];
  coins: Coin[];
}

export const DetailedCashOverviewCard: FC = () => {
  // TODO: Remove legacy code
  const cashListId = useCashListConfiguration().selectedCashListId;
  const { bankNotes, coins, isLoading } = useBankNotesAndCoins(
    /** The global cash list is in use here */
    cashListId
  );

  if (isLoading || bankNotes.length === 0 || coins.length === 0) {
    return <LoadingContainer height={200} />;
  }

  return <DetailedCashOverviewCardView bankNotes={bankNotes} coins={coins} />;
};

export const DetailedCashOverviewCardView: FC<
  DetailedCashOverviewCardViewProps
> = ({ bankNotes, coins }) => {
  const smallScreens = useMediaQuery('(max-width:850px)');
  const { t } = useTranslation();

  return (
    <Card data-cy="detailted-cash-overview" sx={{ p: 3 }}>
      <CardHeader
        title={t('cashManagement.detailed-cash-overview')}
        sx={{ mb: 3, p: 0 }}
        titleTypographyProps={{
          sx: { fontWeight: 600 },
        }}
      />

      <Stack
        direction="row"
        gap={5}
        justifyContent="space-between"
        flexWrap="wrap"
        mr={2}
        ml={2}
      >
        <Stack
          direction="column"
          gap={2}
          sx={{
            width: {
              xs: '100%',
              md: 'auto',
            },
          }}
        >
          {bankNotes.map((bankNote) => (
            <CashRow key={bankNote.factor} coin={bankNote} />
          ))}
        </Stack>
        <Divider
          orientation={smallScreens ? 'horizontal' : 'vertical'}
          flexItem
        />
        <Stack
          direction="column"
          gap={2}
          sx={{
            width: {
              xs: '100%',
              md: 'auto',
            },
          }}
        >
          {coins.map((coin) => (
            <CashRow key={coin.factor} coin={coin} />
          ))}
        </Stack>
      </Stack>
    </Card>
  );
};

export const CashRow: FC<{ coin: Coin }> = ({ coin }) => {
  const { fCurrency } = useFormatting();
  const sum = (coin.amount * coin.factor) / 100;

  const typeIsBankNote = isBanknote(coin.factor);

  return (
    <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
      <CashImage factor={coin.factor} />
      <SvgIconStyle sx={{ width: 16, height: 16 }} src={times} />
      <NumberContained>
        <Amount variant="body2">{coin.amount}</Amount>
      </NumberContained>
      <SvgIconStyle sx={{ width: 16, height: 16 }} src={equals} />
      <NumberContained>
        <Sum variant="body2">
          {fCurrency(sum, { minimumFractionDigits: typeIsBankNote ? 0 : 2 })}
        </Sum>
      </NumberContained>
    </Stack>
  );
};

const NumberContained = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));

const Amount = styled(Typography)(() => ({
  width: 40,
  textAlign: 'center',
}));

const Sum = styled(Typography)(() => ({
  width: 60,
  textAlign: 'center',
}));
