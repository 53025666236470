import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import { DefaultSuspense } from '@pflegenavi/web-components';

const ServiceProviderOverviewPage = lazy(
  () => import('./ServiceProviderOverviewPage')
);
const ServiceProviderDetailsPage = lazy(
  () => import('./ServiceProviderDetailsPage')
);

const ServiceProviderProfile = lazy(
  () => import('./components/service-provider-details/ServiceProviderProfile')
);

const ServiceProviderResidents = lazy(
  () =>
    import('./components/service-provider-residents/ServiceProviderResidents')
);

export const serviceProviderRoutes = (): JSX.Element => {
  return (
    <Route path={nhAppMainPages.SERVICE_PROVIDER}>
      <Route
        index={true}
        element={
          <DefaultSuspense>
            <ServiceProviderOverviewPage key="service-provider-overview" />
          </DefaultSuspense>
        }
      />
      <Route
        path={nhAppMainPages.SERVICE_PROVIDER_ADD}
        element={
          <DefaultSuspense>
            <ServiceProviderOverviewPage
              modalToAddOpen
              key="add-service-provider"
            />
          </DefaultSuspense>
        }
      />
      <Route
        path={nhAppMainPages.SERVICE_PROVIDER_DETAILS}
        element={
          <DefaultSuspense>
            <ServiceProviderDetailsPage />
          </DefaultSuspense>
        }
      >
        <Route
          index={true}
          element={
            <DefaultSuspense>
              <ServiceProviderProfile />
            </DefaultSuspense>
          }
        />
        <Route
          path={nhAppMainPages.SERVICE_PROVIDER_DETAILS_RESIDENTS}
          element={
            <DefaultSuspense>
              <ServiceProviderResidents />
            </DefaultSuspense>
          }
        />
      </Route>
    </Route>
  );
};
