import { GroupContainer } from './GroupContainer';
import { Title } from './Title';
import { FactorRow } from '@pflegenavi/frontend/pages/cash-management';
import { RowDivider } from './RowDivider';
import { CashListStorageType } from '@pflegenavi/shared/api';
import { Row } from './Row';
import { Typography, useTheme } from '@mui/material';
import type { FC } from 'react';
import React from 'react';
import { useTextStyles } from './useTextStyles';
import { useTranslation } from 'react-i18next';
import { useFormatting } from '@pflegenavi/frontend/localization';
import { useCashListConfiguration } from '@pflegenavi/frontend/api-nursing-home';

interface CashProps {
  cashListId: string;
}

export const Cash: FC<CashProps> = ({ cashListId }) => {
  const { fCurrencyCents } = useFormatting();
  const { t } = useTranslation();
  const theme = useTheme();
  const { rowTextProps, totalTextProps } = useTextStyles();

  const cashList = useCashListConfiguration().cashLists.find(
    (cashList) => cashList.id === cashListId
  );

  if (!cashList) {
    throw new Error(`Cash list ${cashListId} not found`);
  }

  const { bankAccountAmountInCent, totalInCent, coins } = cashList;

  const allCoins = coins.sort((a, b) => b.factor - a.factor);
  const cashListName = cashList?.name ?? t('accounting.dashboard.cash-title');
  return (
    <GroupContainer>
      <Title>{cashListName}</Title>
      {allCoins.map((coin, index) => (
        <>
          <FactorRow
            factor={coin.factor}
            amount={coin.amount}
            color={theme.palette.grey['600']}
          />
          {(index + 1) % 3 === 0 && index + 1 < allCoins.length ? (
            <RowDivider />
          ) : undefined}
        </>
      ))}
      <RowDivider />
      {cashList && cashList.storageType & CashListStorageType.BankAccount ? (
        <>
          <Row>
            <Typography {...rowTextProps}>
              {t('accounting.dashboard.cash-savings-title')}
            </Typography>
            <Typography {...rowTextProps}>
              {fCurrencyCents(bankAccountAmountInCent)}
            </Typography>
          </Row>
          <RowDivider />
        </>
      ) : undefined}
      <Row>
        <Typography {...totalTextProps} fontWeight={700}>
          {t('accounting.dashboard.total-cash-balance', {
            cashListName: cashListName,
          })}
        </Typography>
        <Typography {...totalTextProps} fontWeight={700}>
          {fCurrencyCents(totalInCent)}
        </Typography>
      </Row>
    </GroupContainer>
  );
};
